import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);
import createPersistedState from "vuex-persistedstate";

const store = new Vuex.Store({
    plugins: [createPersistedState()],
    state:{
        user: null,
        store: null,
    },
    getters:{
        user: (state) => {
            return state.user;
        },
        store:(state) => {
            return state.store;
        }
    },
    actions:{
        user(context, user) {
            context.commit('user',user)
        },
        store(context, store) {
            context.commit('store',store)
        }
    },
    mutations:{
        user(state, user){
            state.user = user;
        },
        store(state, store){
            state.store = store;
        },
    },
});

export default store;
<template>
  <div>
    <b-form @submit="onSubmit" @reset="onReset" v-if="show">
      <b-form-group
        id="input-group-1"
        label="Matricule"
        label-for="input-1"
        description="Nous ne montrerons jamais votre numéro d'immatriculation"
        class="mb-2"
      >
        <b-form-input
          id="input-1"
          v-model="registration_number"
          placeholder="000524 119 05"
        ></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-2" label="Marque:" label-for="input-2" 
          description="Entrer la marque"
          class="mb-2"
        >
        <b-form-select v-model="selected" :options="options" @change="changed"><b-form-input size="sm" class="mr-sm-2"> </b-form-input></b-form-select>
      </b-form-group>

      <b-form-group id="input-group-3" label="Modèle:" label-for="input-2" 
          description="Entrer le modèle"
          class="mb-2">
        <b-form-select v-model="selected1" :options="options1" @change="modelchanged"><b-form-input size="sm" class="mr-sm-2"> </b-form-input></b-form-select>
      </b-form-group>

      <b-form-group id="input-group-4" label="L'année de fabrication:" label-for="input-2" 
          description="Entrer l'année"
          class="mb-2">
        <b-form-select v-model="selected2" :options="options2"><b-form-input size="sm" class="mr-sm-2"> </b-form-input></b-form-select>
      </b-form-group>

      <b-form-group id="input-group-5" label="type d'energie:" label-for="input-2" 
          description="Entrer l'energier (Carburant)"
          class="mb-2">
        <b-form-select v-model="selected3" :options="options3"><b-form-input size="sm" class="mr-sm-2"> </b-form-input></b-form-select>
      </b-form-group>

      <b-form-group id="input-group-6" label="Motorisation:" label-for="input-2" 
          description="Entrer la motorisation"
          class="mb-2">
        <b-form-input
          id="input-2"
          v-model="capacity"
          placeholder="Saisir la motorisation"
          required
        ></b-form-input>
      </b-form-group>

      <b-button type="submit" variant="primary">Soumettre</b-button>
      <b-button type="reset" variant="danger">Réinitialiser</b-button>
    </b-form>
  </div>
</template>

<script>
  import axios from 'axios';
  import {mapGetters} from 'vuex';
  export default {
    name:'AddCar',
    data() {
      return {
        selected: null,
        options:[
          {value: null, text: 'select your car brand'}
        ],
        selected1: null,
        options1:[
          {value: null, text: 'select your car model'}
        ],
        selected2: null,
        options2:[
          {value: null, text: 'select your car year'}
        ],
        selected3: null,
        options3:[
          {value: null, text: 'select your car engine type'}
        ],
        capacity: '',
        registration_number:'',
        show: true, 
        data:[],
        models:[],
      }
    },
    methods: {
      changed(){
        let models =  this.data.filter(m =>m.brand == this.selected);
        this.options1 = [{ value: null, text: 'Séléctionner votre modèle' },];
        this.options2 = [{ value: null, text: 'l\'année de véhicule' },]
        this.models = models[0].car_models;
        for(let model of models[0].car_models){
          this.options1.push({ value:model.model, text:model.model});
          this.options2.push({ value:model.year, text:model.year});
        }
      },
      modelchanged(){
        let model = this.models.filter((m) => m.model == this.selected1);
        this.options3 = [{value: null, text: 'Carburant de véhicule' }];
        for(let m of model[0].car_engines){
          this.options3.push({value: m.energy_type, text:m.energy_type});
          this.options4.push({ value:m.capacity, text:m.capacity});
        }
      },
      async addcar(){
        try{
          window.console.log(this.capacity);await axios.post(`client/add_car`,{
            registration_number: this.registration_number,
            userId: this.user.id,
            brand: this.selected,
            model: this.selected1,
            year: this.selected2,
            energyType: this.selected3,
            capacity: this.capacity,
          });
        }catch(err){
          window.console.error(err);
        }
      },
      async onSubmit(event) {
        event.preventDefault()
        this.addcar();
      },
      onReset(event) {
        event.preventDefault()
        // Reset our form values
        this.registration_number = '';
        this.brand = null;
        this.model = null;
        this.year = null;
        this.energyType = null;
        this.capacity = null;
        // Trick to reset/clear native browser form validation state
        this.show = false
        this.$nextTick(() => {
          this.show = true
        })
      }
    },
    async mounted() {
      try{
        const res = await axios.get(`user/model_brand_engine`);
        this.data = res.data;     
        for(let option of this.data){
          this.options.push({ value:option.brand, text:option.brand});
        }
      }catch(err){
        window.console.error(err);
      }
    },
    computed:{
      ...mapGetters(['user']),
    },
  }
</script>
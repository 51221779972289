<template>
  <b-container class="GarageSatting">
        <h2 class="mb-5"> Votre Garage</h2>
    <b-button-group>
      <b-button v-b-modal.modal-1 class="BtAddCar">
        <b-icon icon="plus-circle"></b-icon> Ajouter une voiture
      </b-button>
    </b-button-group>
    <b-table :items="cars" :fields="fields" striped responsive="sm">

      <template #cell(supprimer|mettre_à_jour)="row">
        <b-button size="sm" @click="row; deleteCar(row.item.id)" class="mr-2">
          <b-icon icon="x-circle"></b-icon> Effacer
        </b-button>
        <b-button v-b-modal.modal-2 size="sm" @click="row; updateCar(row.item)" class="mr-2">
          <b-icon icon="box-arrow-in-up"></b-icon> Mettre à jours
        </b-button>
      </template>

      <template #cell(chercher_une_piece)="row">
        <b-button size="sm" @click="row;search(row.item)" class="mr-2" :to="`/${$i18n.locale}/pagepiecerecherche`">
          <b-icon icon="search"></b-icon> Rechercher des pièces
        </b-button>
      </template>

      <template #cell(details)="row">
        <b-button size="sm" @click="row.toggleDetails" class="mr-2">
          {{ row.detailsShowing ? 'Hide' : 'Show'}} Détails
        </b-button>
      </template>

      <template #row-details="row">
        <b-card>
          <b-row class="mb-2">
            <b-col sm="3" class="text-sm-right"><strong>Numéro d'enregistrement:</strong></b-col>
            <b-col>{{ row.item.registration}}</b-col>
          </b-row>

          <b-row class="mb-2">
            <b-col sm="3" class="text-sm-right"><strong>Marque:</strong></b-col>
            <b-col>{{ row.item.marque_voiture}}</b-col>
          </b-row>

          <b-row class="mb-2">
            <b-col sm="3" class="text-sm-right"><strong>Modèle:</strong></b-col>
            <b-col>{{ row.item.modèle}}</b-col>
          </b-row>

          <b-row class="mb-2">
            <b-col sm="3" class="text-sm-right"><strong>Année:</strong></b-col>
            <b-col>{{ row.item.car_year}}</b-col>
          </b-row>

          <b-row class="mb-2">
            <b-col sm="3" class="text-sm-right"><strong>Carburant:</strong></b-col>
            <b-col>{{ row.item.car_engine_type}}</b-col>
          </b-row>

          <b-row class="mb-2">
            <b-col sm="3" class="text-sm-right"><strong>Motorisation:</strong></b-col>
            <b-col>{{ row.item.engine_capacity}}</b-col>
          </b-row>

          <b-button size="sm" @click="row.toggleDetails">Cacher les détails</b-button>
        </b-card>
      </template>
    </b-table>


    <b-modal hide-footer  id="modal-1" title="Ajouter une voiture">
      <p class="my-4" :userID="userID"><AddCar></AddCar></p>
    </b-modal>
    <b-modal hide-footer  id="modal-2" title="Mettre à jour la voiture">
      <p class="my-4"><UpdateCar :carID="selectedcarID"></UpdateCar></p>
    </b-modal>

    <b-modal hide-footer  id="modal-deleted" title="Supprimer la voiture">
      <p class="my-4">la voiture a été supprimée avec succès</p>
    </b-modal>

    <b-modal hide-footer  id="modal-delete-error" title="Erreur suppression">
      <p class="my-4">Une erreur est apparue !!</p>
    </b-modal>


  </b-container>
</template>

<script>
import AddCar from "./AddCar.vue";
import UpdateCar from "./UpdateCar.vue";
import axios from 'axios';
import {mapGetters} from 'vuex';
import { bus } from '../main'
  export default {
    props: {
      userID:String,
    },
    components: {
      AddCar,
      UpdateCar,
    },
    data() {
      return {
        fields: ['marque_voiture', 'modèle', 'details','supprimer|mettre_à_jour','chercher_une_piece'],
        cars: [],
        data:[],
        selectedcarID:'',
        searchResult:[],
      }
    },
    methods: {
     async deleteCar(carID){
        try{
          let res = await axios.delete(`client/delete_car/${carID}`);
          if(res.status == 200){
            this.$bvModal.show('modal-deleted');
          }else{
            this.$bvModal.show('modal-delete-error');
          }
        }catch(err){
          window.console.error(err);
        }
      },
      updateCar(car){
        this.selectedcarID = car.id;
      },
      async search(car) { 
        try{
          let res = null;
          window.console.log('get all products');
          res = await axios.get(`user/products_by_car/${car.marque_voiture}/${car.modèle}/${car.car_year}/${car.car_engine_type}`);
          this.searchResult = res.data;
          
          bus.$emit('searchData',this.searchResult);
        }catch(e){
          window.console.error(e);
        }
      },
    },
    async mounted() {
      try{
        let res = await axios.get(`client/get_cars/${this.user.id}`);
        this.data = res.data;
        for(let car of this.data){
          this.cars.push({id:car.id,marque_voiture:car.car_brand.brand,registration:car.registration_number,modèle:car.car_model.model,car_year:car.car_model.year,car_engine_type:car.car_engine.energy_type,engine_capacity:car.car_engine.capacity})
        }
      }catch(err) {
        window.console.error(err);
      }
    },
    computed:{
      ...mapGetters(['user']),
    },
  }
</script><style scoped>
.GarageSatting
{
    height: 100%;
    background: #fff;
    padding: 40px 40px;
}
.BtAddCar
{
  color: #F87127;
  border-color: #F87127;
  border-radius: 10px;
  background: #fff;
  font-size: 1.2rem;
  font-weight: 500;
}
.BtAddCar:hover,
.BtAddCar:active
{
  background: #F87127;
  color: #fff;
  box-shadow: 0px 0px 0px 4px #f8702769;
}
</style>
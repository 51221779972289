<template>
<b-container fluid class="pageMaintenance" no-guttars>
    <b-col class="imgWarning">
        <img src="../assets/Solution_warning.svg" alt="Page en Maintenance">
        <h2>Le Site et en maintenance</h2>
    </b-col>
</b-container>
</template>

<script>
export default {
  name: "PageMaintenance",
  components: {
  }
}
</script>

<style scoped>
.imgWarning{
    position: relative;
    display: flex;
    justify-content: center;
}
img{
    max-width: 900px;
}
h2{
    position:absolute;
    display: flex;
    justify-content: center;
    top: 15%;
    font-weight: 800;
    font-size: 3rem;
}
@media (max-width: 768px)
{
    img{
        max-width: 550px;
    }
}
@media (max-width: 492px)
{
    img{
        max-width: 280px;
    }
    h2{
    font-weight: 800;
    top: 10%;
    font-size: 1.5rem;
    }
}

</style>
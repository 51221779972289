<template>
    <b-container>
      <b-card title="User Phones" sub-title="Phones">
      <b-form>
                <b-form-tags v-model="phones" no-outer-focus class="mb-2">
                <template v-slot="{ tags, inputAttrs, inputHandlers, addTag, removeTag }">
                    <b-input-group aria-controls="my-custom-tags-list">
                    <input
                        v-bind="inputAttrs"
                        v-on="inputHandlers"
                        placeholder="New Phone"
                        class="form-control">
                    <b-input-group-append>
                        <b-button @click="addTag(),addphoneUser(inputAttrs)" variant="primary">Add</b-button>
                    </b-input-group-append>
                    </b-input-group>
                    <ul
                    id="my-custom-tags-list"
                    class="list-unstyled d-inline-flex flex-wrap mb-0"
                    aria-live="polite"
                    aria-atomic="false"
                    aria-relevant="additions removals"
                    >
                    <!-- Always use the tag value as the :key, not the index! -->
                    <!-- Otherwise screen readers will not read the tag
                        additions and removals correctly -->
                    <b-card
                        v-for="tag in tags"
                        :key="tag"
                        :id="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                        tag="li"
                        class="mt-1 mr-1"
                        body-class="py-1 pr-2 text-nowrap"
                    >
                        <strong>{{ tag }}</strong>
                        <b-button
                        @click="removeTag(tag),removePhoneUser(tag)"
                        variant="link"
                        size="sm"
                        :aria-controls="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                        >remove</b-button>
                    </b-card>
                    </ul>
                </template>
                </b-form-tags>
      </b-form>
    </b-card>
    <b-card title="Store Phones" sub-title="Phones">
      <b-form>
                <b-form-tags v-model="phones" no-outer-focus class="mb-2">
                <template v-slot="{ tags, inputAttrs, inputHandlers, addTag, removeTag }">
                    <b-input-group aria-controls="my-custom-tags-list">
                    <input
                        v-bind="inputAttrs"
                        v-on="inputHandlers"
                        placeholder="New Phone"
                        class="form-control">
                    <b-input-group-append>
                        <b-button @click="addTag(),addphoneStore(inputAttrs)" variant="primary">Add</b-button>
                    </b-input-group-append>
                    </b-input-group>
                    <ul
                    id="my-custom-tags-list"
                    class="list-unstyled d-inline-flex flex-wrap mb-0"
                    aria-live="polite"
                    aria-atomic="false"
                    aria-relevant="additions removals"
                    >
                    <!-- Always use the tag value as the :key, not the index! -->
                    <!-- Otherwise screen readers will not read the tag
                        additions and removals correctly -->
                    <b-card
                        v-for="tag in tags"
                        :key="tag"
                        :id="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                        tag="li"
                        class="mt-1 mr-1"
                        body-class="py-1 pr-2 text-nowrap"
                    >
                        <strong>{{ tag }}</strong>
                        <b-button
                        @click="removeTag(tag),removePhoneStore(tag)"
                        variant="link"
                        size="sm"
                        :aria-controls="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                        >remove</b-button>
                    </b-card>
                    </ul>
                </template>
                </b-form-tags>
      </b-form>
    </b-card>
    </b-container>
</template>

<script>
  import {validationMixin} from 'vuelidate'
  import axios from 'axios';
  import {minLength,required} from 'vuelidate/lib/validators'
  import {mapGetters} from 'vuex';
export default {
  props: ['clickedNext', 'currentStep'],
    storeID: { 
      type: String,
      default: '',
    },
    userID: { 
      type: String,
      default: '',
    },
  mixins: [validationMixin],
  data() {
    return {
      form:{
        StorePhones:[],
        UserPhones:[],
      }
    };
  },
  validations: {
    form:{
      StorePhones:{
        required,
        minLength:minLength(1)
      },
      UserPhones:{
        required,
        minLength:minLength(1)
      }
    }
  },
      computed:{
      ...mapGetters(['user']),
      ...mapGetters(['store']),
    },
watch: {
          $v: {
            handler: function (val) {
              if(!val.$invalid) {
                  this.$emit('can-continue', {value: true});
              } else {
                  this.$emit('can-continue', {value: false});
              }
            },
            deep: true
          },
          clickedNext(val) {
            if(val === true) {
              this.$v.form.$touch();
            }
          }
        },
        methods: {
            async addphoneUser(phone){
                try{
                    this.form.UserPhones.push(phone);
                    let res = await axios.post(`info/add_phone`,{
                        userID: this.user.id,
                        phoneNum: phone.value
                    });
                    window.console.log(res);
                }catch(e){
                    window.console.error(e);
                }
            },
            async removePhoneUser(phone){
                try{
                    this.form.UserPhones.push(phone);
                    let res = await axios.delete(`info/delete_phone/${phone}`);
                    window.console.log(res);
                }catch(e){
                    window.console.error(e);
                }                
            },
            async addphoneStore(phone){
                try{
                  this.form.StorePhones.push(phone);
                    let res = await axios.post(`store/add_phone`,{
                        storeID: this.store.id,
                        phoneNum: phone.value
                    });
                    window.console.log(res);
                }catch(e){
                    window.console.error(e);
                }
            },
            async removePhoneStore(phone){
                try{
                    var vm = this;

                    await axios.post(`store/add_phone`,{
                        storeID: this.store.id,
                        phoneNum: phone.value
                    });
                    vm.$forceUpdate();
                }catch(e){
                    window.console.error(e);
                }            
            }
        },
        mounted() {
          if(!this.$v.$invalid) {
            this.$emit('can-continue', {value: true});
          } else {
            this.$emit('can-continue', {value: false});
          }
        }
};
</script>
<template>
    <b-container class="pubDeuxHoriz" fluid no-guttars>
        <b-row class="PubDeuxHome">
            <b-col cols="12" sm="12" md="6" lg="6" xl="6" class="PubDroite">
                <img src="../assets/vulcanisateur.jpg" alt="">
                <b-button class="buttomPubL" >Reserver un rendez-vous</b-button>
            </b-col>
            <b-col cols="12" sm="12" md="6" lg="6" xl="6" class="PubGauche">
                <img src="../assets/les_phares.jpg" alt="">
                <b-button class="buttomPubR" >Visiter le Store</b-button>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>

export default {
  name: 'PubDeuxHoriz',
  components: {
    
  }
}
</script>


<style scoped>
.pubDeuxHoriz .PubDeuxHome
{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
}
.pubDeuxHoriz .PubDeuxHome .PubDroite,
.pubDeuxHoriz .PubDeuxHome .PubGauche
{
    padding: 0 0 0 5px;
}
.pubDeuxHoriz .PubDeuxHome img
{
    position: relative;
    margin: 0;
    padding: 0;
    height: 250px;
    min-width: 100%;
}
.pubDeuxHoriz .PubDeuxHome .buttomPubL
{
    position: absolute ;
    bottom: 0px;
    right: 50%;
    border-radius: 20px 0 0 0;
  background: rgb(240, 100, 8);
}
.pubDeuxHoriz .PubDeuxHome .buttomPubR
{
    position: absolute ;
    bottom: 0px;
    right: 0px;
    border-radius: 20px 0 0 0;
  background: rgb(240, 100, 8);
}
@media (max-width: 768px)
{
    .pubDeuxHoriz .PubDeuxHome .buttomPubL
    {
        position: absolute;
        right: 0%;
        bottom: 50%;
        z-index: 100000;
    }
}
</style>
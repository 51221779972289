<template>

    <b-container fluid no-gutters class="CategoriesPiece">       

        <CardCategorie1 v-bind:cards="cards"/>

    </b-container>

</template>

<script>

import CardCategorie1 from "./CardCategorie.vue";
import axios from 'axios';
export default {

    name:'CategoriesPieces',
    components: {
        CardCategorie1,
    },
    data() {
        return {
            data:null,
            cards: [],
        }
    },
    async created(){
        try{
            let categories = await axios.get(`user/get_popular_categories`);
            this.data = categories.data;

            // window.console.log(this.data);
            
            for(let cardinfo of this.data){
                let link  = 'https://www.api.mikaniky.com/'+ cardinfo.pic.replace( /\\/g, "/");
                this.cards.push({name:cardinfo.product_type_name,img:link,id:cardinfo.id,childs:cardinfo.children});
            }
        }catch(err){
            window.console.error(err);
        }
    },
    methods: {
        
    },
    
}
</script>

<style scoped>
.CategoriesPiece
{
    position: relative;
    margin: 60px 0;
}
</style>
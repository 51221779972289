<template>
    <b-row>
        <b-col  class="listSelectedAuto">
          <div class="zoneSearche">
            <div>
              <!--<h5>la marque de véhicule</h5>-->
              <b-form-select v-model="selected" :options="options" @change="changed"><b-form-input size="sm" class="mr-sm-2"> </b-form-input></b-form-select>
            </div>
            
            <div>
              <!--<h5>le modèle</h5>-->
              <b-form-select v-model="selected2" :options="options2" @change="modelchanged"><b-form-input size="sm" class="mr-sm-2"> </b-form-input></b-form-select>
             </div>

            <!--<div>
              <h5>l'année</h5>
              <b-form-select v-model="selected3" :options="options3"><b-form-input size="sm" class="mr-sm-2"> </b-form-input></b-form-select>
            </div> -->

            <div>
              <!--<h5>Motorisation</h5>-->
              <b-form-select v-model="selected4" :options="options4"><b-form-input size="sm" class="mr-sm-2"> </b-form-input></b-form-select>
            </div>
            <div class="bottonRecherche">
              <b-button class="BtSearch" @click.prevent="search" :to="`/${$i18n.locale}/pagepiecerecherche`"><b-icon icon="search"></b-icon> Démarrer</b-button>
            </div>
          </div>
        </b-col>
    </b-row>    
</template>

<script>
  import axios from 'axios';
  import { bus } from '../main'
  //import Multiselect from 'vue-multiselect'

export default {
    name: 'ListSelectedAuto',
    components: {
    //Multiselect
    },
    data() {
      return {
        selected: null,
        options: [
          { value: null, text: 'Marque (Ex: Peugeot.. )' },
        ],
        selected2: null,
        options2: [
          { value: null, text: 'le modèle' },
        ],
        selected3: null,
        options3: [
          { value: null, text: 'l\'année' },
        ],
        selected4: null,
        options4: [
          { value: null, text: 'Motorisation' },
        ],
        data:[],
        models:[],
        searchResult:[],
      }
    },
    methods: {
      changed(){
        // window.console.log(this.data);
        let models =  this.data.filter(m =>m.brand == this.selected);
        this.options2 = [{ value: null, text: 'Séléctionner votre modèle' },];
        this.options3 = [{ value: null, text: 'l\'année de véhicule' },]
        this.models = models[0].car_models;
        for(let model of models[0].car_models){
          this.options2.push({ value:`${model.description}`, text:`${model.description} [${model.year_from}, ${model.year_to}]`});
          // this.options3.push({ value:model.year, text:model.year});
        }
      },
      modelchanged(){
        let model = this.models.filter((m) => m.description == this.selected2);
        this.options4 = [{value: null, text: 'Motorisation de véhicule' }];
        let i = 1;
        for(let m of model[0].car_engines){
          this.options4.push({label:m.carbutrant, options:[]});
          for(let desc of m.car_year_descs){
            this.options4[i].options.push({value:desc.description,text:`${desc.description} [${desc.year_from} - ${desc.year_to}]`});
          }
          i = i + 1;
        }
      },
      async search () { 
        try{
          let res = null;
          window.console.log('get all products');
          res = await axios.get(`user/products_by_car/${this.selected}/${this.selected2}/${this.selected3}/${this.selected4}`);
          this.searchResult = res.data;
          
          bus.$emit('searchData',this.searchResult);
        }catch(e){
          window.console.error(e);
        }
      },
    },
    async created() {
    try{
      const res = await axios.get(`user/model_brand_engine`);
      this.data = res.data

      for(let option of this.data){
        this.options.push({ value:option.brand, text:option.brand});
      }
    }catch(err){
      window.console.error(err);
    }
  },
}
</script>

<style scoped>
.ZoneVoiture,
.ZoneMarque
{
  position: relative;
  display: flex;
  flex-wrap: nowrap;
}
/*.multi  select{
    min-width: 150px;
    height: 35px;
    display: block;
    padding: 3px 40px 0 8px;
    border-radius: 5px;
    border: 1px solid #ff0f0f;
    background: #fff;
    font-size: 14px;
}
.modelCar .multiselect .multiselect__tags .multiselect__content-wrapper
{
    background: #fff;
}

.modelVoiture
{
  min-width: auto;
}*/

select{
  position:relative;
  display: flex;
  justify-content: center;
  margin: 0 0 3px 0;
  padding: 0 0 0 10px;
  width: 250px;
  height: 35px;
  color: #7e7e7e;
  border-radius: 7px;
  font-weight: 700;
  font-size: 1.05rem;
}
select:hover{
  color: #F87127;
  border: 1px solid #F87127;
  border-bottom: 2px solid #F87127;
  box-shadow: 0px 2px 3px 1px #f87027ea;
}

.zoneSearche div
{
  padding: 5px 0;
}
.h5
{
  position: relative;
  display: flex;
  justify-content: flex-start;
  max-width: 250px;
}
.BtSearch
{
  position: absolute;
  right: 150px;
  max-width: 200px;
  margin: 10px 0 0 0;
  border-color: #F87127;
  background: #F87127;
  border-radius: 4px;
  font-size: 1.2rem;
  font-weight: 500;
}
.BtSearch:hover,
.BtSearch:active
{
  color: #F87127;
  background: #fff;
  box-shadow: 0px 0px 0px 4px #f8702769;
}
@media (max-width: 1250px)
{
  select{
    margin: 0 0 10px 0;
    height: 35px;
    border-radius: 15px;
    font-size: 1.05rem;
  }
  .zoneSearche div
  {
    padding: 5px 0;
  }
  .h5
  {
    max-width: 200px;
  }
  .BtSearch
  {
    right: 100px;
    max-width: 200px;
    margin: 10px 0 0 0;
    border-radius: 10px;
    font-size: 1.1rem;
  }
}
@media (max-width: 998px)
{
  select{
    margin: 0 0 10px 0;
    height: 25px;
    width: 180px;
    border-radius: 15px;
    font-size: 0.9rem;
  }
  .zoneSearche div
  {
    padding: 4px 0;
  }
  .BtSearch
  {
    right: 100px;
    max-width: 180px;
    margin: 10px 0 0 0;
    border-radius: 10px;
    font-size: 0.9rem;
  }
}
@media (max-width: 740px)
{
  select{
    margin: 0 0 8px 0;
    height: 25px;
    width: 180px;
    border-radius: 15px;
    font-size: 0.9rem;
  }
  .zoneSearche div
  {
    padding: 4px 0;
  }
  .BtSearch
  {
    right: 100px;
    max-width: 180px;
    margin: 10px 0 0 0;
    border-radius: 10px;
    font-size: 0.9rem;
  }
}
</style>
          // v-for="card in cards"
          // :key="card.name"
<template >
  <div class="col-12 h-100 ml-8 shadow-lg p-2 bg-white rounded">
    <b-container class="h-100">
      <b-row class="" align-h="center">
          <b-row class="imageUser">
            <b-form-file accept=".jpg, .png" placeholder="" id="fileUpload" v-model="file" hidden @change="uploadImage"></b-form-file>
            <b-avatar size="100px" button  @click.prevent="chooseFiles" :src="`https://www.api.mikaniky.com/${user.pic}`"></b-avatar>
            <h5 class="UserName">{{ user.username }}</h5>
          </b-row>
      </b-row>
      <b-row align-h="start">
        <div class="w-100">
          <b-list-group flush class="outline-warning">
            <b-list-group-item button>
              <router-link :to="`/${$i18n.locale}/UserProfile`"><b-btn class="btnUserAdm"><b-icon icon="person" font-scale="2"></b-icon><h5><span> Profil </span></h5></b-btn></router-link>
            </b-list-group-item>
            <b-list-group-item button>
              <router-link :to="`/${$i18n.locale}/UserProfile/infos`"><b-btn class="btnUserAdm"><b-icon icon="info-circle" font-scale="2"></b-icon><h5> Informations </h5></b-btn></router-link>
            </b-list-group-item>
            <b-list-group-item button>
              <router-link :to="`/${$i18n.locale}/UserProfile/contacts`"><b-btn class="btnUserAdm"><b-icon icon="journal-bookmark" font-scale="2"></b-icon><h5> Contacts </h5></b-btn></router-link>
            </b-list-group-item>
            <b-list-group-item button>
              <router-link :to="`/${$i18n.locale}/UserProfile/garage`"><b-btn class="btnUserAdm"><b-icon icon="shop-window" font-scale="2"></b-icon><h5> Garage </h5></b-btn></router-link>
            </b-list-group-item>
            <b-list-group-item v-if="user.roles[0] == `ROLE_MANAGER`" button>
              <router-link :to="`/${$i18n.locale}/UserProfile/informationStore`"
                ><b-btn class="btnUserAdm"><b-icon icon="shop" font-scale="2"></b-icon><h5> Boutique </h5></b-btn>
              </router-link>
            </b-list-group-item>
            <b-list-group-item v-if="user.roles[0] == `ROLE_MANAGER`" button @click="changeIcon">
              <router-link :to="`/${$i18n.locale}/UserProfile/publication`"
                ><b-btn class="btnUserAdm"><b-icon icon="cart-check" font-scale="2"></b-icon><h5> Liste des produits </h5></b-btn>
              </router-link>
            </b-list-group-item>
            <b-list-group-item v-b-toggle.collapse-4 button>
              <router-link :to="`/${$i18n.locale}/UserProfile/setting`"><b-btn class="btnUserAdm"><b-icon icon="gear" font-scale="2"></b-icon><h5> Parametres </h5></b-btn></router-link>
            </b-list-group-item>
            <b-list-group-item v-if="user.roles[0] == `ROLE_MANAGER`" button>
              <b-btn v-if="false" class="btnUserAdm"><b-icon icon="graph-up" font-scale="2"></b-icon><h5> Statistique </h5></b-btn>
                <!-- <b-icon
                  icon="reply"
                  aria-hidden="true"
                  variant="warning"
                ></b-icon> -->
            </b-list-group-item>
          </b-list-group>
        </div>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import store from '../Vuex';
import axios from 'axios';
import {mapGetters} from 'vuex';
export default {

  name: "SideBareUserProfile",
  props: {
    cards: {},
  },
  data() {
    return {
      file: null,
      data:[],
      icon: "chevron-right",
      iconOrder: "chevron-right",
    };
  },
  methods: {
    chooseFiles(){
      document.getElementById("fileUpload").click()
      // this.uploadImage();
    },
    changeIcon() {
      if (this.icon === "chevron-right") this.icon = "chevron-down";
      else this.icon = "chevron-right";
    },
    changeIconOrder() {
      if (this.iconOrder === "chevron-right") this.iconOrder = "chevron-down";
      else this.iconOrder = "chevron-right";
    },
    async uploadImage(event){
      try{
        
        window.console.log(event.target.files[0]);
        let formData = new FormData();
        formData.append("event", "profile");
        formData.append("userpic", event.target.files[0]);
        formData.append("id", this.user.id);
        let res = await axios.post(`info/add_profile_pic` ,formData);
        window.console.log(res.data);
        store.dispatch('user',res.data);
      }catch(err){
        window.console.log(err);
      }
    },
  },
  async created () {
    try{
      let res = await axios.get(`user`);
      this.data = res.data;
    }catch(err){
      window.console.log(err);
    }
  },
    computed:{
      ...mapGetters(['user']),
    },
};
</script>

<style scoped>
.imageUser
{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.imageUser h5
{
  text-align: center;
}
.list-group-item{
  padding: 0;
  border-radius: 50px;  
  margin: 10px 0;
}
.list-group-item a
{
  text-decoration: #fff;
}
.list-group-item .btnUserAdm
{
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  text-align: start;
  margin: 0;
  padding: 0 0 0 15px;
  width: 100%;
  height: 40px;
  background: #fff;
  color: #000;
  border: 2px solid rgba(255, 255, 255, 0);
  border-radius: 50px;
}
.list-group-item .btnUserAdm:hover{
  background: #fff;
  color: #F87127;
  border: 2px solid #F87127;
  box-shadow: 0px 0px 8px 3px #f87027ea;
}
.list-group-item .btnUserAdm h5
{
  position: relative;
  text-decoration: none;
  font-weight: 800;
  width: 100%;
  margin: 0 0 0 10px;
}
</style>
<template>
    <div class="row row-cols-1 mt-3 overflow-auto">
        <b-pagination-nav pills number-of-pages="10" base-url="#" align="center"></b-pagination-nav>
    </div>
</template>

<script>
export default {
    name:'Pagination'
}
</script>

<style scoped>

</style>
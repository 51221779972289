<template>
  <div>
    <b-form @submit="onSubmit" @reset="onReset" v-if="show">
      <b-form-group
        id="input-group-1"
        label="Numéro d'immatriculation de la voiture:"
        label-for="input-1"
        description="Nous ne partagerons jamais votre numéro d'enregistrement avec qui que ce soit d'autre"
      >
        <b-form-input
          id="input-1"
          v-model="form.registrationNumber"
          placeholder="Entrez le numéro d'immatriculation de la voiture"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-2" label="Car engine type:" label-for="input-2">
        <b-form-input
          id="input-2"
          v-model="form.energyType"
          placeholder="Entrez le type de moteur"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-3" label="Energie:" label-for="input-3">
        <b-form-input
          id="input-2"
          v-model="form.capacity"
          placeholder="Entrez la capacité du moteur"
          required
        ></b-form-input>
      </b-form-group>

      <b-button type="submit" variant="primary">Enregestrer</b-button>
      <b-button type="reset" variant="danger">Réinitialiser</b-button>
    </b-form>
  </div>
</template>

<script>
import axios from 'axios';
  export default {
  props: {
    carID:String,
  },
    name:'UpdateCar',
    data() {
      return {
        form:{
          energyType:'',
          capacity:'',
          registrationNumber:''
        },
        show: true
      }
    },
    methods: {
      async updateCar(){
        try{
          let res = await axios.put('client/updateCar',{
            carID:this.carID,
            energyType:this.form.energyType,
            capacity:this.form.capacity,
            registrationNumber:this.form.registrationNumber,
          });
          window.console.log(res);
        }catch(e){
          window.console.error(e);
        }
      },
      async onSubmit(event) {
        event.preventDefault();
        alert(JSON.stringify(this.form));
        this.updateCar();
      },
      onReset(event) {
        event.preventDefault()
        // Reset our form values
        this.form.email = ''
        this.form.name = ''
        this.form.food = null
        this.form.checked = []
        // Trick to reset/clear native browser form validation state
        this.show = false
        this.$nextTick(() => {
          this.show = true
        })
      }
    }
  }
</script>
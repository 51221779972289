<template>
  <b-container fluid class="homeMain" no-guttars>
    <b-container fluid no-guttars class="carousselHomeHeader">
      <b-row class="rowHome">
        <b-col cols="12" sm="12" md="4" lg="4" xl="4" class="searchPieceHome">
          <div  class="box_Content">
            <b-form class="formHomeRech" @submit.prevent="onSubmitLogin" @reset="onReset">  
                <img src="../assets/FormRecherche.jpg" class="presentation_header" style="background-attachment: fixed;">
                <div class="zoneSearche">
                  <h2><span class="titreColor">Votre</span> véhicule !!</h2>
                  <div class="listSelectHome">
                    <my-list-selected-auto></my-list-selected-auto>
                  </div>
              </div>
            </b-form>
          </div>
        </b-col>
        <b-col cols="12" sm="12" md="8" lg="8" xl="8" class="sliderHomeHooper">
          <hooper
            :centerMode="true"
            :infiniteScroll="true"
            :autoPlay="true"
            :playSpeed="4000"
            class="hooperCarousselHomeHeader"
          >
            <slide>
              <b-container fluid class="carousselHeader">
                <img src="../assets/Pup_Header_1.jpg" />
              </b-container>
            </slide>
            <slide>
              <b-container fluid class="carousselHeader">
                <img src="../assets/Pup_Header_2.jpg" />
              </b-container>
            </slide>
            <slide>
              <b-container fluid class="carousselHeader">
                <img src="../assets/Pup_Header_3.jpg" />
              </b-container>
            </slide>
            <hooper-pagination slot="hooper-addons"></hooper-pagination>
          </hooper>
        </b-col>
      </b-row>
    </b-container>
    <b-container fluid class="categoriePiece" id="categoriePiece">
      <h2>Catégories Pièces</h2>
      <b-col cols="12" sm="12" md="12" lg="12" xl="12">
        <CategoriesPieces1 v-bind:cards="cards" />
      </b-col>
    </b-container>
    <b-container fluid class="Best_Agent" id="Best_Agent">
      <h2>Les Meilleurs Stores</h2>
      <b-col cols="12" sm="12" md="12" lg="12" xl="12">
        <BestStores v-if="false" v-bind:cards="cards" />
      </b-col>
    </b-container>
    <b-container v-if="false" fluid class="categorieService" id="categorieService">
      <h2>Catégories Services</h2>
      <b-col cols="12" sm="12" md="12" lg="12" xl="12">
        <CategoriesService v-bind:cards="cards" />
      </b-col>
    </b-container>
    <b-container v-if="false" fluid class="Best_Products" id="Best_Products">
      <h2>Les Meilleurs pièces déttaché vendu</h2>
      <b-col cols="12" sm="12" md="12" lg="12" xl="12">
        <my-BestProducts></my-BestProducts>
      </b-col>
    </b-container>
    <b-container v-if="false" fluid class="Best_Agent" id="Best_Agent">
      <h2>Les Meilleurs Mécaniciens, Eléctreciens, ...</h2>
      <b-col cols="12" sm="12" md="12" lg="12" xl="12">
        <my-BestAgent></my-BestAgent>
      </b-col>
    </b-container>
    <b-container fluid class="Pub_Deux_Horiz" id="Pub_Deux_Horiz">
      <my-PubDeuxHoriz></my-PubDeuxHoriz>
    </b-container>
  </b-container>
</template>

<script>
import { Hooper, Slide, Pagination as HooperPagination } from "hooper";
import "hooper/dist/hooper.css";
import ListSelectedAuto from "../components/ListSelectedAuto.vue";
import CategoriesPieces1 from "../components/CategoriesPieces.vue";
import CategoriesService from "../components/CategoriesService.vue";
import BestStores from "../components/BestStores.vue";
import BestProducts from "../views/BestProducts.vue";
import BestAgent from "../views/BestAgent.vue";
import PubDeuxHoriz from "../components/PubDeuxHoriz.vue";
import {mapGetters} from 'vuex';

export default {
  name: "Home",
  components: {
    Hooper,
    Slide,
    HooperPagination,
    CategoriesPieces1,
    CategoriesService,
    BestStores,
    "my-list-selected-auto": ListSelectedAuto,
    "my-BestProducts": BestProducts,
    "my-BestAgent": BestAgent,
    "my-PubDeuxHoriz": PubDeuxHoriz,
  },
  data() {
    return {
      selected: null,
      options: [
        { value: null, text: "Séléctionner votre voiture" },
      ],
      selected2: null,
      options2: [
        { value: null, text: "Séléctionner votre voiture" },
      ],
    };
  },
  created(){
    window.console.log();
  },
  computed:{
    ...mapGetters(['user']),
  },
};
</script>

<style scoped>
.homeMain{
  position: relative;
  background: #f5f5f5;
}
.homeMain .carousselHomeHeader {
  position: relative;
  padding: 20px 0 0 0;
}
.homeMain .carousselHomeHeader .searchPieceHome {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.row
{
  position: relative;
  display: flex;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
}
.homeMain .carousselHomeHeader .box_Content
  {
    position: relative;
    margin-bottom: 0px;
    width: 500px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box; 
  }
.homeMain .carousselHomeHeader .searchPieceHome .box_Content .formHomeRech .zoneSearche
  {
    position: absolute;
    box-sizing: border-box;
    background-blend-mode: screen;    /*ulusion*/
    background-repeat: no-repeat;
    align-items: center;
    width: 100%;
    height: 100%;
    top: 150px;
    left: 80px;
  }
.homeMain .carousselHomeHeader .box_Content .formHomeRech .zoneSearche h5
{
  position: relative;
  margin-bottom: 30px;
  color: rgb(0, 0, 0);
  font-weight: 800;
  font-size: 1.3rem;
  padding: 0px 0 0 0;
}
.homeMain .carousselHomeHeader .box_Content .formHomeRech .zoneSearche .titreColor
{
  color: #F87127;
}
.homeMain .carousselHomeHeader .box_Content .formHomeRech .presentation_header
{
  width: 450px;
  height: 500px;
}
.homeMain .carousselHomeHeader .box_Content .formHomeRech .zoneSearche .listSelectHome
{
  position: relative;
  left: 20px;
}
.homeMain .carousselHomeHeader .sliderHomeHooper {
  position: relative;
  margin: 0;
  padding: 0;
}
.homeMain .carousselHomeHeader .hooper {
  min-width: 100%;
  height: 100%;
}
.homeMain .carousselHomeHeader .hooper * {
  width: 100%;
  height: 100%;
}
.hooper-pagination
{
  position: absolute;
  top: 90%; 
  right: 50%;
  max-width: 20px;
  color: brown;
}
.homeMain .carousselHomeHeader .slide {
  width: 100%;
  height: 100%;
}
.homeMain .carousselHomeHeader img
{
  width: 100%; 
}
.homeMain .categoriePiece,
.homeMain .categorieService
{
  position: relative;
  margin: 100px 0 0 0;
}
@media (max-width: 1250px)
{
  .homeMain .carousselHomeHeader .searchPieceHome .box_Content .formHomeRech .zoneSearche
    {
      top: 120px;
      left: 40px;
    }
  .homeMain .carousselHomeHeader .box_Content .formHomeRech .zoneSearche h2
  {
    margin-bottom: 10px;
    font-size: 1.6rem;
  }
}
@media (max-width: 998px)
{
  .homeMain .carousselHomeHeader .searchPieceHome .box_Content .formHomeRech .zoneSearche
    {
      top: 80px;
      left: 30px;
    }
  .homeMain .carousselHomeHeader .box_Content .formHomeRech .zoneSearche h2
  {
    margin-bottom: 10px;
    font-size: 1.6rem;
  }
}
@media (max-width: 740px)
{
  .homeMain .carousselHomeHeader .searchPieceHome .box_Content .formHomeRech .zoneSearche
    {
      top: 120px;
      left: 30px;
    }
  .homeMain .carousselHomeHeader .box_Content .formHomeRech .zoneSearche h2
  {
    margin-bottom: 10px;
    font-size: 1.6rem;
  }
}
</style>

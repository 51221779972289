<template>
    <b-container fluid class="Setting" no-guttars>
        <h3>Changer votre mot de passe</h3>
        <b-form @submit.prevent="changepass">
            <b-input-group id="input-group-1" label-for="input-1" class="input-field mb-5" >
                <b-form-input id="type-password" v-model="oldpass" required placeholder="************" type="password"></b-form-input>
                    <label for="Ancien_password" ></label>
            </b-input-group> 
            <b-input-group id="input-group-1" label-for="input-1" class="input-field mb-5" >
                    <b-form-input id="type-password" type="password" v-model="newpass"></b-form-input>
                    <label for="New_password" >Nouveau mot de pssse</label>
            </b-input-group>  
            <b-input-group id="input-group-1" label-for="input-1" class="input-field mb-5" >
                    <b-form-input id="type-password" type="password" v-model="confpass"></b-form-input>
                    <label for="Encien_password" >Confirmer votre mot de pssse</label>
            </b-input-group>   
            <b-button class="BtnEnreg" type="submit">Enregistrer</b-button>  
        </b-form>
    </b-container>
</template>

<script>
import axios from 'axios';
import {mapGetters} from 'vuex';
export default ({
  name:'UserProfileSetting',
  
  data() {
    return{
      oldpass:"",
      newpass:"",
      confpass:"",
    }
  },
  methods: {
    async changepass(){
      try{
        window.console.log(this.newpass);
        window.console.log(this.confpass);
        if(this.newpass == this.confpass){
          let res = await axios.post(`auth/update_pass`,{
            userID:this.user.id,
            password:this.newpass
          });
          window.console.log(res)
        }else{
          window.console.log('the password isn\'t the same');
        }

      }catch(e){
        window.console.error(e);
      }
    },
  },
  computed:{
    ...mapGetters(['user']),
    ...mapGetters(['store']),
  },
})
</script>

<style scoped>
.Setting
{
    position: relative;
    background: #fff;
    padding: 40px 40px;

}
.ListPublication .card-title
{
    padding: 0 30px 0 0;
}
form{
    margin: 50px 0;
}
.input-field {
  position: relative;
  width: 400px;
  height: 50px;
  line-height: 35px;
}
input{
  max-width: 100%;
  border: 0;
  outline: 0;
  margin: 0 0 50px 0;
  padding: 0.5rem;
  border: 2px solid #d3d3d3;
  font-size: 1.2em;
  box-shadow: none;
  color: #111;
}
label {
  position: absolute;
  top: 0;
  left: 10px;
  padding: 0 10px;
  margin: 0 0 0 0;
  color: #d3d3d3;
  transition: 0.2s all;
  cursor: text;
}
input:invalid
{
  outline: 0;
}
input:focus,
input:valid {
  border-color: rgb(240, 100, 8);
}
input:focus~label,
input:valid~label{
  z-index: 1000;
  font-size: 1.4em;
  top: -23px;
  color: rgb(240, 100, 8);
  background: #ffff;
}
.BtnEnreg
{
    background: #F87127;
    color: #Fff;
    margin-left: 150px;
}
</style>

<template>
  <div>
    <CategoriesPieces></CategoriesPieces>
  </div>
</template>

<script>
import CategoriesPieces from "../components/CategoriesPieces.vue"
export default {
  name: 'MecCat',
  components: {
      CategoriesPieces,
  }
}
</script>
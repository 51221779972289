<template>
  <b-container class="addProductPage">
        <b-button v-b-tooltip.hover variant="light" title="Retour" @click="$router.go(-1)"><b-icon scale="1.5" icon="arrow-left-circle" font-scale="1.5">Retour</b-icon></b-button>
        <b-card title="Details de la piece" sub-title="">
            <b-form>
                <b-input-group id="input-group-2"  class="input-field mb-3">
                    <!-- <b-form-input id="input-2"  required  ></b-form-input> -->
                    <label for="Nom_utilisateur">Nom de la pièce</label>
                    <b-form-input list="input-list" id="input-with-list"></b-form-input>
                    <b-form-datalist id="input-list" :options="productsList"></b-form-datalist>
                </b-input-group>

                <b-input-group id="input-group-3" class="input-field mb-4">
                    <b-form-textarea></b-form-textarea>
                    <label for="Nom_description">Description pièce</label>
                </b-input-group>

                <b-input-group id="input-group-3"  class="input-field mb-4">
                    <b-form-textarea></b-form-textarea>
                    <label for="Nom_utilisateur">Description Supp</label>
                </b-input-group>

                <b-button class="BtnEnreg">Enregistrer</b-button>
            </b-form>
        </b-card>
        <b-card title="Details pièce" sub-title="">
            <b-form>
                <b-input-group id="input-group-2" prepend="Type de pièce" label-for="input-4" class="mb-3">
                    <b-form-select v-model="selected" :options="options"></b-form-select>
                </b-input-group>
                <!--<b-input-group id="input-group-2" prepend="Product type name:" label-for="input-4" class="mb-3">
                    <b-form-select v-model="selected" :options="options"></b-form-select>
                </b-input-group>-->
                <b-input-group id="input-group-3" prepend="Description" label-for="input-6" class="mb-3">
                    <b-form-textarea></b-form-textarea>
                </b-input-group>

                <b-input-group id="input-group-3" prepend="Autres description" label-for="input-6" class="mb-3">
                    <b-form-textarea></b-form-textarea>
                </b-input-group>

                <b-button class="BtnEnreg">Enregistrer</b-button>
            </b-form>
        </b-card>
        <b-card title="Couleur de produit" sub-title="">
            <b-form-input type="color"></b-form-input>
            <br>
            <br>
            <b-button class="BtnEnreg">Ajouter couleur</b-button>
            <br>
            <br>
            <b-img v-for="color in colors" :key="color.id" v-bind="mainProps" :blank-color="color.color_value" ></b-img>
        </b-card>
        <b-card title="Pièce compatible" sub-title="">
            <b-form>
                <b-input-group id="input-group-2" prepend="Marque de voiture:" label-for="input-4" class="mb-3">
                    <b-form-select v-model="selectedCar" :options="cars" @change="changeCar"></b-form-select>
                </b-input-group>

                <b-input-group id="input-group-3" prepend="Modèle de voiture:" label-for="input-6" class="mb-3">
                    <b-form-select v-model="selectedmodel" :options="carmodel" @change="modelchanged"></b-form-select>
                </b-input-group>

                <b-input-group id="input-group-3" prepend="Année de production:" label-for="input-6" class="mb-3">
                        <b-form-select v-model="selectedyear" :options="modelYears" ></b-form-select>
                </b-input-group>

                <b-input-group id="input-group-3" prepend="Description" label-for="input-6" class="mb-3">
                    <b-form-select v-model="selected" :options="options"></b-form-select>
                </b-input-group>

                <b-input-group id="input-group-3" prepend="Autre descriptions" label-for="input-6" class="mb-3">
                        <b-form-select v-model="selected" :options="options"></b-form-select>
                </b-input-group>

                <b-button class="BtnEnreg">Ajouter</b-button>

                <b-table hover :items="listcars"></b-table>
            </b-form>
        </b-card>
  </b-container>
</template>


<script>
import axios from 'axios';
export default {
  props: {
      userID:String,
  },
        name:'AddProduct',
        data() {
            return {
                show: true,
                colors:[{id:1,color_value:"#003344"}],
                mainProps: { blank: true, width: 40, height: 40},
                carsdata:[],
                cars:[],
                selectedCar:null,
                carmodel:[],
                models:[],
                selectedmodel:null,
                modelYears:[],
                selectedyear:null,
                carcarburants:[],
                selectedcarburat:null,
                carcapacity:[],
                selectedcapacity:null,
                products:[],
                selectedProd:null,
                prodData:[],
                types:[],
                selectedType:null,
                typData:[],
                prodDesc:'',
                otherprodDesc:'',
                selectedcolor:'',
                listcolors:[],
                listcars:[],
                productsList:['Apple', 'Banana', 'Grape', 'Kiwi', 'Orange'],
                // typDesc:'',
            }
        },
        methods: {
            async addProduct ()  {
                try{
                    let res = await axios.post(``,);
                    if(res.status == 200){
                        window.console.log('product added');
                    }else{
                        window.console.log('a problem apeared');
                    }
                }catch(err){
                    window.console.error(err);
                }
            },
            addcar(){
                this.listcars.push({brand:this.selectedCar,model:this.selectedmodel,year:this.selectedYear,
                energytype:this.selectedcarburat,capacity:this.selectedcapacity});
                
            },
            addcolor(){
                this.listcolors.push(this.selectedcolor);
                window.console.log(this.selectedcolor);
                window.console.log(this.listcolors);
            },
            changeProd(){
                let prods =  this.prodData.filter(m =>m.product_name == this.selectedProd);
                this.prodDesc = prods[0].product_description;
                this.otherprodDesc = prods[0].other_product_description;
            },
            // changeType(){
            //     let typs = this.typData.filter(m =>m.type_name == this.selectedType);
            //     this.typDesc = typs[0].product_type_description;
            // },
            changeCar(){
                let models = this.carsdata.filter((m) => m.brand == this.selectedCar);
                this.carmodel = [{ value: null, text: 'Séléctionner votre modèle' },];
                this.modelYears = [{ value: null, text: 'l\'année de véhicule' },]
                this.models = models[0].car_models;
                for(let model of models[0].car_models){
                    this.carmodel.push({value: model.model, text:model.model});
                    this.modelYears.push({value:model.year,text:model.year});
                }
            },
            modelchanged(){
                let model = this.models.filter((m) => m.model == this.selectedmodel);
                // this.carcarburants = [{value: null, text: 'Carburant' }];
                
                for(let m of model[0].car_engines){
                    this.carcarburants.push({value: m.energy_type, text:m.energy_type});
                    this.carcapacity.push({value:m.capacity,text:m.capacity})
                }
            },

        },
        async mounted() {
            try{   
                this.cars = [];
                let products = await axios.get(`user/get_products`);
                this.prodData = products.data;
                for(let product of products.data) {
                    this.products.push({value:product.product_name,text:product.product_name});
                }
                let types = await axios.get(`user/get_types_children`);
                for(let type of types.data){
                    this.types.push({value:type.product_type_name,text:type.product_type_name})
                }
                let cars = await axios.get(`user/model_brand_engine`);
                this.carsdata = cars.data;
                for(let car of cars.data){
                    this.cars.push({value:car.brand,text:car.brand})
                }

            }catch(err){
                window.console.error(err);
            }
        },
}
</script>

<style scoped>

.addProductPage
{
    background: #fff;
    padding: 20px 20px;
}
form{
    margin: 20px 0;
}
.input-field {
  position: relative;
  width: 400px;
  height: 80px;
  line-height: 35px;
}
input{
  max-width: 100%;
  outline: 0;
  margin: 0 0 20px 0;
  padding: 0.5rem;
  border: 2px solid #d3d3d3;
  font-size: 1.2em;
  box-shadow: none;
  color: #111;
}
textarea{
  max-width: 100%;
  outline: 0;
  margin: 0 0 0px 0;
  padding: 0;
  border: 2px solid #d3d3d3;
  font-size: 1.2em;
  box-shadow: none;
  color: #111;
}
label {
  position: absolute;
  top: 0;
  left: 10px;
  padding: 0 10px;
  margin: 0 0 0 0;
  color: #d3d3d3;
  transition: 0.2s all;
  cursor: text;
}
input:invalid,
textarea:invalid
{
  outline: 0;
}
input:focus,
input:valid,
textarea:focus,
textarea:valid {
  border-color: rgb(240, 100, 8);
}
input:focus~label,
input:valid~label,
textarea:focus~label,
textarea:valid~label{
  z-index: 1000;
  font-size: 1.4em;
  top: -23px;
  color: rgb(240, 100, 8);
  background: #ffff;
}
.BtnEnreg
{
    background: #F87127;
    color: #Fff;
    margin-left: 150px;
}

.input-group-text
{
    background: rgba(240, 101, 8, 0.096);
    width: 150px;
}
.sauvgardInformation
{
    position: relative;
    display: flex;
    justify-content: center;
}
.buttonEnregistrer
{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 3px solid rgb(240, 100, 8);
    padding: 3px 25px;
    font-size: 1.2rem;
    font-weight: 700;
    border-radius: 20px;
    border-radius: 20px;
    background: rgb(240, 100, 8);
}
.buttonEnregistrer:focus
{
  box-shadow: none;
}

</style>
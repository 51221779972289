import { render, staticRenderFns } from "./StoreStepTow.vue?vue&type=template&id=074bef54&"
import script from "./StoreStepTow.vue?vue&type=script&lang=js&"
export * from "./StoreStepTow.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
<template>
    <b-container class="ProfilMember">
       <h2> Information Utilisateur </h2>
        <b-form class="mt-5">
            <b-input-group  id="input-group-5" label-for="input-5" class="input-field mb-5 mt-5">
                <b-form-input v-b-tooltip.hover title="Nom d'utilisateur" id="input-5" required v-model="data.username" ></b-form-input>
                <label for="Nom_utilisateur">Nom d'utilisateur</label>
            </b-input-group>

            <b-input-group id="input-group-1" label-for="input-2" class="input-field mb-5">
                <b-form-input v-b-tooltip.hover title="e-mail" id="input-1" type="email" required v-model="data.email" ></b-form-input>
                <label for="Email">E-mail</label>
            </b-input-group>

            <b-button class="BtnEnreg">Enregistrer</b-button>
        </b-form>
    </b-container>
</template>

<script>
// import WilayaCommune from '../components/WilayaCommune.vue';
import axios from 'axios';
import {mapGetters} from 'vuex';
export default {

  props: {
      userID:String,
  },
        name:'UserProfileMember',
        data() {
            return {
                selected: null,
                selectedL: null,
                selectedS: null,
                optionsL: [
                    { value: null, text: 'Selectionez votre langue' },
                    { value: 'Ar', text: 'Arabe' },
                    { value: 'Fr', text: 'Français' },
                    { value: 'An', text: 'Anglais'}
                ],
                data:{},
                email:'', 
                username:'',
            }
            },
        components:{
            //'my-WilayaCommune':WilayaCommune
        },
        methods: {
            async updateProfile(){
                try{
                    let res = await axios.put(`info/update_profile/${this.user.id}/${this.username}/${this.email}`);
                    window.console.log(res.data);
                }catch(err){
                    window.console.error(err);
                }
            },
        },
        async mounted() {
            try{
                // this.userID = '4ed3a39a-ea8b-4dfb-8ce8-a652c06de229';
                let res = await axios.get(`info/get_info/${this.user.id}`);
                this.data = res.data;
                this.email = this.data.email;
                this.username = this.data.username;
            }catch(e){
                window.console.error(e);
            }
        },
        computed:{
            ...mapGetters(['user']),
        },
}
</script>

<style scoped>
.ProfilMember
{
    background: #fff;
    padding: 40px 40px;
}
form{
    margin: 50px 0;
}
.input-field {
  position: relative;
  width: 400px;
  height: 50px;
  line-height: 35px;
}
input{
  max-width: 100%;
  border: 0;
  outline: 0;
  margin: 0 0 50px 0;
  padding: 0.5rem;
  border: 2px solid #d3d3d3;
  font-size: 1.2em;
  box-shadow: none;
  color: #111;
}
label {
  position: absolute;
  top: 0;
  left: 10px;
  padding: 0 10px;
  margin: 0 0 0 0;
  color: #d3d3d3;
  transition: 0.2s all;
  cursor: text;
}
input:invalid
{
  outline: 0;
}
input:focus,
input:valid {
  border-color: rgb(240, 100, 8);
}
input:focus~label,
input:valid~label{
  z-index: 1000;
  font-size: 1.4em;
  top: -23px;
  color: rgb(240, 100, 8);
  background: #ffff;
}

.BtnEnreg
{
    background: #F87127;
    color: #Fff;
    margin-left: 150px;
}
</style>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',[_c('b-card',{attrs:{"title":"User Phones","sub-title":"Phones"}},[_c('b-form',[_c('b-form-tags',{staticClass:"mb-2",attrs:{"no-outer-focus":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var tags = ref.tags;
var inputAttrs = ref.inputAttrs;
var inputHandlers = ref.inputHandlers;
var addTag = ref.addTag;
var removeTag = ref.removeTag;
return [_c('b-input-group',{attrs:{"aria-controls":"my-custom-tags-list"}},[_c('input',_vm._g(_vm._b({staticClass:"form-control",attrs:{"placeholder":"New Phone"}},'input',inputAttrs,false),inputHandlers)),_c('b-input-group-append',[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){addTag(),_vm.addphoneUser(inputAttrs)}}},[_vm._v("Add")])],1)],1),_c('ul',{staticClass:"list-unstyled d-inline-flex flex-wrap mb-0",attrs:{"id":"my-custom-tags-list","aria-live":"polite","aria-atomic":"false","aria-relevant":"additions removals"}},_vm._l((tags),function(tag){return _c('b-card',{key:tag,staticClass:"mt-1 mr-1",attrs:{"id":("my-custom-tags-tag_" + (tag.replace(/\s/g, '_')) + "_"),"tag":"li","body-class":"py-1 pr-2 text-nowrap"}},[_c('strong',[_vm._v(_vm._s(tag))]),_c('b-button',{attrs:{"variant":"link","size":"sm","aria-controls":("my-custom-tags-tag_" + (tag.replace(/\s/g, '_')) + "_")},on:{"click":function($event){removeTag(tag),_vm.removePhoneUser(tag)}}},[_vm._v("remove")])],1)}),1)]}}]),model:{value:(_vm.phones),callback:function ($$v) {_vm.phones=$$v},expression:"phones"}})],1)],1),_c('b-card',{attrs:{"title":"Store Phones","sub-title":"Phones"}},[_c('b-form',[_c('b-form-tags',{staticClass:"mb-2",attrs:{"no-outer-focus":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var tags = ref.tags;
var inputAttrs = ref.inputAttrs;
var inputHandlers = ref.inputHandlers;
var addTag = ref.addTag;
var removeTag = ref.removeTag;
return [_c('b-input-group',{attrs:{"aria-controls":"my-custom-tags-list"}},[_c('input',_vm._g(_vm._b({staticClass:"form-control",attrs:{"placeholder":"New Phone"}},'input',inputAttrs,false),inputHandlers)),_c('b-input-group-append',[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){addTag(),_vm.addphoneStore(inputAttrs)}}},[_vm._v("Add")])],1)],1),_c('ul',{staticClass:"list-unstyled d-inline-flex flex-wrap mb-0",attrs:{"id":"my-custom-tags-list","aria-live":"polite","aria-atomic":"false","aria-relevant":"additions removals"}},_vm._l((tags),function(tag){return _c('b-card',{key:tag,staticClass:"mt-1 mr-1",attrs:{"id":("my-custom-tags-tag_" + (tag.replace(/\s/g, '_')) + "_"),"tag":"li","body-class":"py-1 pr-2 text-nowrap"}},[_c('strong',[_vm._v(_vm._s(tag))]),_c('b-button',{attrs:{"variant":"link","size":"sm","aria-controls":("my-custom-tags-tag_" + (tag.replace(/\s/g, '_')) + "_")},on:{"click":function($event){removeTag(tag),_vm.removePhoneStore(tag)}}},[_vm._v("remove")])],1)}),1)]}}]),model:{value:(_vm.phones),callback:function ($$v) {_vm.phones=$$v},expression:"phones"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <b-container>
      <b-card title="User addresses" sub-title="Addresses">
        <b-form>
            <b-input-group id="input-group-2" prepend="Wilaya:" label-for="input-4" class="mb-3">
                <b-form-select v-model="form.userAddress.wilaya" :options="wilayasUser" @change="clientChangeWilaya"></b-form-select>
            </b-input-group>

            <b-input-group id="input-group-2" prepend="Daira:" label-for="input-3" class="mb-3">
                <b-form-select v-model="form.userAddress.daira" :options="dairasUser" @change="clientChangeDaira"></b-form-select>
            </b-input-group>

            <b-input-group id="input-group-2" prepend="Commune:" label-for="input-3" class="mb-3">
                <b-form-select v-model="form.userAddress.commune" :options="communesUser"></b-form-select>
            </b-input-group>

            <b-input-group id="input-group-2"  prepend="Street:" label-for="input-3" class="mb-3">
                <b-form-input aria-describedby="input-street-feedback" v-model="form.userAddress.street" id="input-2"></b-form-input>
            </b-input-group>
            <b-alert v-if="$v.form.userAddress.street.$error" variant="danger" show>invalid zip</b-alert>
            <b-input-group id="input-group-2" prepend="Zip:" label-for="input-3" class="mb-3">
                <b-form-input aria-describedby="input-zip-feedback" v-model="form.userAddress.zip" id="input-2"></b-form-input>
            </b-input-group>
            <b-alert v-if="$v.form.userAddress.zip.$error" variant="danger" show>invalid zip</b-alert>
            <b-button  @click.prevent="clientAddress" v-b-modal.modal-1 variant="outline-primary"><b-icon icon="plus-circle"></b-icon> Add Address</b-button>
            <b-card no-body class="mb-1" v-for="address in userAddresses" :key="address.num">
                <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-1 variant="info">address {{address.num}}</b-button>
                </b-card-header>
                <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                    <b-card-text>Wilaya: {{ address.wilaya }}</b-card-text>
                    <b-card-text>Daira: {{ address.daira }}</b-card-text>
                    <b-card-text>Commune: {{ address.commune }}</b-card-text>
                    <b-card-text>Rue:</b-card-text><b-card-text>{{ address.rue }}</b-card-text>
                    <b-card-text>Zip: {{ address.rue }}</b-card-text>
                    <b-button @click="removeUserAddress(address.id)">remove</b-button>
                    </b-card-body>
                </b-collapse>
            </b-card>
        </b-form>
      </b-card>
    </b-container>
</template>

<script>
  import {validationMixin} from 'vuelidate'
  import axios from 'axios';
  import {minLength,required} from 'vuelidate/lib/validators'
  import {mapGetters} from 'vuex'; 
export default {
  props: ['clickedNext', 'currentStep'],
  mixins: [validationMixin],
  data() {
    return {
      form: {
        userAddress:{
          wilaya:null,
          daira:null,
          commune:null,
          street:'',
          zip:'',
          userAddresses:[],
        },
      },
      wilayasUser:[],
      dairasUser:[],
      communesUser:[],
      nbrAC:0,
    };
  },
  validations: {
    form: {
      userAddress:{
        userAddresses:{
          required,
          minLength:minLength(1)
        },
        wilaya:{
          required
        },
        daira:{
          required
        },
        commune:{
          required
        },
        street:{
          required
        },
        zip:{
          required
        }
      },
    }
  },

    watch: {
        $v: {
            handler: function (val) {
                if(!val.$invalid) {
                  this.$emit('can-continue', {value: true});
                } else {
                  this.$emit('can-continue', {value: false});
                }
            },
            deep: true
        },
        clickedNext(val) {
          if(val === true) {
            this.$v.form.$touch();
          }
        }
    },
    computed:{
      ...mapGetters(['user']),
    },
    methods: {
      async removeUserAddress(addressID){
        try{
            var vm = this;
            await axios.post(`info/add_address`,{
                addressID: addressID
            });
            vm.$forceUpdate();
        }catch(e){
            window.console.error(e);
        }
      },
      async clientAddress(){
        try{
          this.nbrAC = this.nbrAC + 1;
          this.form.userAddress.userAddresses.push({id:this.nbrAC,wilaya: this.form.userAddress.wilaya, daira: this.form.userAddress.daira, commune: this.form.userAddress.commune,zip:this.form.userAddress.zip ,rue: this.form.userAddress.street,num:this.nbrAC});
          let res = await axios.post(`info/add_address`,{
            userID:this.user.id,
            wilaya:this.form.userAddress.wilaya,
            daira:this.form.userAddress.daira,
            commune:this.form.userAddress.commune,
            rue:this.form.userAddress.street,
            zip:this.form.userAddress.zip,
          });
          
          // this.nbrAS = this.nbrAS + 1;
          window.console.log(res);
        }catch(err){
          window.console.error(err);
        }
      },
    clientChangeWilaya(){
      this.dairasUser = [];
      this.communesUser = [];
      let dairas = this.wilayasUser.filter(m =>m.value == this.form.userAddress.wilaya);
      
      this.dairasUser = dairas[0].list_daira;
      
    },
    clientChangeDaira(){
      this.communesUser = [];
      let communes = this.dairasUser.filter(m =>m.value == this.form.userAddress.daira);
      
      this.communesUser = communes[0].list_communes;
    },
    storeChangeWilaya(){
      this.dairasStore = [];
      this.communesStore = [];
      let dairas = this.wilayasUser.filter(m =>m.value == this.form.storeAddress.wilaya);
      
      this.dairasStore = dairas[0].list_daira;
    },
    storeChangeDaira(){
      this.communesStore = [];
      let communes = this.dairasStore.filter(m =>m.value == this.form.storeAddress.daira);
      
      this.communesStore = communes[0].list_communes;
    },
  },
        async beforeMount(){
          try{
            let res = await axios.get('user/get_wilaya');
            let communes = [];
            let dairas = [];
            for(let wil of res.data){
              dairas = [];
              for(let dai of wil.dairas){
                communes = [];
                for(let com of dai.communes){
                  communes.push({value:com.commune,text:com.commune});
                }
                dairas.push({
                    value:dai.daira,
                    text:dai.daira,
                    list_communes:communes
                });
              }
              this.wilayasUser.push({
                value:wil.wilaya,
                text:wil.wilaya,
                list_daira:dairas
              })
            }
          }
          catch(err){
            window.console.error(err);
          }
        },
        mounted() {
          if(!this.$v.$invalid) {
            this.$emit('can-continue', {value: true});
          } else {
            this.$emit('can-continue', {value: false});
          }
        },
};
</script>
import { render, staticRenderFns } from "./Activation.vue?vue&type=template&id=207a4930&scoped=true&"
import script from "./Activation.vue?vue&type=script&lang=js&"
export * from "./Activation.vue?vue&type=script&lang=js&"
import style0 from "./Activation.vue?vue&type=style&index=0&id=207a4930&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "207a4930",
  null
  
)

export default component.exports
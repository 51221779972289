<template>
    <b-container>
      <b-card title="User Informations" sub-title="Informations">
        <b-form>
            <b-input-group id="input-group-2" prepend="Prénom:" label-for="input-4" class="mb-3">
                <b-form-input :class="['input',($v.form.Userform.first_name.$error) ? 'is-danger' : '']" id="input-2" v-model="form.Userform.first_name"></b-form-input>
            </b-input-group>
            <b-alert v-if="$v.form.Userform.first_name.$error" variant="danger" show>Ce nom d'utilisateur est invalide</b-alert>

            <b-input-group id="input-group-2" prepend="Nom:" label-for="input-3" class="mb-3">
                <b-form-input id="input-2" v-model="form.Userform.last_name"></b-form-input>
            </b-input-group>
            <b-alert v-if="$v.form.Userform.last_name.$error" variant="danger" show>Ce nom d'utilisateur est invalide</b-alert>

            <b-input-group id="input-group-2" prepend="Date de naissance:" label-for="input-3" class="mb-3">
                <b-form-datepicker id="datepicker-buttons" v-model="form.Userform.birthday" :placeholder="birthday" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" today-button locale="fr" ></b-form-datepicker>
            </b-input-group>
            <b-alert v-if="$v.form.Userform.birthday.$error" variant="danger" show>Ce nom d'utilisateur est invalide</b-alert>

            <b-input-group id="input-group-2" prepend="Sexe:" label-for="input-3" class="mb-3">
                <b-form-select v-model="form.Userform.sex" :options="optionsS"></b-form-select>
            </b-input-group>
            <b-alert v-if="$v.form.Userform.sex.$error" variant="danger" show>Ce nom d'utilisateur est invalide</b-alert>
        </b-form>
      </b-card>   
    </b-container>
</template>

<script>
    import {validationMixin} from 'vuelidate'
    import {required} from 'vuelidate/lib/validators'
    import axios from 'axios';
    import {mapGetters} from 'vuex';
    export default {
    props: ['clickedNext', 'currentStep'],

    mixins: [validationMixin],
    data() {
      return {
        optionsS: [
          { value: null, text: 'Seléctioner votre sexe' },
          { value: 'male', text: 'homme' },
          { value: 'female', text: 'femme' },
        ],
        form:{
          Userform:{
            first_name:'',
            last_name:'',
            birthday:'',
            sex:null
          },
        },
      }
    },
    validations: {
      form:{
        Userform:{
          first_name:{
            required,
          },
          last_name:{
            required,
          },
          birthday:{
            required
          },
          sex:{
            required
          }
        },
      }
    },
    watch: {
      $v: {
        handler: function (val) {
          if(!val.$invalid) {
            this.$emit('can-continue', {value: true});
          } else {
            this.$emit('can-continue', {value: false});
          }
        },
        deep: true
      },
      clickedNext(val) {
        if(val === true) {
          this.$v.form.$touch();
        }
      }
    },
    methods: {
      async userInfo(){
        try{
          window.console.log(this.userID);
          let birthday = new Date(this.form.Userform.birthday);
          let res = await axios.post(`info/add_info`,{
            userID:this.user.id,
            birthday:birthday,//this.form.Userform.birthday,
            first_name:this.form.Userform.first_name,
            last_name:this.form.Userform.last_name,
            sex:this.form.Userform.sex,
          });
          window.console.log(res);
        }catch(err){
          window.console.error(err);
        }
      },
    },
    computed:{
      ...mapGetters(['user']),
    },
    async mounted() {
      window.console.log(this.user);
      if(!this.$v.$invalid) {
        this.$emit('can-continue', {value: true});
      } else {
          this.$emit('can-continue', {value: false});
      }
    },
    async deactivated(){
      this.userInfo();
      this.$router.push({
        params:{store: this.storeID}
      });
    }
  }
</script>
<template>
    <b-container class="cardTopAgentService" fluid no-guttars>
        <div class="row">
            <div  v-for="card in cards" :key="card.name" class="cardAgent  my-1">
                <div class="card h-100">
                    <div class="card-img-top">
                        <img :src="card.img">
                        <!--<div>
                            <span class="badge badge-pill badge-primary">{{ card.price }} DA</span>
                        </div>-->
                    </div>
                    <div class="card-body">
                        <div class="AgentNameStore">{{ card.store }}</div>
                        <div class="card-title" >{{ card.name }}</div>
                        <div class="AgentSpecialite"><NOBR>{{ card.specialite }}</NOBR></div>
                        <div class="AgentAdresse"><NOBR>{{ card.adresse}}</NOBR></div>
                        
                    </div>
                    <div class="RatingAgentService">
                        <StarRating :glyph="Stars" :spacing="1" active-color="#f6c23d" :increment="1" :fixed-points="0" :item-size="10" :rating="rating" :read-only="true"></StarRating>
                        <div class="VisiterProfil ml-3">
                            <button  class="buttonProfil" :to="`/${$i18n.locale}/PagePiece`">Profil</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </b-container>
</template>

<script>

import { StarRating } from "vue-rate-it";
import Star_Rating from "vue-rate-it/glyphs/star";

export default {
    name:'CardTopAgentService',
    props: {
        cards: {
        }
    },
    components: {
    StarRating,
    },
	created(){
    // register the icon
        this.Stars = Star_Rating
    },
    data() {
        return {
            
    }
    }
}
</script>

<style scoped>
    .cardTopAgentService
    {
        position: relative;
    }
    .cardTopAgentService .cardAgent
    {
        position: relative;
        display: flex;
        justify-content: center;
        max-width: 250px;
        padding: 20px 2px 0;       
    }
    .cardTopAgentService .card-body
    {
        position: relative;
        display: block;
        width: 180px;
        margin: 0;
        padding: 0 5px;
        cursor: pointer ;
    }
    .cardTopAgentService .card-img-top
    {
        padding-left: 0;
        padding-right: 0;
        display: inline-block;
        padding: 0 0;  
    }    
    .cardTopAgentService .card-img-top img
    {
        position: relative;
        min-width: 100%;
        height: 100px;
    }
    .cardTopAgentService .card-title,
    .cardTopAgentService .AgentNameStore
    {
        font-weight: 600;
        text-align: center;
    }
    .cardTopAgentService .card-title:hover
    {
        cursor: pointer ;
        -webkit-text-decoration-line: underline; /* Safari */
        text-decoration-line: underline; 
    }
    .cardTopAgentService .card:hover
    {
        box-shadow: 0 1px 10px 5px rgba(0,0,0,.1);
    }
    .cardTopAgentService .badge 
    {
        position: absolute ;
        Top: 5px;
        right: 10px;
        font-size: 1rem;
        color: #ff0a0a;
        background: rgb(214, 214, 214);
    }
    .cardTopAgentService .card-body .AgentSpecialite,
    .cardTopAgentService .card-body .AgentAdresse
    {
        overflow: hidden
    }
    .RatingAgentService 
    {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        padding: 0;
    }
    .RatingAgentService .StarRating
    {
        max-width: 50%;
    }
    .VisiterProfil
    {
        position: absolute;

        right: 3px;

    }
    .buttonProfil
    {
        border-width: 1px;
        font-size: 900;
        border-radius: 5px;
        color: #fff;
        font-weight: 700;
        background: rgb(240, 100, 8);
    }
    .buttonProfil:focus,
    .buttonProfil:active
    {
        color: rgb(240, 100, 8);
        background: #fff;
        border: 1px solid rgb(240, 100, 8);
        box-shadow: 0 0 0 0.1rem rgba(240, 101, 8, 0.287);
    }
</style>
<template>
  <b-container fluid class="topOfNavbar">
    <b-row>
      <b-col cols="12" class="nomEntrepriseSM" :to="`/${$i18n.locale}/Home`">
          <b-link href="" :to="`/${$i18n.locale}/`"><img src="../assets/logoMikaniky.png" class="d-inline-block align-top" ></b-link>
      </b-col>
      <b-col cols="6" sm="2" md="3" lg="4" xl="4" class="Service">
         <b-navbar  type="light" sticky>                    
                <b-navbar-toggle target="navbar-toggle-collapse">
                    <template v-slot:default="{ expanded }">
                        <b-icon v-if="expanded" icon="chevron-bar-up"></b-icon>
                        <b-icon v-else icon="chevron-bar-down"></b-icon>
                    </template>
                </b-navbar-toggle>

                <b-collapse  id="navbar-toggle-collapse" is-nav>
                    <!-- Right aligned nav items -->
                    <b-navbar-nav class="ml-auto">
                      <b-nav-item>
                          <b-button v-if="false" v-b-tooltip.hover title="Catégorie" class="btSideBarLeft" v-b-toggle.sidebar-backdrop><b-icon icon="list" font-scale="1.5"></b-icon></b-button>
                          <b-sidebar
                            id="sidebar-backdrop"
                            title="Mikaniky"
                            variant="dark"
                            backdrop
                            shadow
                          >
                            <div class="px-3 py-2">
                              <b-row align-h="start">
                                <div class="w-100">
                                  <b-list-group flush class="outline-warning">
                                    <b-list-group-item button>
                                      <router-link :to="`/${$i18n.locale}/`"><b-btn v-b-toggle.collapse-1 class="btnCategries"><img v-bind="mainProps" rounded="circle" src="../assets/belt.png"/><h5><span> Embrayages </span></h5></b-btn>
                                        <b-collapse id="collapse-1" class="mt-2">
                                          <div class="sousCateg">
                                            
                                              <router-link :to="`/${$i18n.locale}/`"><h4>câble d'Embrayages</h4></router-link>
                                              <router-link :to="`/${$i18n.locale}/`"><h4>Kit complet</h4></router-link>
                                          </div>
                                        </b-collapse>
                                      </router-link>
                                        
                                    </b-list-group-item>
                                    <b-list-group-item button>
                                      <router-link :to="`/${$i18n.locale}/`"><b-btn v-b-toggle.collapse-2 class="btnCategries"><img v-bind="mainProps" rounded="circle" src="../assets/sportive-car.png"/><h5> Extérieurs </h5></b-btn>
                                        
                                        <b-collapse id="collapse-2" class="mt-2">
                                        <div class="sousCateg">
                                            <router-link :to="`/${$i18n.locale}/`"><h4>porte</h4></router-link>
                                            <router-link :to="`/${$i18n.locale}/`"><h4>verre</h4></router-link>
                                          </div>
                                        </b-collapse>
                                      </router-link>
                                    </b-list-group-item>
                                    <b-list-group-item button>
                                      <router-link :to="`/${$i18n.locale}/`"><b-btn class="btnCategries"><img v-bind="mainProps" rounded="circle" src="../assets/damper.png"/><h5> Suspension </h5></b-btn></router-link>
                                    </b-list-group-item>
                                    <b-list-group-item button>
                                      <router-link :to="`/${$i18n.locale}/`"><b-btn class="btnCategries"><img v-bind="mainProps" rounded="circle" src="../assets/engine.png"/><h5> Moteur </h5></b-btn></router-link>
                                    </b-list-group-item>
                                    <b-list-group-item button>
                                      <router-link :to="`/${$i18n.locale}/`"><b-btn class="btnCategries"><img v-bind="mainProps" rounded="circle" src="../assets/oil.png"/><h5> Filtration-Huile </h5></b-btn></router-link>
                                    </b-list-group-item>
                                    <b-list-group-item button>
                                      <router-link :to="`/${$i18n.locale}/`"><b-btn class="btnCategries"><img v-bind="mainProps" rounded="circle" src="../assets/brakes.png"/><h5> Freinage </h5></b-btn></router-link>
                                    </b-list-group-item>
                                    <b-list-group-item button>
                                      <router-link :to="`/${$i18n.locale}/`"><b-btn class="btnCategries"><img v-bind="mainProps" rounded="circle" src="../assets/exhaust_pipe.png"/><h5>  Echappement </h5></b-btn></router-link>
                                    </b-list-group-item>
                                    <b-list-group-item button>
                                      <router-link :to="`/${$i18n.locale}/`"><b-btn class="btnCategries"><img v-bind="mainProps" rounded="circle" src="../assets/exhaust.png"/><h5> Electricité </h5></b-btn></router-link>
                                    </b-list-group-item>
                                    <b-list-group-item button>
                                      <router-link :to="`/${$i18n.locale}/`"><b-btn class="btnCategries"><img v-bind="mainProps" rounded="circle" src="../assets/radiator.png"/><h5> Refroidissement </h5></b-btn></router-link>
                                    </b-list-group-item>
                                    <b-list-group-item button>
                                      <router-link :to="`/${$i18n.locale}/`"><b-btn class="btnCategries"><img v-bind="mainProps" rounded="circle" src="../assets/air-conditioning.png"/><h5> Chauffage-Climatisation </h5></b-btn></router-link>
                                    </b-list-group-item>
                                    <b-list-group-item button>
                                      <router-link :to="`/${$i18n.locale}/`"><b-btn class="btnCategries"><img v-bind="mainProps" rounded="circle" src="../assets/car-key.png"/><h5> Serrures-Fermeture </h5></b-btn></router-link>
                                    </b-list-group-item>
                                    <b-list-group-item button>
                                      <router-link :to="`/${$i18n.locale}/`"><b-btn class="btnCategries"><img v-bind="mainProps" rounded="circle" src="../assets/car-seat.png"/><h5> Accessoires </h5></b-btn></router-link>
                                    </b-list-group-item>
                                  </b-list-group>
                                </div>
                              </b-row>
                            </div>
                          </b-sidebar>
                      </b-nav-item>
                      <div class="btAccueil">
                        <b-nav-item>
                            <b-button v-b-tooltip.hover title="Accueil" :to="`/${$i18n.locale}/`"><b-icon icon="house-door-fill" font-scale="1.5">home</b-icon></b-button>
                        </b-nav-item>
                      </div>
                    </b-navbar-nav>
                    
                    <b-button v-if="!user" class="connexion_Button" :to="`/${$i18n.locale}/login`">
                      <font-awesome-icon v-b-tooltip.hover title="Connexion" class="ConnexionFont mr-3" :icon="['fa', 'user']" /> 
                    </b-button>
                    <b-navbar-nav v-if="user" class="langueFlag ml-auto">
                      <b-nav-item-dropdown :to="`/${$i18n.locale}/userprofile`" :text="$t('header.Lang')" class="mr-3">
                            <template v-slot:button-content> 
                              <b-avatar :src="`https://www.api.mikaniky.com/${user.pic}`"></b-avatar>
                            </template>
                        <b-dropdown-item disabled ><span>  {{user.username}}</span></b-dropdown-item>
                        <b-dropdown-item :to="`/${$i18n.locale}/userprofile`"><b-icon icon="person"></b-icon><span>  Profile</span></b-dropdown-item>
                        <b-dropdown-item :to="`/${$i18n.locale}/UserProfile/garage`"><b-icon icon="house"></b-icon><span>  Garage</span></b-dropdown-item>
                        <b-dropdown-item :to="`/${$i18n.locale}/userprofile/Setting`"><b-icon icon="gear"></b-icon><span>  Parameters</span></b-dropdown-item>
                        <b-dropdown-divider v-if="user.roles[0] == `ROLE_MANAGER`"></b-dropdown-divider>
                        <b-dropdown-item v-if="user.roles[0] == `ROLE_MANAGER`" :to="`/${$i18n.locale}/userprofile/informationStore`"><b-icon icon="shop"></b-icon><span>  Store</span></b-dropdown-item>
                        <!--<b-dropdown-item v-if="user.roles[0] == `ROLE_MANAGER`"  :to="`/${$i18n.locale}/userprofile/stats`"><b-icon icon="graph-up"></b-icon><span>  Statistics</span></b-dropdown-item>-->
                        <b-dropdown-divider></b-dropdown-divider>
                        <b-dropdown-item @click.prevent="disconnect"><b-icon icon="box-arrow-right"></b-icon><span>  Déconnecter</span></b-dropdown-item>
                        <b-dropdown-divider v-if="user.roles[0] == `ROLE_ADMIN`"></b-dropdown-divider>
                        <b-dropdown-item v-if="user.roles[0] == `ROLE_ADMIN`" ><b-icon icon="box-arrow-right"></b-icon><span>  Adminstrateur</span></b-dropdown-item>
                      </b-nav-item-dropdown>
                    </b-navbar-nav>
                </b-collapse>
        </b-navbar>
      </b-col>

      <b-col sm="4" md="4" lg="4" xl="4" class="nomEntreprise" :to="`/${$i18n.locale}/Home`">
          <b-link href="" :to="`/${$i18n.locale}/`"><img src="../assets/logoMikaniky.png" class="d-inline-block align-top" ></b-link>
      </b-col>

      <b-col cols="6" sm="6" md="5" lg="4" xl="4" class="connexion">
              <div class="recherchePiece">
                <b-row class="ZoneRecherche">
                  <b-form-input v-model="productName" class="InputRecherche " placeholder="Recherche"></b-form-input>

                  <!-- <select class="selectCategorieSearch " id="selectCategorieSearch" v-model="selectCategorie">
                  <option v-for="child in type_children" :key="child.id" :value="child.value">{{child.text}}</option>
                  </select> -->
                  <b-button class="ButtonLoupeRecherche " @click.prevent="search" :to="`/${$i18n.locale}/pagepiecerecherche`"><font-awesome-icon class="" :icon="['fa', 'search']"/></b-button>                            
                </b-row>
              </div>  
        <b-col class="langue ml-3">  
          <b-navbar-nav class="langueFlag">
                  <b-nav-item-dropdown :text="$t('header.Lang')" left class="mr-3">
                    <template v-slot:button-content> 
                      <b-img :src="require(`@/assets/flag_${$i18n.locale}.svg`)" height="25" width="25" alt="flag"></b-img> <span>{{$i18n.locale.toUpperCase()}}</span>
                    </template>
                    <b-dropdown-item @click.prevent="setLocale('ar')"><b-img :src="require('../assets/flag_ar.svg')" height="25" width="25" alt="flag_ar"></b-img><span>عربي</span></b-dropdown-item>
                    <b-dropdown-item @click.prevent="setLocale('en')"><b-img :src="require('../assets/flag_en.svg')" height="25" width="25" alt="flag_en"></b-img><span>English</span></b-dropdown-item>
                    <b-dropdown-item @click.prevent="setLocale('fr')"><b-img :src="require('../assets/flag_fr.svg')" height="25" width="25" alt="flag_fr"></b-img><span>Français</span></b-dropdown-item>
                  </b-nav-item-dropdown>
          </b-navbar-nav>
        </b-col>
      </b-col>
        <!-- <b-button v-if="user" class="connexion_Button" v-b-tooltip.hover title="Déconnecter " :to="`/${$i18n.locale}`"  @click.prevent="disconnect">
          <font-awesome-icon class="ConnexionFont mr-3" :icon="['fa', 'user']" />
        </b-button> -->
    </b-row>

  </b-container>
</template>

<script>
import axios from 'axios';
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faShoppingCart, faUser, faSearch } from "@fortawesome/free-solid-svg-icons";
import { bus } from '../main'
import {mapGetters} from 'vuex';
import store from '../Vuex';

library.add(faShoppingCart, faUser, faSearch);

export default {
  name: "Header",
  components: {
    "font-awesome-icon": FontAwesomeIcon,
  },
  data() {
    return {
      selectCategorie:null,
      datas:[],
      filtred_data:[],
      type_children:[{value:null,text:'Toutes les catégories',description:'',id:''}],
      searchResult:[],
      productName:'',
      profilepic:'',
      mainProps: { blank: true, width: 70, height: 70, class: 'm1' }
    };
    
  },
    methods:{
      disconnect(){
        localStorage.removeItem('x-auth-token');
        store.dispatch('user',null);
        store.dispatch('store',null);
        window.location.href = "https://www.mikaniky.com/";
      },
      async search () { 
        try{
          let res = null;
          if(this.productName == '' && this.selectCategorie == null){
            res = await axios.get(`user/get_all_products`);
          }
          else if(this.productName != '' && this.selectCategorie != null){
            res = await axios.get(`user/get_product_by_name/${this.productName}/${this.selectCategorie}`);
          } 
          else if(this.productName == '' && this.selectCategorie != null){
            res = await axios.get(`user/get_product_by_category/${this.selectCategorie}`);
          }
          else{
            res = await axios.get(`user/get_Product_name/${this.productName}`);
          }
          this.searchResult = res.data;
          bus.$emit('searchData',this.searchResult);
        }catch(e){
          window.console.error(e);
        }
      },
          setLocale(locale){
              this.$i18n.locale = locale
              
              this.$router.push({
                  params:{lang: locale}
              })
          }
      },
      async created(){
        try{ 
          let data = await axios.get(`user/get_types`);
          let children = await axios.get(`user/get_types_children`);
          this.datas = data.data;
          for(let d of this.datas){
            this.filtred_data.push({typekey:d.id, type_name:d.product_type_name, type_description:d.product_type_description});
          }
          for(let child of children.data){
            this.type_children.push({value:child.product_type_name,text:child.product_type_name,description:child.description,id:child.id});
          }
          // this.profilepic = ':3000/'+ this.user.pic.replace( /\\/g, "/");
          // let token = localStorage.getItem('x-auth-token');
          // let user = await axios.get(`info/get_user/${}`,{headers: {"token" : `${token}`}});
          // this.$store.dispatch('user',user.data);
        }
        catch (e){
          window.console.error(e);
        }
      },
      computed:{
        ...mapGetters(['user']),
      },
};
</script>

<style scoped>
.topOfNavbar {
  position: relative;
  height: 80px;
  padding: 5px 10px;
  border-bottom: 1px solid rgba(128, 128, 128, 0.466);
  box-shadow: 0px 1px 5px 0px rgba(119, 119, 119, 0.466);
}
.list-group-item
{
  padding: 0;
  margin: 10px 0;
  background: none;
  border: none;
}
.list-group-item a
{
  text-decoration: none;
}
.list-group-item .btnCategries
{
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  text-align: start;
  margin: 0;
  padding: 0 0 0 15px;
  width: 100%;
  height: 60px;
  background: #fff;
  color: #000;
  border: 2px solid rgba(255, 255, 255, 0);
  border-radius: 50px;
  z-index: 1000000;
}
.list-group-item .btnCategries:hover{
  background: #fff;
  color: #F87127;
  border-bottom: 2px solid #F87127;
  box-shadow: 0px 2px 3px 1px #f87027ea;
}
.list-group-item .btnCategries img
{
  width: 60px;
  height: 50px;
}
.list-group-item .btnCategries h5
{
  position: relative;
  text-decoration: none;
  font-weight: 800;
  width: 100%;
  margin: 0 0 0 10px;
}
.list-group-item .sousCateg h4
{
  position: relative;
  font-weight: 600;
  color: #000;
  margin: 0 0 0 10px;
  cursor:pointer;
}
.list-group-item .sousCateg h4:hover
{
  text-decoration: underline #f87027ea;  
}

/** .navbar-nav .dropdown-item
{
  color: #181818;
}
.dropdown-item:hover, .dropdown-item:focus {
    color: #1e2125;
    background-color: #ee7512;
}*/
.topOfNavbar .Service {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
        background: none;
}
.navbar 
    {
        position: relative;
        justify-content: space-between;
        padding: 5px 1rem;
    } 
.btn-secondary
{
  position: relative;
  background: #fff;
  color: rgb(240, 100, 8);
  border: none;
}
.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary:active
{
  color: rgb(240, 100, 8);
  background: rgba(240, 101, 8, 0.253);
  border-bottom: 3px solid rgb(240, 100, 8);
}
.topOfNavbar .connexion .connexion_Button:hover,
.topOfNavbar .connexion .Grage_Button:hover
{
  color: rgb(240, 100, 8);
  background: rgba(240, 101, 8, 0.096);
  border: 1px solid rgb(240, 100, 8);
  box-shadow: 0 0 0 0.1rem rgba(240, 54, 8, 0.472);
}
.nomEntrepriseSM
{
  display: none;
}
    .nomEntreprise
    {
        position: relative;
        display: flex;
        justify-content: flex-start ;
        align-items: flex-start;
       text-align: center;
    }
    .nomEntreprise img
    {
      margin: 0;
      padding: 0 10px 0 0;
      width: 250px;
      max-height: 70px;      /*le max de l'image logo dans la barre d'outil*/
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    }
.nomEntreprise {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0;
}    
    .topOfNavbar .connexion {
      position: relative;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-left: 5px;
    }
    .ZoneRecherche
    {
      width: 230px;
      height: 42px;
      border: 3px solid rgb(240, 100, 8);
      border-radius: 20px;
      margin: 0 0 0 0;
      padding: 0 0 0 0;
    }
    .ZoneRecherche:hover
    {
      border: 3px solid rgb(240, 100, 8);
      border-radius: 20px;
      box-shadow: 0 0 0 0.1rem rgba(197, 69, 19, 0.486);
    }
    .ZoneRecherche .InputRecherche
    {
      width: 185px;
      height: 36px;
      border: none;
      border-radius: 20px 0 0 20px;
    }
    .ZoneRecherche .InputRecherche:hover,
    .ZoneRecherche .InputRecherche:focus
    {
      margin: 0 0;
      padding: 0 0;
      border: none;
      box-shadow: none;
      background: rgb(238, 238, 238);
    }
    .ZoneRecherche .selectCategorieSearch
    {
      width: 150px;
      height: 36px;
      border:none;
      border-left: 1px solid rgb(246, 178, 133);
      background: #fff;
      box-shadow: none;
    }
    .ZoneRecherche .selectCategorieSearch:active,
    .ZoneRecherche .selectCategorieSearch:focus
    {
      margin: 0 0;
      padding: 0 0;
      border-left: 1px solid rgb(251, 154, 90);
    }
    .langueFlag
    {
      position: relative;
      display: flex;
      justify-content: flex-end;
    }
    .ButtonLoupeRecherche
    {
      margin: 0 0 0 0; 
      width: 39px;
      max-height: 40px;
      color: #fff;
      background: rgb(240, 100, 8);
      border: 3px solid rgb(240, 100, 8);
      border-radius: 0 20px 20px 0;
      margin: 0 0 0 0;
      padding: 0 0 0 0;
    }
    .ButtonLoupeRecherche:hover,
    .ButtonLoupeRecherche:focus
    {
      color:#fff;
      background: rgb(240, 100, 8);
      border: none;
      border-radius: 0 20px 20px 0;
      box-shadow: 0 0 0 0.1rem rgba(197, 69, 19, 0.486);
    }

@media (max-width: 992px) {
  .nomEntreprise span {
    font-size: 1.7rem;
  }
  .topOfNavbar .connexion .connexion_Button,
  .topOfNavbar .connexion .Grage_Button {
    font-size: 1.1rem;
  }
  
    .ZoneRecherche
    {
      width: 180px;
      max-height: 40px;
      border: 2px solid rgb(240, 100, 8);
    }
    .ZoneRecherche:hover
    {
      border: 1px solid rgb(240, 100, 8);
      
    }
    .ZoneRecherche .InputRecherche
    {
      max-width: 136px;
    }
    .ZoneRecherche .selectCategorieSearch
    {
      max-width: 120px;
    }
    .ButtonLoupeRecherche
    {
      margin: 0 0 0 0;
      width: 40px;
      height: 36px;
      border: 1px solid rgb(240, 100, 8);
    }

}
@media (max-width: 780px) {
.topOfNavbar {
  height: 60px;
  padding: 2px 4px;
  box-shadow: 0px 1px 3px 0px rgba(119, 119, 119, 0.466);
}
.navbar 
    {
        display: flex;
        justify-content: center;
        padding: 0px 0rem;
    } 
  .topOfNavbar .Service {
    justify-content: flex-start;
  }
  .btAccueil
  {
    display: none;
  }
  .nomEntreprise 
  {
          display: flex;
          justify-content: flex-end;

  }
        .nomEntreprise img
        {
          width: 150px;
          max-height: 45px;      /*le max de l'image logo dans la barre d'outil*/
          clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
        }       
    .ZoneRecherche
    {
      width: 144px;
      height: 36px;
    }
    .ZoneRecherche .InputRecherche
    {
      width: 112px;
      height: 32px;
    }
    .ZoneRecherche .selectCategorieSearch
    {
      width: 80px;
      height: 32px;
    }
    .ButtonLoupeRecherche
    {
      margin: 0 0 0 0;
      width: 28px;
      height: 32px;
      border: 1px solid rgb(240, 100, 8);
    }
}
@media (max-width: 576px){
  
.topOfNavbar {
  height: 80px;
  box-shadow: 0px 1px 3px 0px rgba(119, 119, 119, 0.466);
}

.navbar 
    {
      max-height: 35px;
      padding: 0px 0rem;
    } 
  .topOfNavbar .Service {
    max-height: 35px;
    justify-content: flex-start;
  }
  
  a.nav-link,
  .nav-link,
  .btn,
  .navbar-expand .navbar-nav .nav-link,
  .list-group-item .btnCategries
  {
    padding: 0 5px ;
  }


    .nomEntrepriseSM
    {
        position: relative;
        display: flex;
        justify-content: center ;
        align-items: center;
       text-align: center;
    }
    .nomEntrepriseSM img
    {
          width: 100px;
          max-height: 35px;      /*le max de l'image logo dans la barre d'outil*/
          clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    }
.nomEntreprise {
  display: none;
} 
       
    .ZoneRecherche
    {
      display: flex;
      justify-content: flex-end;
      width: 150px;
      height: 30px;
    }
    .ZoneRecherche .InputRecherche
    {
      max-width: 100px;
      height: 27px;
    }
    .ZoneRecherche .selectCategorieSearch
    {
      max-width: 25px;
      height: 27px;
    }
    .ButtonLoupeRecherche
    {
      width: 30px;
      height: 27px;
      border: 1px solid rgb(240, 100, 8);
    }
}
</style>
<template>
  <b-container fluid no-gutters class="StorePage">
    <div class="headerNomStore" fluid no-gutters>
      <h3 class="nomStore" id="nomStore">{{ nom_Store }}</h3>
    </div>
          <b-row class="contentStore" fluid no-gutter>
            <b-col class="imageStore" cols="12" md="6" lg="6" xl="6">
              <img src="../assets/les_phares.jpg" alt="Store déttaché">
            </b-col> 
              <b-col class="content_left" cols="12" md="6" lg="6" xl="6">                
                <b-row class="PrixStore"> 
                  <b-col v-if="false" class="nReferenceStore mt-3">
                     <h6  id="nReferenceStore">Référence Store: #{{ n_Reference }}</h6> 
                  </b-col>
                  <b-co>
                    <h6 class="wilayaStore" id="wilayaStore"><span><font-awesome-icon :icon="['fa', 'map-marker-alt']"/></span>  Adresse: {{ wilaya_Store }} , {{daira_Store}} ,{{ commune_Store }} , {{ rue_Store }} 
                    </h6>
                  </b-co>

                  <b-col class="RatingButtonStore mb-3 mt-3" md="10" lg="10" xl="10">
                    <StarRating :glyph="Stars" :spacing="1" active-color="#f6c23d" :increment="1" :fixed-points="0" :item-size="20" :rating="rating" :read-only="true"></StarRating>
                    <b-button class="buttonPageStore"  @click.prevent="get_contact" v-b-modal.modal-1>Les informations de contact</b-button>
                    <!-- :to="`/${$i18n.locale}/Store`" -->
                  </b-col>
                </b-row>

                
                <hr class="divider divider-xs bg-bermuda offset-top-15">
                   
                <b-col id="descriptionStore" class="descriptionStore mt-5">
                  <h3>Description: </h3>
                  <p>{{ description_Store }} </p>
                </b-col>
              </b-col>
          </b-row>
        <b-container v-if="user">
          <b-modal  id="modal-1" title="informations" size="lg">
            <p class="my-4">Téléphone :</p>
            <p class="my-4" v-for="phone in phones" :key="phone.number">{{phone.number}}</p> 
            <p class="my-4">Addresses:</p>
            <h6 class="wilayaStore" id="wilayaStore" v-for="adr in address" :key="adr.zip"><span><font-awesome-icon :icon="['fa', 'map-marker-alt']"/></span>wilaya:{{adr.wilaya}},{{adr.daira}},{{adr.commune}},{{adr.rue}},zip:{{adr.zip}}</h6>
            <!-- wilaya:adr.wilaya,daira:adr.daira,commune:adr.commune,rue:adr.rue,zip:adr.zip -->
            <a v-bind:href="fb">facebook</a><br>
            <a v-bind:href="insta">insta</a><br>
          </b-modal>
        </b-container> 
        <b-container class="tabsStore mt-5 mb-5">
          <b-tabs content-class="mt-3">
            <b-tab title="Pieces">
              <my-BestProducts></my-BestProducts>
            </b-tab>
          </b-tabs>
        </b-container>

        <my-PubDeuxHoriz></my-PubDeuxHoriz>
      </b-container>
</template>

<script>
import { StarRating } from "vue-rate-it";
import Star_Rating from "vue-rate-it/glyphs/star";
import BestProducts from "../views/BestProducts.vue";
import {mapGetters} from 'vuex';

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import PubDeuxHoriz from "../components/PubDeuxHoriz.vue";
import axios from 'axios';


library.add(faMapMarkerAlt);

export default {
  props: {
    storeID:String,
  },

  name: "Store",
  components: {
    StarRating,
    "font-awesome-icon": FontAwesomeIcon,
    "my-PubDeuxHoriz": PubDeuxHoriz,
    "my-BestProducts": BestProducts,
  },
  data() {
    return {
			Stars: '',		
			n_Reference:'',
			n_De_Store:'',
			wilaya_Store:'',
      commune_Store:'',
      daira_Store:'',
      rue_Store:'',
			nom_Store:'',
			rating:4,
      data:null,
      storePICS:[],
      storeProducts:[],
      contacts:null,
      phones:[],
      address:[],
      fb:'',
      insta:'',
    };
  },
    methods: {
    async get_contact(){
      try{
        this.contacts = null;
        this.phones = [];
        this.address = [];
        const res = await axios.get(`user/get_store_contact/${this.storeID}`);
        this.contacts = res.data;
        this.fb = res.data.fb;
        this.insta = res.data.insta;
        // window.console.log(this.contacts);
        for(let phone of this.contacts.contact.phones){
          this.phones.push({number:phone.phone_num});
        }
        for(let adr of this.contacts.contact.addresses){
          this.address.push({wilaya:adr.wilaya,daira:adr.daira,commune:adr.commune,rue:adr.rue,zip:adr.zip});
        }
        // window.console.log(this.phones);
        // window.console.log(this.address);
      }catch(e){
        window.console.error(e);
      }
    },
  },

  async created(){
    try{
      this.Stars = Star_Rating;
      const res = await axios({
        method: 'get',
        url:`user/get_store_id/${this.storeID}`
      });
      const res1 = await axios.get(`user/get_store_contact/${this.storeID}`);
      this.wilaya_Store = res1.data.contact.addresses[0].wilaya;
      this.commune_Store = res1.data.contact.addresses[0].commune;
      this.daira_Store = res1.data.contact.addresses[0].daira;
      this.rue_Store = res1.data.contact.addresses[0].rue;
      this.data = res.data;
      this.nom_Store = this.data.name;
      window.console.log(this.data);
      for(let storepic of this.data.store_pics){
        this.storePICS.push(storepic);
      }
      for(let product of this.data.products){
        this.storeProducts.push(product);
      }
      // window.console.log(this.storeProducts);
      // window.console.log(this.storePICS);
    }catch(err){
      window.console.error(err);
    }
  },
  computed:{
    ...mapGetters(['user']),
  },
};
</script>

<style scoped>

.headerNomStore
{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 70px;
  margin: 20px 0;
  padding: 10px;
  background: rgba(240, 101, 8, 0.329);
}
.contentStore{
  position: relative;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;

}
.adresseStore {
  position: relative;
  display: flex;
}
.ratingStore {
  position: relative;
  display: flex;
}
.imageStore
{
  position: relative;
  display: flex;
  justify-content: center;
  height: 300px;
}
.imageStore img
{
  position: relative;
}
hr
{
  display: flex;
  justify-content: center;
  max-width: 75%;
  color: rgb(240, 100, 8);
}
.nDeStore
{
  text-align: start;
  max-width: 250px;
}
.PrixStore
{
  position: relative;
  display: flex;
  justify-items: center ;
  margin-top: 15px;
}
.PrixStore .PrixStore
{
  position: relative;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}
.PrixStore .PrixStore h5
{
  margin: 0 0 0;
  font-size: 1.8rem;

}
.wilayaStore span
{
  color: rgb(240, 100, 8);
}
.RatingButtonStore
{
  position:relative;
  display: flex;
  flex-wrap: nowrap;
}
.RatingButtonStore .buttonPageStore
{
  position:absolute ;
  right: 0;
  border: 3px solid rgb(240, 100, 8);
  font-size: 1.2rem;
  font-weight: 700;
  border-radius: 20px;
  border-radius: 20px;
  background: rgb(240, 100, 8);
}
.RatingButtonStore .buttonPageStore:focus
{
  box-shadow: none;
}

.tabsStore
{
  min-height: 400px;
  padding: 0 0 30px 0px;
  background: #fff;
}
</style>
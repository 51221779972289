<template>
  <b-container fluid no-gutters id="pieceRecherche" class="pieceRecherche">
    <b-row>
      <b-col class="RechercheAvc" cols="12" sm="4" md="4" lg="3" xl="3" >
        <h4>Recherche Avancée</h4>
        <my-searchbarPiece></my-searchbarPiece>
      </b-col>
      <b-col cols="12" sm="8" md="8" lg="9" xl="9" class="cardRecherchePieceAll">
        <my-CardPieceInformation  v-for="product in products" :key="product.id" v-bind:product="product"></my-CardPieceInformation>
        <b-col cols="12">
          <my-pagination></my-pagination>
        </b-col>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Pagination from "../components/Pagination.vue";
import SearchBarPiece from "../components/SearchBarPiece";
import CardPieceInformation from "../components/CardPieceInformation.vue";
import { bus } from '../main'
export default {
  props: {
  },
  name: "PagePieceRecherche",
  data() {
    return {
      selectwilaya: "",
      communeList: "",
      valueDateInput: "",
      products:[],
    };
  },
  components: {
    "my-pagination": Pagination,
    "my-searchbarPiece": SearchBarPiece,
    "my-CardPieceInformation": CardPieceInformation,
  },
  created(){
    bus.$on('searchData', (data) => {
      this.products = data;
    });
  }
};
</script>

<style scoped>
.cardRecherchePieceAll {
  display: flex;
  flex-wrap: wrap;
}
.RechercheAvc
{
  background: #fff;
}
.RechercheAvc h4 
{
  margin: 30px 0 0 0;
  color: rgb(240, 100, 8);
}
</style>

<template>
		<b-container fluid class="card">
            <b-container class="imgBx">
				<img :src="`https://www.api.mikaniky.com/`">
			</b-container>
				<b-row class="CardPieceRecherche"> 
					<b-col fluid no-guttars class="content_left">
						<h2 class="nomPiece" id="nomPiece">{{ product.product.product_name }}</h2>
						<b-col class="numbreRefPiece mt-3">
							<h6 class="nDePiece" id="nDePiece">N° de piece: {{ n_De_Piece }} -</h6> 
							<h6 class="nReference" id="nReference">- Référence: #{{ n_Reference }}</h6>
						</b-col>
						<hr class="divider divider-xs bg-bermuda offset-top-15">
						<h6 class="nomStore" id="nomStore">Nom du Magasin: {{ product.store.name }}</h6>
						<h6 class="wilayaPiece" id="wilayaPiece">Wilaya: {{ wilaya_Piece }}</h6>
	
						<div class="mt-3 mb-3">
							<StarRating :glyph="Stars" :spacing="1" active-color="#f6c23d" :increment="1" :fixed-points="0" :item-size="20" :rating="rating" :read-only="true"></StarRating>
						</div>
						
					</b-col>
					<b-col class="content_Right">
						<h5>Prix:<span class="PrixPiece" id="PrixPiece"> {{ product.product_price }} </span>da</h5>	

						<b-button :to="`/${$i18n.locale}/pagepiece/${product.product.id}/${product.store.id}`" >Voir Profile</b-button>
					</b-col>
				</b-row>
		</b-container>	
</template>

<script>
import {StarRating} from 'vue-rate-it';
import Star_Rating from 'vue-rate-it/glyphs/star';
export default {
  props: {
	product:Object,
  },
	name:'CardPieceInformation',
	components:{
        StarRating,
	},
	created(){
    // register the icon
        this.Stars = Star_Rating;
    },
	data(){
		return {
			Stars: '',
			n_Reference:'12552',
			n_De_Piece:'521d21',
			wilaya_Piece: 'constantine',
			nom_Store: 'AutoBenz',
			rating:4,
			imagelink:''
		}
	},    
}
</script>

<style scoped>
.card
{
	position: relative;
	width: calc(90% - 20px);;
	min-height: 300px;
	background: #fff;
	margin: 10px;
    border-image: linear-gradient(to right,#ff0f0f 20%,#e26749 100%);
    border-image-slice: 1;
    border-top: 3px solid;
    border-right-width: 0;
    border-left-width: 0;
    border-bottom-width: 0;
	padding: 0;
	box-sizing: border-box;
}
.card:hover {
    box-shadow: 0 1px 10px 5px rgba(0,0,0,.1);
}
.card .imgBx
{
	position: absolute;
	width: 30%;
	height: 100%;
}
.card .imgBx img
{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover; 
}
.card .CardPieceRecherche 
{
	position: absolute;
	right: 0;
	width: 70%;
	height: 100%;
	box-sizing: border-box;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
}
.card .CardPieceRecherche .content_left
{
	position: relative;
	padding: 10px;
	min-width: 70%;
	height: 100%;
	box-sizing: border-box;
}
.card .CardPieceRecherche .content_Right
{
	position: relative;
	padding: 10px;
	min-width: 30%;
	height: 100%;
	box-sizing: border-box;

}
.card .CardPieceRecherche .content_left h2
{
	margin: 0;
	padding: 0;
	font-size: 24px;
	line-height: 0.85em;
	font-weight: 600;
	text-align: center;
}
.card .CardPieceRecherche .content_left h5
{
	margin: 0;
	padding: 0;
}
.card .CardPieceRecherche .content_left hr{
    width: 70px;
    height: 4px;
    margin: 5px auto;
    background-image: linear-gradient(to right,#ff0f0f 20%,#e26749 80%);
    background-repeat: repeat-x;
    border: none;
}
.card .CardPieceRecherche .content_left .numbreRefPiece
{
	position: relative;
	display: flex;
	flex-wrap: nowrap;
	justify-content: center;
}
.card .CardPieceRecherche .content_Right a
{
	display: inline-block;
	text-decoration: none;
	padding: 5px 10px;
	margin-top: 15px;
	border: 2px solid #ff0f0f;
	color: #262626;
	background-color: #fff;
	font-weight: 600;
}
.card .CardPieceRecherche .content_Right a:hover
{
	color: #fff;
    background-color: #ff0f0f;
    border-color: #ff0f0f;
}
.card .CardPieceRecherche .content_Right .content .rating
{
	position: relative;
	transform: rotateY(180deg);
	display: flex;
	justify-content: center;
	padding-top: 5px;
}
.card .CardPieceRecherche .content .rating input
{
	display: none;   /*eliminer la form radio*/ 
}
.card .CardPieceRecherche .content .rating label
{
	display: block;
	cursor: pointer;
	width: 25px;
}
.card .CardPieceRecherche .content .rating label:before
{
	content: '\f005';
	font-family: fontAwesome;
	position: relative;
	display: block;
	font-size: 25px;    /*la taille des star*/
	color: rgba(132, 132, 132, .9686274509803922);
}
.card .CardPieceRecherche .content .rating label:after
{
	content: '\f005';
	font-family: fontAwesome;
	position: absolute;
	display: block;
	padding-top: 5px;
	font-size: 25px;    /*la taille des star*/
	color: #FFE228;
	top: 0;             /*élevé les star vers le haut*/ 
	opacity: 0;
	transition: .5s;
	text-shadow: 0 2px 5px rgba(0,0,0,.5);   /*faire d'obre en bas*/
}
.card .CardPieceRecherche .content .rating label:hover:after,
.card .CardPieceRecherche .content .rating label:hover ~ label:after,
.card .CardPieceRecherche .content .rating input:checked ~ label:after
{
	opacity: 1;
}

@media (max-width: 991px)
{
	.card
	{
		width: calc(70% - 20px);
		margin: 10px auto;
	}
}
@media (max-width: 768px)
{
	.card
	{
		width: calc(70% - 20px);
		margin: 10px auto;
	}
	.card .imgBx
	{
		position: relative;
		width: 100%;
		height: 250px;
	}
	.card .CardPieceRecherche
	{
		position: relative;
		width: 100%;
		height: 250px;
	}
}
@media (max-width: 480px)
{
	.card
	{
		width: calc(100% - 10px);
		margin: 10px auto;
		padding: 20px 0;
	}
}
</style>
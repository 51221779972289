<template>

    <b-container fluid no-gutters class="CategoriesPiece">       

        <CardCategorie v-bind:cards="cards"/>

    </b-container>

</template>

<script>

import CardCategorie from "./CardCategorie.vue";
import axios from 'axios'

export default {
    name:'CategoriesService',
    components: {
        CardCategorie,
    },
    data() {
        return {
            cards: [
                {
                    name: 'Mécanicien',
                    img: require('../assets/mecanicien.jpg'),
                }, 
                {
                    name: 'Electricien',
                    img: require('../assets/Electricien.jpg'),
                }, 
                {
                    name: 'Dépanneur',
                    img: require('../assets/Depanneur.jpg'),
                },  
                {
                    name: 'Vulcanisateur',
                    img: require('../assets/vulcanisateur.jpg'),
                },
                {
                    name: 'Tôlier',
                    img: require('../assets/tolier.jpg'),
                },
            ],
        }
    },
    async created(){
        try{
            let data = await axios.get(``);
            for(let d of data.data){
                window.console.log(d);
            }

        }catch(err){
            window.console.error(err);
        }
    }
}
</script>

<style scoped>
.CategoriesPiece
{
    position: relative;
    margin: 60px 0;
}
</style>
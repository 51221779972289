<template>
    <div class="row h-100"> 
            <b-container fluid no-gutters id="userprofile" class="userprofile h-100">
                <b-row class="h-100 ml-2" align-h="start" cols="2"> 
                    <b-col cols="12" sm="12" md="4" lg="3" class="sideBarProfile h-100">
                          <SideBareUserProfile />
                          <!-- v-bind:cards="cards" -->
                    </b-col>
                    <b-col cols="12" sm="12" md="8" lg="9" class="pageUser">
                        <router-view :key="$route.path"></router-view>
                    </b-col>
                </b-row>
            </b-container>
    </div>
</template>

<script>
import SideBareUserProfile from '../components/SideBareUserProfile.vue';

export default {
    name:'UserProfile',
    components:{
        SideBareUserProfile,
    },
    data(){
        return{
            // cards: [
            //     {
            //         UserName: 'issam airo',
            //         img: require('../assets/courroie.jpg'),
            //     },  
            // ],
            
        }
    }
}
</script>

<style scoped>
.pageUser
{
    padding: 2px 0;
}

</style>

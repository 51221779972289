import Vue from "vue"; 
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import About from "../views/About.vue";
import PagePieceRecherche from "../views/PagePieceRecherche.vue";
import PagePiece from "../views/PagePiece.vue";
import Login from "../views/Login.vue";
import SignUp from "../views/SignUp.vue";
import Store from "../views/Store.vue";
import UserProfile from "../views/UserProfile.vue";
import Garage from "../views/Garage.vue";
import UserProfileMember from "../views/UserProfileMember.vue";
import UserProfilePublication from "../views/UserProfilePublication.vue";
//import UserProfileNewPub from "../views/UserProfileNewPub.vue";
import UserProfileInformationStore from "../views/UserProfileInformationStore.vue";
import UserProfileSetting from "../views/UserProfileSetting.vue";
import MecCat from "../views/MecCat.vue";
import UserContacts from "../views/UserContacts.vue";
import UserInformations from "../views/UserInformations.vue";
import AddStoreInfo from "../views/AddStoreInfoSteps.vue";
import AddUserInfo from "../views/AddUserInfoSteps.vue";
import Activation from "../views/Activation.vue";
import SinginActivationMSG from "../views/SigninActivationMSG.vue"
import PageMaintenance from "../views/PageMaintenance.vue";
import pageNotFound from "../views/pageNotFound.vue";
import PageNotProduct from "../views/PageNotProduct.vue";
import productUpdate from "../components/productUpdate.vue";
import AddProduct from "../components/AddProduct.vue";
import productViewImages from "../components/productViewImages.vue";
import SousCategoriesPieces from "../views/SousCategoriesPieces.vue";
import SubCategorieLastLevel from "../views/SubCategorieLastLevel.vue" 
import i18n from "../i18n";

Vue.use(VueRouter);

const routes = [{
    path: "/",
    redirect: `/${i18n.locale}`,
  },
  {
    path: "/:lang",
    component: {
      render(h) {
        return h("router-view");
      },
    },
    children: [{
        path: "",
        name: "Home",
        component: Home,
      },
      {
        path:"activationMSG",
        name:"activationMSG",
        component:SinginActivationMSG,
        meta:{
          title: 'Activation Message'
        }
      },
      {
        path: "user_steps/:userID",
        name: "AddUserInfo",
        component: AddUserInfo,
        props:true,
        meta: {
          title: 'Ajouter Informations'
        }
      },
      //this is modified
      {
        path: "store_steps/:userID",
        name: "AddStoreInfo",
        component: AddStoreInfo,
        props:true,
        meta: {
          title: 'Magasin informations'
        }
      },
      // {
      //   path: "store_steps/:userID/:storeID",
      //   name: "AddStoreInfo",
      //   component: AddStoreInfo,
      //   props:true,
      //   meta: {
      //     title: 'Magasin informations'
      //   }
      // },
      {
        path: "activation/:token",
        name: "Activate",
        component: Activation,
        props: true,
        meta: {
          title: 'Activation'
        }
      },
      {
        path: "about",
        name: "About",
        component: About,
        meta: {
          title: 'About'
        }
      },
      {
        path: "pagepiecerecherche",
        name: "PagePieceRecherche",
        component: PagePieceRecherche,
        meta: {
          title: 'Recherche'
        }
      },
      {
        path: "pagepiece/:productID/:storeID",
        name: "PagePiece",
        component: PagePiece,
        props:true,
        meta: {
          title: 'Piece'
        }
      },
      {
        path: "pagepiece/:carBrand/:carModel/:carYear/:carEngine",
        name: "PagePiece",
        component: PagePiece,
        props:true,
        meta: {
          title: 'Piece'
        }
      },
      {
        path: "login",
        name: "Login",
        component: Login,
        meta: {
          title: 'Login'
        }
      },
      {
        path: "SignUp",
        name: "SignUp",
        component: SignUp,
        meta: {
          title: 'SignUp'
        }
      },
      {
        path: "store/:storeID",
        name: "Store",
        component: Store,
        props:true,
        meta: {
          title: 'Magasin'
        }
      },
      {
        path: "mecat/:name",
        name: "MecCat",
        component: MecCat,
        props:true,
      },
      {
        path: "pageMaintenance",
        name: "pageMaintenance",
        component: PageMaintenance,
        props:true,
      },
      {
        path: "pageNotFound",
        name: "pageNotFound",
        component: pageNotFound,
        props:true,
      },
      {
        path: "pageNotProduct",
        name: "pageNotProduct",
        component: PageNotProduct,
        props:true,
      },
      {
        path: "SousCategoriesPieces/:type_name",
        name: "SousCategoriesPieces",
        component: SousCategoriesPieces,
        props:true,
      },
      {
        path:"SubCategorieLastLevel/:typeName",
        name:"SubCategorieLastLevel",
        component:SubCategorieLastLevel,
        props:true
      },
      {
        path: 'userprofile',
        component: UserProfile,
        children: [{
            path: '',
            name:"UserProfileMember",
            component: UserProfileMember,
            meta: {
              title: 'Profile',
            }
          },
          {
            path: 'infos',
            name:"UserInformations",
            component: UserInformations,
            meta: {
              title: 'Informations',
            }
          },
          {
            path: 'contacts',
            name:"UserContacts",
            component: UserContacts,
            meta: {
              title: 'Contacts',
            }
          },
          {
            path: 'publication',
            name:"UserProfilePublication",
            component: UserProfilePublication,
            meta: {
              title: 'Les Produits',
            }
          },
          {
            path: 'informationStore',
            name:"UserProfileInformationStore",
            component: UserProfileInformationStore,
            meta: {
              title: 'Magasin informations'
            }
          },
          {
            path: 'Setting',
            name:"UserProfileSetting",
            component: UserProfileSetting,
            meta: {
              title: 'Paramètres '
            }
          },
          {
            path: "garage",
            name: "Garage",
            component: Garage,  
            meta: {
              title: 'Garage'
            }
          },
          {
            path: "updateProduct",
            name: "UpdateProduct",
            component: productUpdate,  
            meta: {
              title: 'Update Product'
            }
          },
          {
            path: "addProduct",
            name: "AddProduct",
            component: AddProduct,  
            meta: {
              title: 'Add Product'
            }
          },
          {
            path: "productImages",
            name: "productViewImages",
            component: productViewImages,
            meta:{
              title: "Product images"
            }
          }
        ],
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((toRoute, fromRoute, next) => {
  window.document.title = toRoute.meta && toRoute.meta.title ? toRoute.meta.title : 'Mikaniky';

  next();
})

export default router;
<template>

    <b-container fluid no-gutters class="bestagent">       

        <CardTopAgentService v-bind:cards="cards"/>

    </b-container>

</template>

<script>

import CardTopAgentService from "../components/CardTopAgentService.vue";

export default {
    name:'BestAgent',
    components: {
        CardTopAgentService,
    },
    data() {
        return {
            cards: [
                {
                    store: 'Mohammed',
                    name: 'Mecanicien',
                    adresse: 'Batna',
                    img: require('../assets/mecanicien.jpg'),
                    specialite: 'Peugeot,Reneaut,citroen'
                }, 
                {
                    store: 'Khaled',
                    name: 'Mecanicien',
                    adresse: 'Alger',
                    img: require('../assets/mecanicien.jpg'),
                    specialite: 'Volkswagen'
                }, 
                {
                    store: 'Amine',
                    name: 'Mecanicien',
                    dresse: 'Relizen',
                    img: require('../assets/mecanicien.jpg'),
                    specialite: 'ford,Nissan'
                }, 
                {
                    store: 'Issam',
                    name: 'Mecanicien',
                    dresse: 'Alger',
                    img: require('../assets/mecanicien.jpg'),
                    specialite: 'Nissan'
                },  
                {
                    store: 'Oussama',
                    name: 'Mecanicien',
                    adresse: 'Constantine',
                    img: require('../assets/mecanicien.jpg'),
                    specialite: 'ford'
                }, 
                {
                    store: 'Baby',
                    name: 'Mecanicien',
                    resse: 'Ain Mlila, Oum el bouaghi',
                    img: require('../assets/mecanicien.jpg'),
                    specialite: 'Peugeot,Reneaut'
                }, 
                {
                    store: 'Ramzi',
                    name: 'Mecanicien',
                    dresse: 'Batna',
                    img: require('../assets/mecanicien.jpg'),
                    specialite: 'Marque Chinoise'
                }, 
                {
                    store: 'AutoDZ',
                    name: 'Mecanicien',
                    adresse: 'Batna',
                    img: require('../assets/mecanicien.jpg'),
                    specialite: 'tout Marque'
                },  
                {
                    store: 'Maher',
                    name: 'Mecanicien',
                    dresse: 'Batna',
                    img: require('../assets/mecanicien.jpg'),
                    specialite: 'Marque Allemende'
                }, 
                {
                    store: 'Khaled',
                    name: 'Mecanicien',
                    adresse: 'Batna',
                    img: require('../assets/mecanicien.jpg'),
                    specialite: 'Cheverolet'
                }, 
            ],
        }
    }
}
</script>

<style scoped>
.bestagent
{
    position: relative;
    margin: 60px 0;
}
</style>
<template>
  <b-container fluid class="ativationCompte" no-guttars>
    <b-row class="zoneActivation">
      <div class="titreActivation">
        <h2> Il vous reste juste une étape pour activer votre compte Mikaniky</h2>
      </div>
      <div class="corpActivation">
        <b-btn class="btnActivation" @click.prevent="activate">Merci de Cliquer ici pour Activer votre compte</b-btn>
      </div>
    </b-row>
  </b-container>
</template>

<script>
import axios from 'axios';
import store from '../Vuex';
export default {
  props: {
    token:String,
    userID:String,
  },
    name: 'Activation',
    components: {
      
  },
  data () {
    return {
      data:null,
      msg:'your account is activated please proceed with your inscription!!'
    }
  },
  methods: {
  async activate(){
    try{
      let res = await axios.post(`auth/activation/${this.token}`);
      window.console.log(res);
      store.dispatch('user',res.data);
      // this.data = res.data;
      window.console.log(res.data);
      this.userID = res.data.userID;
      window.console.log(this.userID);
      // window.location.href = `http://localhost:8080/${this.$i18n.locale}/store_steps/${this.userID}`;
      if( res.data.roles[0] == 'ROLE_MANAGER'){
      //   this.$router.push({
      //   name:'AddStoreInfo',
      //   params:{userID: this.userID}
      // });
        window.location.href = `https://www.mikaniky.com/${this.$i18n.locale}/store_steps/${this.userID}`;
      }else if( res.data.roles[0] == 'ROLE_USER'){
        // this.$router.push({
        //   name:'AddUserInfo',
        //   params:{userID: this.userID}
        // });
        window.location.href = `https://www.mikaniky.com/${this.$i18n.locale}/user_steps/${this.userID}`;
      }

    }catch(err){
      window.console.log(err);
    }
  }
  },
}
</script>
<style scoped>
.ativationCompte
{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100px 0 200px 0 ;
}
.ativationCompte .zoneActivation
{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.titreActivation
{
  text-align: center;
}
.corpActivation
{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px 0 0 0;

}
.btnActivation 
{
  color: #fff;
  border: 3px solid rgb(0, 0, 0);
  border-radius: 10px;
  background: #F87127;
  font-size: 1.2rem;
  font-weight: 500;
}
.btnActivation:hover,
.btnActivation:active
{
  background: #fff;
  border-color: #F87127;
  color: #F87127;
  box-shadow: 0px 0px 0px 4px #f8702769;
}
</style>
<template>
    <b-container class="cardTopVentePiece">
        <div class="row">
            <div  v-for="card in cards" :key="card.name" class="cardProduit  my-1">
                <div class="card h-100">
                    <div class="card-img-top">
                        <img :src="card.img">
                        <div>
                            <span class="badge badge-pill badge-primary">{{ card.price }} DA</span>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="card-title" >{{ card.name }}</div>
                        <div>{{ card.store }}</div>
                        <div>{{ card.adresse}}</div>
                        
                        <div class="buttonViewTopPiece">
                            <button  class="buttonView" :to="`/${$i18n.locale}/PagePiece`">Afficher</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </b-container>
</template>

<script>

export default {
    name:'CardTopVentePiece',
    props: {
        cards: {
        }
    },
    components: {
    },
    data() {
        return {
            
    }
    }
}
</script>

<style scoped>

    .cardTopVentePiece .cardProduit
    {
        position: relative;
        display: flex;
        justify-content: center;
        max-width: 250px;

       
    }
    .cardTopVentePiece .card-body
    {
        position: relative;
        display: block;
        width: 180px;
        margin: 0;
        cursor: pointer ;
    }
    .cardTopVentePiece .card-img-top
    {
        padding-left: 0;
        padding-right: 0;
        display: inline-block;
        padding: 0 0;  
    }    
    .cardTopVentePiece .card-img-top img
    {
        position: relative;
        min-width: 100%;
        height: 100px;
    }
    .card-title:hover
    {
        cursor: pointer ;
        -webkit-text-decoration-line: underline; /* Safari */
        text-decoration-line: underline; 
    }
    .cardTopVentePiece .card:hover
    {
        box-shadow: 0 1px 10px 5px rgba(0,0,0,.1);
    }
    .cardTopVentePiece .badge 
    {
        position: absolute ;
        Top: 5px;
        right: 10px;
        font-size: 1rem;
        color: #ff0a0a;
        background: rgb(214, 214, 214);
    }
    .cardTopVentePiece .card-body .buttonViewTopPiece
    {
        position: relative;
        display: flex;
        justify-content: flex-end;
    }   
    .cardTopVentePiece .card-body .buttonViewTopPiece .buttonView
    {
        border-width: 1px;
        font-size: 900;
        border-radius: 5px;
        color: #fff;
        font-weight: 700;
        background: rgb(240, 100, 8);
    }
    .cardTopVentePiece .card-body .buttonViewTopPiece .buttonView:focus,
    .cardTopVentePiece .card-body .buttonViewTopPiece .buttonView:active
    {
        color: rgb(240, 100, 8);
        background: #fff;
        border: 1px solid rgb(240, 100, 8);
        box-shadow: 0 0 0 0.1rem rgba(240, 101, 8, 0.287);

    }
</style>
<template>

    <b-container fluid no-gutters class="bestProducts">       

        <CardTopVentePiece v-bind:cards="cards"/>

    </b-container>

</template>

<script>

import CardTopVentePiece from "../components/CardTopVentePiece.vue";

export default {
    name:'BestProducts',
    components: {
        CardTopVentePiece,
    },
    data() {
        return {
            cards: [
                {
                    name: 'Courroie',
                    store: 'AutoDZ',
                    adresse: 'Batna',
                    img: require('../assets/courroie.jpg'),
                    price: 2500
                }, 
                {
                    name: 'Phares',
                    store: 'AutoDZ',
                    adresse: 'Alger',
                    img: require('../assets/les_phares.jpg'),
                }, 
                {
                    name: 'Amortisseur',
                    store: 'AutoDZ',
                    adresse: 'Relizen',
                    img: require('../assets/Amortisseur.jpg'),
                }, 
                {
                    name: 'Moteur',
                    store: 'AutoDZ',
                    adresse: 'Alger',
                    img: require('../assets/moteur.jpg'),
                },  
                {
                    name: 'Courroie',
                    store: 'AutoDZ',
                    adresse: 'Constantine',
                    img: require('../assets/courroie.jpg'),
                }, 
                {
                    name: 'Phares',
                    store: 'AutoDZ',
                    adresse: 'Ain Mlila, Oum el bouaghi',
                    img: require('../assets/les_phares.jpg'),
                }, 
                {
                    name: 'Amortisseur',
                    store: 'AutoDZ',
                    adresse: 'Batna',
                    img: require('../assets/Amortisseur.jpg'),
                }, 
                {
                    name: 'Moteur',
                    store: 'AutoDZ',
                    adresse: 'Batna',
                    img: require('../assets/moteur.jpg'),
                },  
                {
                    name: 'Amortisseur',
                    store: 'AutoDZ',
                    adresse: 'Batna',
                    img: require('../assets/Amortisseur.jpg'),
                }, 
                {
                    name: 'Moteur',
                    store: 'AutoDZ',
                    adresse: 'Batna',
                    img: require('../assets/moteur.jpg'),
                    price: 2500
                }, 
            ],
        }
    }
}
</script>

<style scoped>
.bestProducts
{
    position: relative;
    margin: 60px 0;
}
</style>
<template>
    <b-container class="userInformations">
        <h2> Profil utilisateur</h2>
        <b-form>
            <b-input-group id="input-group-2" label-for="input-4" class="input-field mb-5">
                <b-form-input id="input-2"  required v-model="lastName" ></b-form-input>
                <label for="Nom">Nom</label>
            </b-input-group>

            <b-input-group id="input-group-2" label-for="input-3" class="input-field  mb-5">
                <b-form-input id="input-2"  required v-model="firstName" ></b-form-input>
                <label for="prenom">Prénom</label>
            </b-input-group>

            <b-input-group id="input-group-3" prepend="Date de naissance" label-for="input-6" class="input-field  mb-5">
                <b-form-input id="datepicker-buttons" type="date"  v-model="birthday" :placeholder="birthday" ></b-form-input>
            </b-input-group>

            <b-input-group id="input-group-3" v-b-tooltip.hover title="Le sexe" label-for="input-6" class="input-field mb-5">
                <b-form-input id="input-1" disabled="true" required v-model="sex" ></b-form-input>
            </b-input-group>

            <b-input-group v-if="false" id="input-group-4" prepend="Langue :" label-for="input-6" class="mb-3">
                <b-form-select v-model="selectedL" :options="optionsL"></b-form-select>
            </b-input-group>

            <b-button class="BtnEnreg">Enregistrer</b-button>
        </b-form>
    </b-container>
</template>

<script>
// import WilayaCommune from '../components/WilayaCommune.vue';
import axios from 'axios';
import {mapGetters} from 'vuex';
export default {

  props: {
      userID:String,
  },
        name:'UserInformations',
        data() {
            return {
                selectedL: 'Fr',
                optionsL: [
                    { value: null, text: 'Selectionez votre langue' },
                    { value: 'Ar', text: 'Arabe' },
                    { value: 'Fr', text: 'Français' },
                    { value: 'An', text: 'Anglais'}
                ],
                data:null,
                lastName:'',
                firstName:'',
                sex:'',
                birthday:'',
            }
            },
        components:{
            
        },
        methods: {
            async updateinfos(){
                try{
                    
                    let res = await axios.put(`info/update_info/${this.user.id}/${this.firstName}/${this.lastName}/${this.birthday}/${this.selectedL}`);
                    window.console.log(res);
                }catch(err){
                    window.console.error(err);
                }
            }
        },
        async mounted(){
            try{
                window.console.log(this.user.id);
                let res = await axios.get(`info/get_info/${this.user.id}`);
                
                this.data = res.data;
                window.console.log(res);
                this.birthday = this.data.user_info.birthday;
                this.lastName = this.data.user_info.last_name;
                this.firstName = this.data.user_info.first_name;
                this.sex = this.data.user_info.sex;
            }catch(e){
                window.console.error(e);
            }
        },
        computed:{
            ...mapGetters(['user']),
        },
}
</script>
<style scoped>
.userInformations
{
    height: 100%;
    background: #fff;
    padding: 40px 40px;
}
form{
    margin: 50px 0;
}
.input-field {
  position: relative;
  width: 400px;
  height: 50px;
  line-height: 35px;
}
input{
  max-width: 100%;
  border: 0;
  outline: 0;
  margin: 0 0 50px 0;
  padding: 0.5rem;
  border: 2px solid #d3d3d3;
  font-size: 1.2em;
  box-shadow: none;
  color: #111;
}
label {
  position: absolute;
  top: 0;
  left: 10px;
  padding: 0 10px;
  margin: 0 0 0 0;
  color: #d3d3d3;
  transition: 0.2s all;
  cursor: text;
}
input:invalid
{
  outline: 0;
}
input:focus,
input:valid {
  border-color: rgb(240, 100, 8);
}
input:focus~label,
input:valid~label{
  z-index: 1000;
  font-size: 1.4em;
  top: -23px;
  color: rgb(240, 100, 8);
  background: #ffff;
}

.BtnEnreg
{
    background: #F87127;
    color: #Fff;
    margin-left: 150px;
}
</style>
<template>
  <div id="app">
    <div class="header" id="header">
      <my-header />
    </div>
    <div class="BodyPage" >
        <router-view :key="$route.path"></router-view>
    </div>
    
    <div class="footer" id="footer">
      <my-footer />
    </div>
  </div>
</template>

<script>

import Footer from './components/footer.vue';
import Header from './components/header.vue';

export default {
  name: 'App',
  components: {
    'my-footer':Footer,
    'my-header':Header,
    }	
}
</script>

<style scoped>
.header
{
  width: 100%;
}
.BodyPage
{
  background: #f5f5f5;
}

</style>

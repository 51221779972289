<template>
  <div>
    <horizontal-stepper :steps="demoSteps" @completed-step="completeStep"
    @active-step="isStepActive" @stepper-finished="finish"
    >                     
  </horizontal-stepper>
  </div>
</template>

<script>
import HorizontalStepper from 'vue-stepper';
import StepOne from '../components/StoreStepOne.vue'
import StepTow from '../components/StoreStepTow.vue'
import StepThree from '../components/StoreStepThree.vue'
import StepFor from '../components/StoreStepFor.vue'
export default {
  name: 'AddStoreInfo',
  components: {
    HorizontalStepper
  },
    data() {
    return {
      demoSteps: [
        {
          icon: "info",
          name: "Informations",
          title: "Store Informations",
          subtitle: "Informations",
          component: StepOne,
          completed: false
        },
        {
          icon: "explore",
          name: "Address",
          title: "Store Address",
          subtitle: "Address",
          component: StepTow,
          completed: false
        },
        {
          icon: "phone",
          name: "Phone",
          title: "Store Phones",
          subtitle: "Phones",
          component: StepThree,
          completed: false
        },
        {
          icon: "image",
          name: "Image",
          title: "Store Images",
          subtitle: "Images",
          component: StepFor,
          completed: true
        },
      ],
    }
  },
  methods: {
    completeStep(payload) {
      this.demoSteps.forEach(step => {
        if (step.name === payload.name) {
          step.completed = true;
        }
      });
    },
    isStepActive(payload) {
      this.demoSteps.forEach(step => {
        if (step.name === payload.name) {
          if (step.completed === true) {
            step.completed = false;
          }
        }
      });
    },
    finish() {
      // alert("end");
      this.$router.push({
        name:'Home',
      });
    }
  }
};
</script>
<template>
        <b-container fluid class="sousCategoriesPieces" id="sousCategoriesPieces">
            <h2 class="nomCategories" id="nomCategories">{{ type_name }}</h2>
            <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                <SousCategoriesPieces2 v-bind:cards="cards" />
            </b-col>
        </b-container>
</template>

<script>

import SousCategoriesPieces2 from "../components/SousCategoriesPieces.vue";
export default {
  name: "SousCategoriesPieces",
  props:{
    type_name:String,
  },
  components: {
    SousCategoriesPieces2,
  },
  data() {
    return {

    };
  },
};
</script>

<style scoped>
.nomCategories
{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 70px;
  margin: 20px 0;
  padding: 10px;
  background: rgba(240, 101, 8, 0.329);
}

</style>

<template>
	<b-container fluid no-gutters class="Footer">	
        
        <b-container fluid no-gutters class="Footer_Information  text-center text-md-left">
            <b-row class="mt-3">
                <b-col md="3" lg="4" xl="3" class="mb-4 dark-grey-text">
                    <h5 class="text-uppercase font-weight-bold"><strong>Mikaniky</strong></h5>
                    <hr class="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" style="width: 60px" />
                    <p>Une plateforme numérique qui organise et digitalise le marché Algérien dans le domaine DES PIECES DE RECHANGE</p>
                </b-col>
                <b-col md="2" lg="2" xl="3" class="categorieFooter mb-4 dark-grey-text">
                    <h5 class="text-uppercase font-weight-bold"><strong>Catégorie</strong></h5>
                    <hr class="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" style="width: 60px" />
                    <h6><b-link :to="`/`" class="dark-grey-text">Embrayage</b-link></h6>
                    <h6><b-link :to="`/`" class="dark-grey-text">Extérieurs</b-link></h6>
                    <h6><b-link :to="``" class="dark-grey-text">Suspension</b-link></h6> 
                    <h6><b-link :to="`/`" class="dark-grey-text">Moteur</b-link></h6> 
                    <h6><b-link :to="`/`" class="dark-grey-text">Acéssoires</b-link></h6> 
                </b-col>
                <b-col md="3" lg="2" xl="2" class="serviceFooter mb-4 dark-grey-text">
                    <h5 class="text-uppercase font-weight-bold"><strong>Service</strong></h5>
                    <hr class="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" style="width: 60px" />
                    <h6><b-link :to="`/`" class="dark-grey-text">Store Mikaniky</b-link></h6>
                    <h6><b-link :to="`/`" class="dark-grey-text">Publicité </b-link></h6>
                    <h6><b-link :to="`/`" class="dark-grey-text">Conditions d'utilisation</b-link></h6>
                </b-col>
                <b-col md="4" lg="3" xl="4" class="contact mb-4 dark-grey-text">
                    <h5 class="text-uppercase font-weight-bold"><strong>Contact</strong></h5>
                    <hr class="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" style="width: 60px" />
                    <p><span><font-awesome-icon class="mr-3" :icon="['fa', 'home']"/></span> 16 Avenue de l'indépendance, Batna, ALGERIE</p>
                    <p><span><font-awesome-icon class="mr-3" :icon="['fa', 'envelope']"/></span> commercial@mikaniky.com</p>
                    <p><span><font-awesome-icon class="mr-3" :icon="['fa', 'phone']" :transform="{ rotate: 90 }"/></span> +213 (0)557 171 717</p>
                    <!-- <p><font-awesome-icon class="mr-3" :icon="['fa', 'print']"/> + 01 234 567 89</p> -->
                </b-col>
            </b-row>
        </b-container>

        
        <div>
            <b-container class="footer_ReseauSci text-left"> 
                <b-row class="py-4 d-flex align-items-center">
                    <b-col md="6" lg="5" class="TitreSci text-center text-md-left mb-4 mb-md-0">
                        <h6 >Contactez nous </h6>
                    </b-col>
                    <b-col md="6" lg="7" class="LogoSci text-center text-md-right">
                        <li><a class="ml-0" href="https://www.facebook.com/Mikaniky-111448154686829" target=_blank><font-awesome-icon :icon="['fab', 'facebook']"/><!--use fontawesom icon--> </a></li>
                        <li><a href="https://www.linkedin.com/" target=_blank><font-awesome-icon :icon="['fab', 'linkedin']"/><!--use fontawesom icon--> </a></li>
                        <li><a href="https://www.instagram.com/" target=_blank><font-awesome-icon :icon="['fab', 'instagram']"/><!--use fontawesom icon--> </a></li>
                    </b-col>
                </b-row>
            </b-container>
        </div>

        <div class="footer-copyright text-center py-3">
            <b-container fluid >
                &copy; 2021 Copyright: <a href="https://www.mikaniky.com"> Mikaniky </a>
            </b-container>
        </div>

	</b-container>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faFacebook, faTwitter , faGooglePlus , faLinkedin, faInstagram  } from '@fortawesome/free-brands-svg-icons';
import { faHome, faEnvelope, faPhone, faPrint} from '@fortawesome/free-solid-svg-icons';

 
library.add(faFacebook, faTwitter , faGooglePlus ,faLinkedin, faInstagram, faHome, faEnvelope, faPhone, faPrint);

export default {
    name: 'Footer',
    components: {
    'font-awesome-icon': FontAwesomeIcon,
    }
    
}
</script>

<style scoped>
    .Footer
    {
        position: relative;    
        padding: 0 0 0 0;    
        color:#fff
    }
    .Footer .Footer_Information
    {
        position: relative;
        background: #272727;
        max-width: 100%;
        padding: 40px 0 0 0;
    }
    a
    {
        color: rgb(240, 100, 8);
    }
    a:hover
    {
        color: rgb(255, 255, 255);
    }
    .categorieFooter
    {
        padding: 0 0 0 0;
    }
    .serviceFooter
    {
        padding: 0 0 0 0;
    }
    .Footer .Footer_Information .row
    {
        --bs-gutter-x: 0rem;
    }
    .Footer .Footer_Information .contact 
    {
        padding: 0 0 0 0;
    }
    .Footer .Footer_Information .contact p
    {
        margin: 0 0;
    }
    .Footer .Footer_Information .contact p span
    {
        color: rgb(240, 100, 8);
        image-orientation: 180deg;
    }
    .Footer .footer_ReseauSci
    {
        position: relative;
        max-height: auto;
        min-width: 100%;
        background-color: #3f3f3f;
        padding: 0 40px;
        margin: 0;
        z-index: 1;
        border-top: 2px solid rgb(212, 212, 212);
    }
    .Footer .footer_ReseauSci .TitreSci h6
    {
        font-size: 1.4em;
        color: rgb(236, 236, 236);
    }
    .Footer .footer_ReseauSci .LogoSci
    {
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;
        z-index: 10;
    }
    .Footer .footer_ReseauSci .LogoSci li
    {
        list-style: none;         /*pour anuuler les point avant les facebook ...*/
        margin: 0 20px;
        transform: scale(1);
        transition: transform 0.3s;
    }
    .Footer .footer_ReseauSci .LogoSci li:hover
    {
        transform: scale(1.2);
    }
    .Footer .footer_ReseauSci .LogoSci li a
    {
        color: rgb(240, 240, 240);
        font-size: 2em;
        transition: 0.4s;
        cursor: pointer;
    }
    .Footer .footer_ReseauSci .LogoSci li a:hover
    {
        color: rgb(236, 140, 76);
    }
    .Footer .footer-copyright
    {
        background: rgba(240, 101, 8, 0.89);
    }
    .Footer .footer-copyright a 
    {
        color: #fff;
        font-weight: 900;
        font-size: 1.2rem;
    }
</style>
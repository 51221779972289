<template>
    <div>
        <b-form class="searchBarPiece">
            <b-container fluid="" mr-2>
                <div>
                    <b-input-group id="input-group-2" label-for="input-4" class="input-field mb-3">
                        <b-form-select class="selectSearchBar" v-model="selectedWilaya" :options="wilaya"></b-form-select>
                        <label for="Wilaya"><h6>Wilaya</h6></label>
                    </b-input-group>
                </div>
                <div>
                    <b-input-group id="input-group-2" label-for="input-4" class="input-field mb-3">
                        <b-form-select class="selectSearchBar" v-model="selectedCategory" :options="type_children" @change="clientChangeWilaya"></b-form-select>
                        <label for="Categorie"><h6>Catégorie</h6></label>
                    </b-input-group>
                    <!-- <b-form-group label="Catégorie:">
                        <select class="selectCategorieSearch " id="selectCategorieSearch" @change="changeSelect" v-model="selectCategorie">
                            <option v-for="child in type_children" :key="child.id" :value="child.value">{{child.text}}</option>
                        </select>
                    </b-form-group> -->
                </div>
                <div>
                    <b-input-group id="input-group-2" label-for="input-4" class="input-field mb-3">
                        <b-form-select class="selectSearchBar" v-model="selected" :options="options" @change="changed"></b-form-select>
                        <label for="Marque"><h6>Marque</h6></label>
                    </b-input-group>
                </div>
                <div>
                    <b-input-group id="input-group-2" label-for="input-4" class="input-field mb-3">
                        <b-form-select class="selectSearchBar" v-model="selected2" :options="options2" @change="modelchanged"></b-form-select>
                        <label for="Modele"><h6>Modèle</h6></label>
                    </b-input-group>
                </div>
                <div>
                    <b-input-group id="input-group-2" label-for="input-4" class="input-field mb-3">
                        <b-form-select class="selectSearchBar" v-model="selected3" :options="options3" @change="clientChangeYear"></b-form-select>
                        <label for="Annee"><h6>Année</h6></label>
                    </b-input-group>
                </div>
                <div>
                    <b-input-group id="input-group-2" label-for="input-4" class="input-field mb-3">
                        <b-form-select class="selectSearchBar" v-model="selected4" :options="options4" @change="clientChangeMotorisation"></b-form-select>
                        <label for="Motorisation"><h6>Motorisation</h6></label>
                    </b-input-group>
                </div>
                <div>    
                    <b-form-group label="Popularité:">
                        <b-form-checkbox-group id="checkbox-group-1" v-model="selected6" stacked>
                            <b-form-checkbox value="A"><StarRating :glyph="Stars" :spacing="1" active-color="#fed267" :fixed-points="0" :item-size="20" :rating="5" :read-only="true"></StarRating></b-form-checkbox>
                            <b-form-checkbox value="B"><StarRating :glyph="Stars" :spacing="1" active-color="#fed267" :fixed-points="0" :item-size="20" :rating="4" :read-only="true"></StarRating></b-form-checkbox>
                            <b-form-checkbox value="C"><StarRating :glyph="Stars" :spacing="1" active-color="#fed267" :fixed-points="0" :item-size="20" :rating="3" :read-only="true"></StarRating></b-form-checkbox>
                            <b-form-checkbox value="D"><StarRating :glyph="Stars" :spacing="1" active-color="#fed267" :fixed-points="0" :item-size="20" :rating="2" :read-only="true"></StarRating></b-form-checkbox>
                            <b-form-checkbox value="E"><StarRating :glyph="Stars" :spacing="1" active-color="#fed267" :fixed-points="0" :item-size="20" :rating="1" :read-only="true"></StarRating></b-form-checkbox>
                        </b-form-checkbox-group>
                    </b-form-group>
                </div>
                <div v-if="true">
                    <b-form-group class="groupService" label="Services:">
                        <b-form-checkbox-group id="checkbox-group-1" v-model="selected5" stacked>
                            <b-form-checkbox value="Org"> <span> Piece d'origine </span> </b-form-checkbox>
                            <b-form-checkbox value="Cop"> <span> Pièce copie </span> </b-form-checkbox>
                            <b-form-checkbox value="Occ"> <span> Occasion </span> </b-form-checkbox>
                        </b-form-checkbox-group>
                    </b-form-group>
                </div>
            </b-container>
        </b-form>
    </div>
</template>

<script scoped>
import {StarRating} from 'vue-rate-it';
import Star_Rating from 'vue-rate-it/glyphs/star';
import axios from 'axios'
export default {
    name:'SearchBarPiece',
    data:()=>({
        datas:[],
        filtred_data:[],
        heart: '',
        type_children:[{value:null,text:'Toutes les catégories',description:'',id:''}],
            selected: null,
        options: [
          { value: null, text: 'La marque de voiture' },
        ],
        selected2: null,
        options2: [
          { value: null, text: 'le modèle' },
        ],
        selected3: null,
        options3: [
          { value: null, text: 'l\'année' },
        ],
        selected4: null,
        options4: [
          { value: null, text: 'Motorisation' },
        ],
        selectedCategory: null,
        selectedWilaya: null,
        data:[],
        models:[],
        selected5:[],
        selected6:[],
        wilaya:[
            { value: null, text: 'Wilaya' },
        ],
    }),
    components:{
        StarRating,
	},
    async created(){
        try{
            this.Stars = Star_Rating
            let data = await axios.get(`user/get_types`);
            let children = await axios.get(`user/get_types_children`);
            let wilayas = await axios.get(`user/get_wilaya`);
            const res = await axios.get(`user/model_brand_engine`);
            this.data = res.data
            
            window.console.log(this.wilaya);
            for(let wil of wilayas.data){
                this.wilaya.push({value:wil.wilaya,text:wil.wilaya});
            }

            for(let option of this.data){
                this.options.push({ value:option.brand, text:option.brand});
            }

            this.datas = data.data;
            for(let d of this.datas){
            this.filtred_data.push({typekey:d.id, type_name:d.product_type_name, type_description:d.product_type_description});
            }
            for(let child of children.data){
            this.type_children.push({value:child.product_type_name,text:child.product_type_name,description:child.description,id:child.id});
            }
            // this.profilepic = ':3000/'+ this.user.pic.replace( /\\/g, "/");
            // let token = localStorage.getItem('x-auth-token');
            // let user = await axios.get(`info/get_user/${}`,{headers: {"token" : `${token}`}});
            // this.$store.dispatch('user',user.data);
        }
        catch (e){
            window.console.error(e);
        }
    },
  methods: {
            changed(){
        let models =  this.data.filter(m =>m.brand == this.selected);
        this.options2 = [{ value: null, text: 'Séléctionner votre modèle' },];
        this.options3 = [{ value: null, text: 'l\'année de véhicule' },]
        this.models = models[0].car_models;
        for(let model of models[0].car_models){
          this.options2.push({ value:model.model, text:model.model});
          this.options3.push({ value:model.year, text:model.year});
        }
      },
      modelchanged(){
        
        let model = this.models.filter((m) => m.model == this.selected2);
        this.options4 = [{value: null, text: 'Motorisation de véhicule' }];
        for(let m of model[0].car_engines){
          this.options4.push({value: m.energy_type, text:m.energy_type});
        }
      },
  },
}
</script>

<style scoped>
/*
    .selectSearchBar
    {
      width: 120px;
      height: 36px;
      border:none;
      background: #fff;
      box-shadow: none;
    }
    .selectSearchBar:active,
    .selectSearchBar:focus
    {
      margin: 0 0;
      padding: 0 0;
      border-left: 1px solid rgb(251, 154, 90);
    }
*/
.searchBarPiece
{
    background: rgb(255, 255, 255);
    padding: 5px 5px;
}
form
{
    margin: 15px 0 50px 0;
}
.input-field 
{
  position: relative;
  width: auto;
  height: 40px;
  line-height: 35px;
    margin: 30px 0 0 0;
}
select
{
    width: 220px;
  border: 0;
  outline: 0;
  margin: 0 0 50px 0;
  padding: 0.5rem;
  border: 2px solid #d3d3d3;
  font-size: 1.2em;
  box-shadow: none;
  color: #111;
}
label 
{
  position: absolute;
  top: 0;
  left: 10px;
  padding: 0 10px;
  margin: 10px 0 0 0;
  color: #d3d3d3;
  background: rgba(255, 255, 255, 0);
  font-size: 1.2em;
  transition: 0.2s all;
  cursor:pointer;
}
select:invalid
{
  outline: 0;
}
select:focus,
select:valid 
{
  border-color: rgb(240, 100, 8);
}
select:focus~label,
select:valid~label
{
  z-index: 1000;
  font-size: 1.4em;
  top: -23px;
  color: rgb(240, 100, 8);
  background: #ffff;
}

.groupService
{
    margin: 20px 0;
}
.groupService span
{
    margin: 0 0 0 8px;
}

</style>
<template>
  <b-container>
    <b-card title="Photo d'utilisateur" sub-title="Photo de profil">
      <b-form-group id="input-group-1" label="Photo de profil:" label-for="input-1" description="" class="mb-4">
        <b-img src="https://picsum.photos/125/125/?image=58" rounded="circle" alt="Profile image"></b-img>
        <b-form-file  placeholder="Choisissez un fichier ou déposez-le ici..." drop-placeholder="" ></b-form-file>
      </b-form-group>
    </b-card>
  </b-container>
</template>

<script>
// import axios from 'axios';
import {mapGetters} from 'vuex';
export default {
  props: {
    userID:String,
  },
  data() {
    return {

    };
  },
  beforeMount(){
      this.$emit("can-continue", { value: true });
  },
  methods: {
  },
  computed:{
    ...mapGetters(['user']),
  },
};
</script>
<template>
    <b-container class="addProductPage">
        <b-button v-b-tooltip.hover variant="light" title="Retour" @click="$router.go(-1)"><b-icon scale="1.5" icon="arrow-left-circle" font-scale="1.5">Retour</b-icon></b-button>
        <b-alert :show="dismissCountDown" :variant="alertcolor" @dismissed="dismissCountDown=0" @dismiss-count-down="countDownChanged">
            {{errormsg}} {{ dismissCountDown }} seconds...
        </b-alert>
        <b-card title="" sub-title="" class="mb-3">
            <label class="btn btn-default">
                <input type="file" multiple @change="selectFile" />
            </label>
            <br/>
            <br/>
            <div v-if="progressInfos">
            <div class="mb-4"
                v-for="(progressInfo, index) in progressInfos"
                :key="index"
            >
                <span>{{progressInfo.fileName}}</span>
                <div class="progress">
                <div class="progress-bar progress-bar-info"
                    role="progressbar"
                    :aria-valuenow="progressInfo.percentage"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    :style="{ width: progressInfo.percentage + '%' }"
                >
                    {{progressInfo.percentage}}%
                </div>
                </div>
            </div>
            </div>
            <br/>
            <br/>
            <b-button class="btnTelecharger btn-success"
            :disabled="!selectedFiles"
            @click.prevent="uploadFiles"
            >
            Télécharger
            </b-button>

            <div v-if="message" class="alert alert-light" role="alert">
            <ul>
                <li v-for="(ms, i) in message.split('\n')" :key="i">
                {{ ms }}
                </li>
            </ul>
            </div>

            <div class="card">
            <div class="card-header">liste des fichiers</div>
            <ul class="list-group list-group-flush">
                <li class="list-group-item"
                v-for="(file, index) in fileInfos"
                :key="index"
                >
                <a :href="file.url">{{ file.name }}</a>
                </li>
            </ul>
            </div>
            <br/>
            <br/>
            <div class="sauvgardPhoto" >
                
                <b-card overlay v-for="pic in pics" :key="pic.link" v-bind="mainProps" :img-src="pic.link" fluid alt="Fluid image" @mouseover="pic.showbuttons = !pic.showbuttons" @mouseleave="pic.showbuttons = !pic.showbuttons">
                    <b-button v-if="pic.showbuttons" @click.prevent="deleteStorePIC(pic)">
                        delete
                    </b-button>
                    
                    <b-button v-if="pic.showbuttons">
                        view
                    </b-button>
                </b-card>
            </div>
        </b-card>
  </b-container>
</template>


<script>
import axios from 'axios';
import {mapGetters} from 'vuex';
export default {
        props: {
            userID:String,
        },
        name:'productViewImages',
        data() {
            return {
                dismissSecs: 5,
                dismissCountDown: 0,
            }
        },
        methods: {
            countDownChanged(dismissCountDown) {
                this.dismissCountDown = dismissCountDown
            },
            showAlert() {
                this.dismissCountDown = this.dismissSecs
            },
            async deleteStorePIC(picID){
                try{
                    var vm = this;
                    await axios.delete(`store/delete_pic/${picID.id}`);
                    this.alertcolor = '';
                    this.alertcolor = 'success';
                    this.errormsg = '';
                    this.errormsg = 'l\'image a été supprimée avec succès';
                    this.dismissCountDown = this.dismissSecs;
                    let index = this.pics.indexOf(picID);
                    if(index > -1){
                        this.pics.splice(index, 1);
                    }
                    vm.$forceUpdate();
                }catch(err){
                    this.alertcolor = '';
                    this.alertcolor = 'danger';
                    if(err.response.status == 400){
                        this.errormsg = '';
                        this.errormsg = 'l\'image n\'est pas supprimée';
                    }else{
                        this.errormsg = '';
                        this.errormsg = 'quelque chose s\'est mal passé';
                    }
                    this.dismissCountDown = this.dismissSecs;
                }
            },
            async upload(idx, file) {
                try{
                    var vm = this;
                    this.progressInfos[idx] = { percentage: 0, fileName: file.name };
                    let formData = new FormData();

                    formData.append("event", "store");
                    formData.append("store", file);
                    formData.append("storeID", this.user.storeID);
                    let res = await axios.post(`store/add_store_pics` ,formData,(event)=>{
                        this.progressInfos[idx].percentage = Math.round(100 * event.loaded / event.total);
                    });
                    let prevMessage = this.message ? this.message + "\n" : "";
                    this.message = prevMessage + res.data;
                    this.getStoreInfo();
                    vm.$forceUpdate();
                    //add get pics here
                    // this.fileInfos = files.data;
                }catch(err) {
                    this.progressInfos[idx].percentage = 0;
                    this.message = "Could not upload the file:" + file.name;
                    window.console.log(err);
                }
            },
            uploadFiles() {
                this.message = "";

                for (let i = 0; i < this.selectedFiles.length; i++) {
                    this.upload(i, this.selectedFiles[i]);
                }
            },
            selectFile() {
                this.progressInfos = [];
                this.selectedFiles = event.target.files;
            }
        },
        computed:{
            ...mapGetters(['store']),
            ...mapGetters(['user']),
        },
}
</script>

<style scoped>

.addProductPage
{
    background: #fff;
    padding: 20px 20px;
}
form{
    margin: 20px 0;
}
.input-field {
  position: relative;
  width: 400px;
  height: 80px;
  line-height: 35px;
}
input{
  max-width: 100%;
  outline: 0;
  margin: 0 0 20px 0;
  padding: 0.5rem;
  border: 2px solid #d3d3d3;
  font-size: 1.2em;
  box-shadow: none;
  color: #111;
}
textarea{
  max-width: 100%;
  outline: 0;
  margin: 0 0 0px 0;
  padding: 0;
  border: 2px solid #d3d3d3;
  font-size: 1.2em;
  box-shadow: none;
  color: #111;
}
label {
  position: absolute;
  top: 0;
  left: 10px;
  padding: 0 10px;
  margin: 0 0 0 0;
  color: #d3d3d3;
  transition: 0.2s all;
  cursor: text;
}
input:invalid,
textarea:invalid
{
  outline: 0;
}
input:focus,
input:valid,
textarea:focus,
textarea:valid {
  border-color: rgb(240, 100, 8);
}
input:focus~label,
input:valid~label,
textarea:focus~label,
textarea:valid~label{
  z-index: 1000;
  font-size: 1.4em;
  top: -23px;
  color: rgb(240, 100, 8);
  background: #ffff;
}
.BtnEnreg
{
    background: #F87127;
    color: #Fff;
    margin-left: 150px;
}

.input-group-text
{
    background: rgba(240, 101, 8, 0.096);
    width: 150px;
}
.sauvgardInformation
{
    position: relative;
    display: flex;
    justify-content: center;
}
.buttonEnregistrer
{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 3px solid rgb(240, 100, 8);
    padding: 3px 25px;
    font-size: 1.2rem;
    font-weight: 700;
    border-radius: 20px;
    border-radius: 20px;
    background: rgb(240, 100, 8);
}
.buttonEnregistrer:focus
{
  box-shadow: none;
}

</style>
<template>
    <b-container class="StorePageInfo">
        <b-alert :show="dismissCountDown" :variant="alertcolor" @dismissed="dismissCountDown=0" @dismiss-count-down="countDownChanged">
            {{errormsg}} {{ dismissCountDown }} seconds...
        </b-alert>
        <h2 class="mb-4"> Votre Store</h2>
        <b-card title="information Générales sur le magasin" sub-title="" class="mb-3">
            <b-form>
                <b-input-group id="input-group-1" label-for="input-5" class="input-field mb-2">
                    <b-form-input id="input-5" required v-model="store_name" :value="store_name" ></b-form-input>
                    <label for="Nom_magasin:">{{ $t('store.store_name') }}</label>
                </b-input-group>

                <b-input-group id="input-group-2" label-for="input-3" class="input-field mb-2">
                    <b-form-textarea id="textarea" v-model="description" rows="3" max-rows="6" :value="description"></b-form-textarea>
                    <label for="Description">Description</label>
                </b-input-group>

                <b-input-group id="input-group-3" label-for="input-3" class="input-field mb-2">
                    <b-form-input id="input-2" v-model="facebook" :value="facebook" ></b-form-input>
                    <label for="Facebook">Facebook</label>
                </b-input-group>
                <b-input-group id="input-group-3" label-for="input-3" class="input-field mb-2">
                    <b-form-input id="input-2" v-model="instagram" :value="instagram" ></b-form-input>
                    <label for="Instagram">Instagram</label>
                </b-input-group>
                    <div>
                    <b-form-checkbox v-model="fb" :indeterminate.sync="indeterminate">Afficher votre page Facebook</b-form-checkbox>
                    <b-form-checkbox v-model="insta" :indeterminate.sync="indeterminate">Afficher votre page Instagram</b-form-checkbox>
                    <!-- <b-form-checkbox v-model="price" :indeterminate.sync="indeterminate">Afficher les prix</b-form-checkbox> -->
                    </div>
                <div class="sauvgardInformation">
                    <b-button class="BtnEnreg" @click.prevent="updateStoreInfo">Enregistrer</b-button>
                </div>
            </b-form>
        </b-card>
        <b-card title="Contact Store" sub-title="" class="mb-3">
            <b-form>
                <b-form-tags v-model="phones" no-outer-focus class="input-field mb-2">
                <template v-slot="{ tags, inputAttrs, inputHandlers, addTag, removeTag }">
                    <b-input-group aria-controls="my-custom-tags-list">
                    <input
                        v-bind="inputAttrs"
                        v-on="inputHandlers"
                        placeholder="Nouveau téléphone"
                        class=" form-control">
                    <b-input-group-append>
                        <b-button @click="addTag(),addphone(inputAttrs)" class="BtAdAdPhone">Ajouter</b-button>
                    </b-input-group-append>
                    </b-input-group>
                    <ul
                    id="my-custom-tags-list"
                    class="list-unstyled d-inline-flex flex-wrap mb-0"
                    aria-live="polite"
                    aria-atomic="false"
                    aria-relevant="additions removals"
                    >
                    <!-- Always use the tag value as the :key, not the index! -->
                    <!-- Otherwise screen readers will not read the tag
                        additions and removals correctly -->
                    <b-card
                        v-for="tag in tags"
                        :key="tag"
                        :id="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                        tag="li"
                        class="mt-1 mr-1"
                        body-class="py-1 pr-2 text-nowrap"
                    >
                        <strong>{{ tag }}</strong>
                        <b-button
                        @click="removeTag(tag),removePhone(tag)"
                        variant="link"
                        size="sm"
                        :aria-controls="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                        >Supprimer</b-button>
                    </b-card>
                    </ul>
                </template>
                </b-form-tags>
                    <b-button v-b-modal.modal-1 class="BtAdAdress"><b-icon icon="plus-circle"></b-icon> Ajouter Adresse</b-button>
                    <b-card no-body class="mb-1" v-for="address in addresses" :key="address.id">
                        <b-card-header header-tag="header" class="p-1" role="tab">
                            <b-button block v-b-toggle="address.id" variant="info">address {{address.num}}</b-button>
                        </b-card-header>
                        <b-collapse :id="address.id" accordion="my-accordion" role="tabpanel">
                            <b-card-body>
                            <b-card-text>Wilaya: {{ address.wilaya }}</b-card-text>
                            <b-card-text>Daira: {{ address.daira }}</b-card-text>
                            <b-card-text>Commune: {{ address.commune }}</b-card-text>
                            <b-card-text>Rue:</b-card-text><b-card-text>{{ address.rue }}</b-card-text>
                            <b-card-text>Zip: {{ address.rue }}</b-card-text>
                            <b-button @click="removeAddress(address.id)">Supprimer</b-button>
                            </b-card-body>
                        </b-collapse>
                    </b-card>
            </b-form>
                
            <b-modal hide-footer id="modal-1" title="Add Car">
                <p class="my-4"><StoreAddAddress></StoreAddAddress></p>
            </b-modal>
        </b-card>
        <b-card title="" sub-title="" class="mb-3">
            <label class="btn btn-default">
                <input type="file" multiple @change="selectFile" />
            </label>
            <br/>
            <br/>
            <div v-if="progressInfos">
            <div class="mb-4"
                v-for="(progressInfo, index) in progressInfos"
                :key="index"
            >
                <span>{{progressInfo.fileName}}</span>
                <div class="progress">
                <div class="progress-bar progress-bar-info"
                    role="progressbar"
                    :aria-valuenow="progressInfo.percentage"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    :style="{ width: progressInfo.percentage + '%' }"
                >
                    {{progressInfo.percentage}}%
                </div>
                </div>
            </div>
            </div>
            <br/>
            <br/>
            <b-button class="btnTelecharger btn-success"
            :disabled="!selectedFiles"
            @click.prevent="uploadFiles"
            >
            Télécharger
            </b-button>

            <div v-if="message" class="alert alert-light" role="alert">
            <ul>
                <li v-for="(ms, i) in message.split('\n')" :key="i">
                {{ ms }}
                </li>
            </ul>
            </div>

            <div class="card">
            <div class="card-header">liste des fichiers</div>
            <ul class="list-group list-group-flush">
                <li class="list-group-item"
                v-for="(file, index) in fileInfos"
                :key="index"
                >
                <a :href="file.url">{{ file.name }}</a>
                </li>
            </ul>
            </div>
            <br/>
            <br/>
            <div class="sauvgardPhoto" >
                
                <b-card overlay v-for="pic in pics" :key="pic.link" v-bind="mainProps" :img-src="pic.link" fluid alt="Fluid image" @mouseover="pic.showbuttons = !pic.showbuttons" @mouseleave="pic.showbuttons = !pic.showbuttons">
                    <b-button v-if="pic.showbuttons" @click.prevent="deleteStorePIC(pic)">
                        delete
                    </b-button>
                    
                    <b-button v-if="pic.showbuttons">
                        view
                    </b-button>
                </b-card>
            </div>
        </b-card>
    </b-container>
</template>

<script>
import axios from 'axios';
import {mapGetters} from 'vuex';
import StoreAddAddress from'../components/StoreAddAddress.vue'
// import UploadService from "../services/UploadFilesService";

export default{
    name: 'UserProfileInformationStore',
    props: {
        storeID:String,
    },
    components:{
            StoreAddAddress
    },
    data() {
        return{
            mainProps: { blank: false, width: 200, height: 200, class: 'm1' },
            fb: true,
            insta: true,
            price: true,
            data:null,
            store_name:'',
            description:'',
            facebook:'',
            instagram:'',
            long:'',
            lat:'',
            pics:[],
            phones:[],
            addresses:[],
            selectedFiles: undefined,
            progressInfos: [],
            message: "",
            fileInfos: [],
            dismissSecs: 5,
            dismissCountDown: 0,
            errormsg:'',
            alertcolor:''
        }
    },
    async mounted(){
        this.getStoreInfo();
    },
    methods:{
        countDownChanged(dismissCountDown) {
            this.dismissCountDown = dismissCountDown
        },
        showAlert() {
            this.dismissCountDown = this.dismissSecs
        },
        async deleteStorePIC(picID){
            try{
                var vm = this;
                await axios.delete(`store/delete_pic/${picID.id}`);
                this.alertcolor = '';
                this.alertcolor = 'success';
                this.errormsg = '';
                this.errormsg = 'l\'image a été supprimée avec succès';
                this.dismissCountDown = this.dismissSecs;
                let index = this.pics.indexOf(picID);
                if(index > -1){
                    this.pics.splice(index, 1);
                }
                vm.$forceUpdate();
            }catch(err){
                this.alertcolor = '';
                this.alertcolor = 'danger';
                if(err.response.status == 400){
                    this.errormsg = '';
                    this.errormsg = 'l\'image n\'est pas supprimée';
                }else{
                    this.errormsg = '';
                    this.errormsg = 'quelque chose s\'est mal passé';
                }
                this.dismissCountDown = this.dismissSecs;
            }
        },
        async getStoreInfo(){
            try{
            // this.user.storeID = '67a57690-8e54-49c2-ba18-8458b0e7ae76';
            let res = await axios.get(`store/get_store/${this.user.storeID}`);
            this.data = res.data;
            this.store_name = this.data.name;
            this.description = this.data.description;
            this.facebook = this.data.fb;
            this.instagram = this.data.insta;
            this.fb = this.data.store_setting.show_fb;
            this.insta = this.data.store_setting.show_inst;
            // this.price = this.data.store_setting.show_prices;
            let link = '';
            this.pics = [];
            this.addresses = [];
            this.phones = [];
            for(let pic of this.data.store_pics){
                link = pic.file;
                this.pics.push({id:pic.id,link:'https://www.api.mikaniky.com/'+ link.replace( /\\/g, "/"),showbuttons:false});
            }
            for(let phone of this.data.contact.phones){
                this.phones.push(phone.phone_num);
            }
            
            let i=0;
            for(let address of this.data.contact.addresses){
                i++;
                this.addresses.push({id:address.id, wilaya: address.wilaya, daira: address.daira, commune: address.commune,zip:address.zip ,rue: address.rue,num:i});
            }
            
        }catch(e){
            window.console.error(e);
        }
        },
        async updateStoreInfo(){
            try{
                var vm = this;
                await axios.put(`store/update_info`,{
                    storeID:this.user.storeID,
                    storeName:this.store_name,
                    description:this.description,
                    // long: this.long,
                    // lat:this.lat,
                    fb:this.facebook,
                    insta:this.instagram,
                    show_fb:this.fb,
                    show_inst:this.insta,
                    show_prices:this.price
                });
                vm.$forceUpdate();
            }catch(err){
                window.console.error(err);
            }
        },
            async removeAddress(addressID){
                try{
                    var vm = this;
                    await axios.post(`store/add_address`,{
                        addressID: addressID
                    });
                    vm.$forceUpdate();
                }catch(e){
                    window.console.error(e);
                }
            },
            async addphone(phone){
                try{
                    var vm = this;
                    await axios.post(`store/add_phone`,{
                        storeID: this.user.storeID,
                        phoneNum: phone.value
                    });
                    vm.$forceUpdate();
                }catch(e){
                    window.console.error(e);
                }
            },
            async removePhone(phone){
                try{
                    await axios.delete(`store/delete_phone/${phone}`);
                }catch(e){
                    window.console.error(e);
                }                
            },
            async upload(idx, file) {
                try{
                    var vm = this;
                    this.progressInfos[idx] = { percentage: 0, fileName: file.name };
                    let formData = new FormData();

                    formData.append("event", "store");
                    formData.append("store", file);
                    formData.append("storeID", this.user.storeID);
                    let res = await axios.post(`store/add_store_pics` ,formData,(event)=>{
                        this.progressInfos[idx].percentage = Math.round(100 * event.loaded / event.total);
                    });
                    let prevMessage = this.message ? this.message + "\n" : "";
                    this.message = prevMessage + res.data;
                    this.getStoreInfo();
                    vm.$forceUpdate();
                    //add get pics here
                    // this.fileInfos = files.data;
                }catch(err) {
                    this.progressInfos[idx].percentage = 0;
                    this.message = "Could not upload the file:" + file.name;
                    window.console.log(err);
                }
            },
            uploadFiles() {
                this.message = "";

                for (let i = 0; i < this.selectedFiles.length; i++) {
                    this.upload(i, this.selectedFiles[i]);
                }
            },
            selectFile() {
                this.progressInfos = [];
                this.selectedFiles = event.target.files;
            }
    },
    computed:{
      ...mapGetters(['store']),
      ...mapGetters(['user']),
    },
}
</script>

<style scoped>

.StorePageInfo
{
    height: 100%;
    background: #fff;
    padding: 40px 40px;
}
.input-group-text
{
    background: rgba(240, 101, 8, 0.096);
    width: 150px;
}
.sauvgardInformation
{
    position: relative;
    display: flex;
    justify-content: center;
}


form{
    margin: 50px 0;
}
.input-field {
  position: relative;
  width: 400px;
  line-height: 35px;
}
input,textarea{
  max-width: 100%;
  border: 0;
  outline: 0;
  margin: 0 0 50px 0;
  padding: 0.5rem;
  border: 2px solid #d3d3d3;
  font-size: 1.2em;
  box-shadow: none;
  color: #111;
}
label {
  position: absolute;
  top: 0;
  left: 10px;
  padding: 0 10px;
  margin: 0 0 0 0;
  color: #d3d3d3;
  transition: 0.2s all;
  cursor: text;
}
input:invalid,
textarea:invalid
{
  outline: 0;
}
input:focus,
input:valid,
textarea:focus,
textarea:valid {
  border-color: rgb(240, 100, 8);
}
input:focus~label,
input:valid~label,
textarea:focus~label,
textarea:valid~label{
  z-index: 1000;
  font-size: 1.4em;
  top: -23px;
  color: rgb(240, 100, 8);
  background: #ffff;
}

.BtnEnreg
{
    background: #F87127;
    color: #Fff;
    margin-left: 150px;
}


.BtAdAdress
{
  color: #F87127;
  border-color: #F87127;
  border-radius: 10px;
  background: #fff;
  font-size: 1.2rem;
  font-weight: 500;
}
.BtAdAdress:hover,
.BtAdAdress:active
{
  background: #F87127;
  color: #fff;
  box-shadow: 0px 0px 0px 4px #f8702769;
}
.BtAdAdPhone
{
  color: #fff;
  background: #F87127;
  border-color: #F87127;
  border-radius: 10px;
  font-size: 1.2rem;
  font-weight: 500;
}
.BtAdAdPhone:hover,
.BtAdAdPhone:active
{
  background: #ffffff;
  color: #F87127;
  box-shadow: 0px 0px 0px 4px #f8702769;
}
.btnTelecharger 
{
    margin: 50px 0 5px 100px; 
}
/*.buttonEnregistrer
{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 3px solid rgb(240, 100, 8);
    padding: 3px 25px;
    font-size: 1.2rem;
    font-weight: 700;
    border-radius: 20px;
    border-radius: 20px;
    background: rgb(240, 100, 8);
}
.buttonEnregistrer:focus
{
  box-shadow: none;
}*/

</style>

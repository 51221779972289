<template>
    <b-container class="cardCategorie"> 
        <div class="row">
            <!--
            <div  v-for="card in cards" :key="card.name" class="cardProduit col-md-3 col-6 my-1">
                <div class="card h-100" v-on:click="get_product_cat(card.name)">
                <router-link :to="`/${$i18n.locale}/pagepiecerecherche`">
                    <div class="card-img-top">
                        <img :src="card.img">
                    </div>
                    <div class="card-body">
                        <div class="card-title" >{{ card.name }}</div>
                    </div>
                </router-link>    
                </div>
            </div>
            -->
            <div  v-for="card in subcards" :key="card.name" class="cardProduit col-md-4 col-6 my-1">
                 <div class="card h-100"  v-on:click="get_product_cat(card.name)">
                    <router-link :to="`/${$i18n.locale}/pagepiecerecherche`">
                        <!-- hello ouss -->
                        <div class="row rowCardCategories">
                            <b-col class="card-img-top" col="2">
                                <img v-if="false" :src="card.img" class="mr-3" alt="...">
                            </b-col>
                            <b-col class="card-title col-10" col="10">
                                {{ card.name }}
                            </b-col>
                        </div>
                        <div v-for = "child in card.childs" :key="child.id" class="media-body">
                            <ul>
                                <!-- <li class="list-group-item"> {{card.list}} </li> -->
                                <li class="list-group-item"> {{child.product_type_name}}</li>
                            </ul>
                        </div>
                        <div class="buttonViewTopPiece">
                            <b-button  class="buttonView" @click.prevent="get_sub_prod(card.id)" :to="`/${$i18n.locale}/SubCategorieLastLevel/${card.name}`">Voir +</b-button>
                        </div>
                    </router-link>    
                </div>
            </div>
            
        </div>
    </b-container>
</template>

<script>
import axios from 'axios';
import { bus } from '../main'
export default {
    name:'CardCategorie',
    props: {
        subcards: {
        }
    },
    components: {
    },
    data() {
        return {
            searchResult:[],
            last_sub_cat:[],
        }
    },
  
  methods: {
    async get_sub_prod(fatherID){
        try{
            let result = await axios.get(`user/get_all_sub_cat/${fatherID}`);
            this.last_sub_cat = result.data;
            // window.console.log(result.data);
            for(let cardinfo of this.last_sub_cat){
                let link  = 'https://www.api.mikaniky.com/'+ cardinfo.pic.replace( /\\/g, "/");
                this.subCard.push({name:cardinfo.product_type_name,img:link,id:cardinfo.id,childs:cardinfo.children});
            }
            bus.$emit('lastSubCat',this.last_sub_cat);
        }
        catch(err){
            window.console.error(err);
        }
    }
  },
}
</script>

<style scoped>
    .cardProduit
    {
        position: relative;
        /* max-width: 350px;
        max-height: 500px; */
        background: #f5f5f5;
        display: inline-block;
        transition: .3s all ease;
        -webkit-transform: scale(0.95);
        transform: scale(0.95);
        transition: .3s ease-in-out;
        -webkit-transition: .3s ease-in-out;
        margin: 0 auto;
        cursor: pointer ;
    }
    /*.cardCategorie .cardProduit:hover{
        -webkit-transform: scale(1);
        transform: scale(1);
    }*/
    .rowCardCategories
    {
        padding: 5px 5px;
        height: 50px;

    }
    .cardProduit .card-title
    {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        height: 50px;
        color: rgb(240, 100, 8);
        padding: 0 0 0 5px;
        font-weight: 900;
        text-align: center;
        font-size: 1.1rem;
    }
    .cardCategorie .card:hover
    {
        box-shadow: 0 1px 10px 5px #f8702763;
    }
    /*.cardCategorie .card-body
    {
        position: relative;
        margin: 0;
        max-width: 100%;
        height: 100%;
        border-right-width: 0;
        border-left-width: 0;
        border-bottom-width: 0;
    }*/
    .cardCategorie .card-img-top
    {
        display: flex;
        align-items: center;
        padding-left: 15px;
        padding-right: 0;
    }
    
    .cardCategorie .card-img-top img
    {
        max-width: 100%;
        height: 40px;
    }
    .cardCategorie .list-group-item
    {
        border: none;
    }
    .cardCategorie .media-body
    {
        margin: 15px 0 0 0;
        padding: 0;
    }
    .cardCategorie ul
    {
        margin: 0 0 0 0;
    }
    .list-group-item:hover
    {
        color: rgb(240, 100, 8);
    }
    .cardCategorie .buttonViewTopPiece
    {
        position: relative;
        display: flex;
        justify-content: flex-end;
        margin: 0 10px 10px 0;
    }   
    .cardCategorie .buttonViewTopPiece .buttonView
    {
        border-width: 1px;
        font-size: 900;
        border-radius: 5px;
        color: #fff;
        font-weight: 700;
        background: rgb(240, 100, 8);
    }
    .cardCategorie .buttonViewTopPiece .buttonView:focus,
    .cardCategorie .buttonViewTopPiece .buttonView:active
    {
        color: rgb(240, 100, 8);
        background: #fff;
        border: 1px solid rgb(240, 100, 8);
        box-shadow: 0 0 0 0.1rem rgba(240, 101, 8, 0.287);

    }
</style>
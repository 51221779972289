<template>
  <b-container fluid no-gutters class="profilSalleForCustemer">
          <b-row class="contentPage_Top">
              <b-col fluid no-guttars class="content_left col-6">
                <h2 class="nomPiece" id="nomPiece">NPS D490O22{{ nom_Piece }}</h2>
                <b-row>
                  <b-col  class="nDePiece mt-3">
                    <h6 id="nDePiece">N° de piece: {{ n_De_Piece }}</h6> 
                  </b-col>
                  <b-col  class="nReference mt-3">
                    <h6  id="nReference">Référence: #{{ n_Reference }}</h6>
                  </b-col>
                </b-row>
                
                <b-container class="PrixStore"> 
                  <b-row> 
                    <b-col md="6" lg="6" xl="6" class="PrixPiece" >
                      <h5>Prix:<span  id="PrixPiece"> {{ prix_Piece }} </span>da</h5>	
                    </b-col>
                    <b-col md="6" lg="6" xl="6">
                      <b-button class="buttonPageStore" :to="`/${$i18n.locale}/store/${storeID}`">Voir Profil</b-button>
                    </b-col>
                  </b-row> 
                </b-container>
                <hr class="divider divider-xs bg-bermuda offset-top-15">
                <b-link class="linkNomStore" :to="`/${$i18n.locale}/store/${storeID}`"><h6> Nom du Magasin: {{ nom_Store }}</h6></b-link>
                <h6 class="wilayaPiece" id="wilayaPiece"><span><font-awesome-icon :icon="['fa', 'map-marker-alt']"/></span>  Wilaya: {{ wilaya_Piece }}</h6>
                <div class="mt-3 mb-3">
                  <StarRating :glyph="Stars" :spacing="1" active-color="#f6c23d" :increment="1" :fixed-points="0" :item-size="20" :rating="rating" :read-only="true"></StarRating>
                </div>
              </b-col>
                  <b-col class="imagePiece col-6">
                    <lingallery :addons="{ enableLargeView: true, enablePictureElement: true}" :responsive="false" :showControls="false" :iid.sync="currentId" :width="width" :height="height" :items="item"/>
                      <!--<img src="../assets/les_phares.jpg" alt="Piece déttaché">-->
                  </b-col> 

            </b-row>

                <!--<div id="descriptionPiece" class="descriptionPiece mt-5">
                  <h3>Les couleurs disponible:</h3><br>
                  <h3>L'état du produit:</h3><br>
                  <p>{{ etat_produit }}</p><br>
                  <h3>Description: </h3><br>
                  <p>{{ description_Piece }}</p><br>
                  <h3>Autre Description: </h3><br>
                  <p>{{ autre_description_Piece }}</p><br>
                </div>-->

                <b-container fluid no-gutters class="InfReference mt-4">
                  <b-row>
                    <b-col class="RFournisseur" cols="12" sm="12" md="4" lg="4" xl="4">
                      <h5>Référence constructeur</h5>
                            <ul>
                                <li class="list-group-item"> kdhfbng kzzos </li>
                                <li class="list-group-item"> Dapibus ac facilisis in</li>
                                <li class="list-group-item"> Morbi leo risus</li>
                            </ul>
                    </b-col>
                    <b-col class="REquipement" cols="12" sm="12" md="4" lg="4" xl="4">
                      <h5>Référence équipement</h5>
                            <ul>
                                <li class="list-group-item"> kdhfbng kzzos </li>
                                <li class="list-group-item"> Dapibus ac facilisis in</li>
                            </ul>
                    </b-col>
                    <b-col class="IComplment" cols="12" sm="12" md="4" lg="4" xl="4">
                      <h5>informations complimentaire</h5>
                            <ul>
                                <li class="list-group-item"> kdhfbng kzzos </li>
                            </ul>
                    </b-col>
                  </b-row>
                </b-container>

                <b-container fluid no-gutters class="VehCompat mt-4">
                  <h5>Véhcules compatible</h5>
                    <div class="cardProduit col-md-4 col-6 my-1">
                      <div class="card h-100">
                          <div class="row rowCardMarque">
                              <b-col class="card-img-top" col="3">
                                  <img src="../assets/volkswagen.png" class="" alt="...">
                              </b-col>
                              <b-col class="card-title col-10" col="10">
                                  <h6>volkswagen</h6>  
                              </b-col>
                          </div>
                          <div class="media-body">
                              <ul>
                                  <li class="list-group-item"> dfghfggfd gger</li>
                                  <li class="list-group-item"> Dapibus ac facilisis in</li>
                                  <li class="list-group-item"> Morbi leo risus</li>
                                  <li class="list-group-item"> Porta ac consectetur ac</li>
                                  <li class="list-group-item"> Vestibulum at eros</li>
                              </ul>
                          </div>
                      </div>
                    </div>
                  <!--<b-table striped hover :items="items" :fields="fields"></b-table>-->
                </b-container>
          
          <my-PubDeuxHoriz></my-PubDeuxHoriz>
  </b-container>
</template>

<script>
import { StarRating } from "vue-rate-it";
import Star_Rating from "vue-rate-it/glyphs/star";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import PubDeuxHoriz from "../components/PubDeuxHoriz.vue";
import axios from "axios";
import Lingallery from 'lingallery';

library.add(faMapMarkerAlt);

export default {
  props: {
    productID:String,
    storeID:String,
  },
  name: "PagePiece",
  components: {
    Lingallery,
    StarRating,
    "font-awesome-icon": FontAwesomeIcon,
    "my-PubDeuxHoriz": PubDeuxHoriz,
  },
	async created(){
    // register the icon
      this.Stars = Star_Rating;
      let res = await axios.get(`user/get_product_id/${this.productID}`);
      this.data = res.data;
      // window.console.log(this.data);
      for(let productpic of this.data.product_pics){
        this.productPics.push(productpic);
      }
      for(let productcolor of this.data.product_colors){
        this.productColors.push(productcolor);
      }
      for(let carbrand of this.data.car_brands){
        this.carBrands.push(carbrand);
      }
      for(let carmodel of this.data.car_models){
        this.carmodels.push(carmodel);
      }
      for(let carengine of this.data.car_engines){
        this.carEngines.push(carengine);
      }
      for(let i in this.data.car_models){
        this.items.push({marque:this.data.car_brands[i].brand,modéle:this.data.car_models[i].model,année:this.data.car_models[i].year,motorisation:this.data.car_engines[i].capacity+','+this.data.car_engines[i].energy_type});
      }
      this.storeID = this.data.StoreProducts[0].store.id;

      this.description_Piece = this.data.product_description;
      this.autre_description_Piece = this.data.other_product_description;
      this.nom_Piece = this.data.product_name;
      this.nom_Store = this.data.StoreProducts[0].store.name;
      this.prix_Piece = this.data.StoreProducts[0].product_price;
      this.wilaya_Piece = this.data.StoreProducts[0].store.contact.addresses[0].wilaya;
    },
  data() {
    return {
      fields: ['marque', 'modéle', 'année','motorisation'],
      items:[],
			Stars: '',	
      etat_produit:'',	
			nom_Piece:'',
			n_Reference:'',
			n_De_Piece:'',
			wilaya_Piece:'',
			prix_Piece: null,
			nom_Store:'',
      description_Piece: '',
      autre_description_Piece:'',
			rating:4,
      data:null,
      productPics:[],
      productColors:[],
      carBrands:[],
      carmodels:[],
      carEngines:[],
      width: 600,
      height: 400,
      item: [{
          src: 'https://www.mikaniky.com/img/les_phares.b2e886dc.jpg',
          thumbnail: 'https://www.mikaniky.com/img/les_phares.b2e886dc.jpg',
          id: 'someid1'
        },
        {
          src: 'https://picsum.photos/600/400/?image=10',
          thumbnail: 'https://picsum.photos/64/64/?image=10'
        },
      ],
      currentId:null
    };
  },
};
</script>

<style scoped>

.profilSalleForCustemer
{
  background: #fff;
}
.adresseStore {
  position: relative;
  display: flex;
}
.ratingStore {
  position: relative;
  display: flex;
}
.imagePiece
{
  position: relative;
  display: flex;
  justify-content: center;
}
.imagePiece img
{
  position: relative;
  width: 80%;
  max-height: 60%;
}
hr
{
  display: flex;
  justify-content: center;
  max-width: 75%;
  color: rgb(240, 100, 8);
}
.nDePiece
{
  text-align: start;
  max-width: 250px;
}
.PrixStore
{
  position: relative;
  display: flex;
  justify-items: center ;
  margin-top: 15px;
}
.PrixStore .PrixPiece
{
  position: relative;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}
.PrixStore .PrixPiece h5
{
  margin: 0 0 0;
  font-size: 1.8rem;

}
.PrixStore .buttonPageStore
{
  border: 3px solid rgb(240, 100, 8);
  font-size: 1.2rem;
  font-weight: 700;
  border-radius: 20px;
  border-radius: 20px;
  background: rgb(240, 100, 8);
}
.PrixStore .buttonPageStore:focus
{
  box-shadow: none;
}
.linkNomStore
{
  color: rgb(240, 100, 8);
}
.wilayaPiece span
{
  color: rgb(240, 100, 8);
}

.InfReference, 
.VehCompat
{
  margin: 100px 0 50px 0;
}

    .VehCompat .cardProduit
    {
        position: relative;
        max-width: 250px;
        max-height: 250px;
        background: #f5f5f5;
        display: inline-block;
        transition: .3s all ease;
        -webkit-transform: scale(0.95);
        transform: scale(0.95);
        transition: .3s ease-in-out;
        -webkit-transition: .3s ease-in-out;
        margin: 0 auto;
        cursor: pointer ;
    }
    /*.cardCategorie .cardProduit:hover{
        -webkit-transform: scale(1);
        transform: scale(1);
    }*/
    .VehCompat .rowCardMarque
    {
        padding: 5px 5px;
        height: 50px;

    }
    .VehCompat .cardProduit .card-title
    {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        height: 50px;
        color: rgb(240, 100, 8);
        padding: 0 0 0 5px;
        font-weight: 900;
        text-align: center;
        font-size: 1.1rem;
    }
    .VehCompat .card-img-top
    {
        display: flex;
        align-items: center;
        margin: 10px 0 0 0;
        padding-left: 15px;
        padding-right: 0;
        width: 55px;
        height: 35px;
    }
    
    .VehCompat .card-img-top img
    {
        width: 100%;
        height: 100%;
    }
    .VehCompat .media-body
    {
        margin: 15px 0 0 0;
        padding: 0;
    }
    ul
    {
        margin: 0 0 0 0;
        padding: 0 0 0 0;
        border: 0px solid #fff;
    }
    .list-group-item
    {
        border: 0px solid #fff;
    }
    .list-group-item:hover
    {
        color: rgb(240, 100, 8);
        border: 0px solid #fff;
    }

</style>

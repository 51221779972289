<template>
  <div>
    <horizontal-stepper :steps="demoSteps" @completed-step="completeStep"
    @active-step="isStepActive" @stepper-finished="alert"
    >                     
  </horizontal-stepper>
  </div>
</template>

<script>
import HorizontalStepper from 'vue-stepper';
import StepOne from '../components/UserStepOne.vue'
import StepTow from '../components/UserStepTow.vue'
import StepThree from '../components/UserStepThree.vue'
import StepFor from '../components/UserStepFor.vue'

export default {
  name: 'AddUserInfo',
  components: {
    HorizontalStepper
  },
    data() {
    return {
      demoSteps: [
        {
          icon: "mail",
          name: "first",
          title: "Step 1",
          subtitle: "Step 1",
          component: StepOne,
          completed: false
        },
        {
          icon: "mail",
          name: "second",
          title: "Step 2",
          subtitle: "Step 2",
          component: StepTow,
          completed: false
        },
        {
          icon: "mail",
          name: "third",
          title: "Step 2",
          subtitle: "Step 3",
          component: StepThree,
          completed: false
        },
        {
          icon: "mail",
          name: "forth",
          title: "Step 4",
          subtitle: "Step 4",
          component: StepFor,
          completed: true
        },
      ],
    }
  },
  methods: {
    completeStep(payload) {
      this.demoSteps.forEach(step => {
        if (step.name === payload.name) {
          step.completed = true;
        }
      });
    },
    isStepActive(payload) {
      this.demoSteps.forEach(step => {
        if (step.name === payload.name) {
          if (step.completed === true) {
            step.completed = false;
          }
        }
      });
    },
    alert() {
      alert("end");
    }
  }
};
</script>
<template>
  <b-container>
    <b-card title="User picture" sub-title="Profile Picture">
      <b-form-group id="input-group-1" label="Profile Picture:" label-for="input-1" description="" class="mb-4">
        <b-img src="https://picsum.photos/125/125/?image=58" rounded="circle" alt="Profile image"></b-img>
        <b-form-file  placeholder="Choose a file or drop it here..." drop-placeholder="" ></b-form-file>
      </b-form-group>
    </b-card>
    <b-card title="Store pictures" sub-title="Pictures">
            <div v-if="progressInfos">
            <div class="mb-2"
                v-for="(progressInfo, index) in progressInfos"
                :key="index"
            >
                <span>{{progressInfo.fileName}}</span>
                <div class="progress">
                <div class="progress-bar progress-bar-info"
                    role="progressbar"
                    :aria-valuenow="progressInfo.percentage"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    :style="{ width: progressInfo.percentage + '%' }"
                >
                    {{progressInfo.percentage}}%
                </div>
                </div>
            </div>
            </div>

            <label class="btn btn-default">
            <input type="file" multiple @change="selectFile" />
            </label>

            <button class="btn btn-success"
            :disabled="!selectedFiles"
            @click="uploadStoreFiles"
            >
            Upload
            </button>

            <div v-if="message" class="alert alert-light" role="alert">
            <ul>
                <li v-for="(ms, i) in message.split('\n')" :key="i">
                {{ ms }}
                </li>
            </ul>
            </div>

            <div class="card">
            <div class="card-header">List of Files</div>
            <ul class="list-group list-group-flush">
                <li class="list-group-item"
                v-for="(file, index) in fileInfos"
                :key="index"
                >
                <a :href="file.url">{{ file.name }}</a>
                </li>
            </ul>
            </div>
            <div class="sauvgardPhoto">
                <b-img v-for="pic in pics" :key="pic.link" v-bind="mainProps" :src="pic.link" fluid alt="Fluid image"></b-img>
            </div>
        </b-card>
  </b-container>
</template>

<script>
import axios from 'axios';
import {mapGetters} from 'vuex';
export default {
  props: {
    storeID:String,
    userID:String,
  },
  data() {
    return {
      mainProps: { blank: false, width: 200, height: 200, class: 'm1' },
      pics:[],
      progressInfos: [],
      selectedFiles: undefined,
      message: '',
    };
  },
  beforeMount(){
      this.$emit("can-continue", { value: true });
  },
  methods: {
    async getpics(){
      try{
        let link = '';
        let res = await axios.get(`store/get_store/${this.store.id}`);
        this.pics = [];
          for(let pic of res.data.store_pics){
            link = pic.file;
            this.pics.push({link:'https://www.api.mikaniky.com'+ link.replace( /\\/g, "/")});
          }
      }catch(err){
        window.console.error(err);
      }
    },
    async upload(idx, file) {
      try{
          this.progressInfos[idx] = { percentage: 0, fileName: file.name };
          let formData = new FormData();
          formData.append("event", "store");
          formData.append("store", file);
          formData.append("storeID", this.store.id);
          let res = await axios.post(`store/add_store_pics` ,formData,(event)=>{
              this.progressInfos[idx].percentage = Math.round(100 * event.loaded / event.total);
          });
          let prevMessage = this.message ? this.message + "\n" : "";
          this.message = prevMessage + res.data;
          this.getStoreInfo();
          //add get pics here
          // this.fileInfos = files.data;
      }catch(err) {
          this.progressInfos[idx].percentage = 0;
          this.message = "Could not upload the file:" + file.name;
      }
    },
    uploadStoreFiles() {
        this.message = "";
        for (let i = 0; i < this.selectedFiles.length; i++) {
            this.upload(i, this.selectedFiles[i]);
        }
    },
    selectFile() {
        this.progressInfos = [];
        this.selectedFiles = event.target.files;
    }
  },
  computed:{
    ...mapGetters(['user']),
    ...mapGetters(['store']),
  },
};
</script>
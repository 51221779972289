<template>
  <b-row>
    <b-col id="sub" class="userID" cols="12" md="6" lg="6" xl="6">
      <b-form class="formLoginSubs" @submit.prevent="onSubmitSub" @reset="onReset">
        <h2>Inscription</h2>
        <hr class="divider divider-xs bg-bermuda offset-top-15" />
        <div class="input">
          <b-form-group
            id="identification-Input"
            label="Identifiant de connexion:"
            label-for="identificationInput"
          >
            <b-form-input
              id="identificationInput"
              v-model="subForm.userName"
              required
              placeholder="Saisir votre Identifiant"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="idEmail-Input"
            label="Entrer votre Email:"
            label-for="idEmailInput"
          >
            <b-form-input
              id="idEmailInput"
              v-model="subForm.email"
              type="email"
              required
              placeholder="ayh@gmail.com"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="idPassword-Input"
            label="Entrer votre mot de passe:"
            label-for="idPasswordInput"
          >
            <b-form-input
              id="idPasswordInput"
              v-model="subForm.password"
              type="password"
              required
              placeholder="**********"
            ></b-form-input>
          </b-form-group>

          <b-form-group id="idPasswordConf-Input" label-for="idPasswordConfInput">
            <b-form-input
              id="idPasswordConfInput"
              v-model="passVerification"
              type="password"
              required
              placeholder="Confirmer votre mot de passe"
            ></b-form-input>
          </b-form-group>

          <b-form-group id="userType-Input" label="Sélectionner un type d'utilisation :" label-for="userTypeInput">
            <b-form-select id="userTypeInput" v-model="selected" :options="options" @change="changerole" size="sm" class="mt-3"></b-form-select>
          </b-form-group>

          <b-form-checkbox
            id="checkbox-1"
            v-model="status"
            name="checkbox-1"
            value="accepted"
            class="checkBoxCondition"
            unchecked-value="not_accepted"
            required
          >
            En cliquant sur Inscrivez vous, vous indiquez que vous avez lu,
            compris et accepté
            <b-button
              variant="link"
              v-b-modal.modal-scrollable
              class="conditionUtilisation"
              >les conditions d'utilisation
            </b-button>
            de Mikaniky.
            <b-modal
              id="modal-scrollable"
              scrollable
              title="Condition d'utilisation"
              ok-only
            >
              <p class="my-4" v-for="i in 1" :key="i">
                Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
                dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta
                ac consectetur ac, vestibulum at eros.
              </p>
            </b-modal>
          </b-form-checkbox>

          <b-form-group>
            <input type="submit" value="S'INSCRIRE" />
          </b-form-group>
        </div>
      </b-form>
    </b-col>
    
    <b-col class="imageStore" cols="12" md="6" lg="6" xl="6">
        <img src="../assets/image_SingUp_right.jpg" alt="Store déttaché">
    </b-col>

  </b-row>
</template>

<script>
import axios from 'axios';
export default {
  name: "SignUp",
  components: {},
    data () {
      return {
        selected:null,
        options:[
            { value: null, text: 'Sélectionner le type de connexion '},
            { value: 'user', text: 'Particulier' },
            { value: 'manager', text: 'Magasin' },
        ],
        subForm:{
          userName:"",
          password:"",
          email:"",
          role:null
        },
        passVerification:"",
      }
  },
  methods: {
    async onSubmitSub(){
      try{
        
      const res = await axios.post(`auth/signup`,{
        username:this.subForm.userName,
        email:this.subForm.email,
        password:this.subForm.password,
        role: this.subForm.role
      });
      window.console.log(res);
      
      window.location.href = "https://www.mikaniky.com/";
      
      }catch(err){
      window.console.log(err);
      }
    },
    changerole(value){
      this.subForm.role = value;
      window.console.log(this.subForm.role);
    }
  },
};
</script>

<style scoped>
.loginAndSubsc {
  position: relative;
  min-height: 100vh;
  background: #f5f5f5;
  padding: 20px 10px;
}
.row .userID {
  display: flex;
  justify-content: center;
  align-items: center;
}
.formLoginSubs {
  position: relative;
  background: #fff;
  margin-bottom: 20px;
  width: 500px;
  padding: 40px 40px 60px; /* les marge*/
  border-radius: 10px; /*Arrondi les coins*/
  text-align: center;
  box-shadow: -3px -3px 3px rgba(0, 0, 0, 0.329),
    /*silohouette de Form*/ 3px 3px 3px rgba(0, 0, 0, 0.329);
}
h2 {
  color: #ff5100; /*changement de couleur*/
  font-weight: 500; /*text gras*/
  letter-spacing: 2px; /*l'espace entre les lettre*/
  display: flex;
  justify-content: center;
}
hr {
  width: 100px;
  height: 4px;
  margin: 7px auto;
  background-image: linear-gradient(to right, rgba(240, 124, 47, 0.746) 20%, rgb(255, 102, 0) 80%);
  background-repeat: repeat-x;
  border: none;
}
/*.lignevertical hr 
    {
        width: 3px;
        height: 100%;
        margin: 7px auto;
        background-image: linear-gradient(to bottom,#fed267 20%,#cf9b48 80%);
        background-repeat: repeat-x;
        border: none;
    }*/
.input {
  text-align: left; /*l'ecriture à gauche*/
  margin-top: 40px;
}

.input .form-group {
  margin-top: 10px;
}

.input .form-group {
  display: block; /*à la ligne*/
  color: #313131;
  margin-bottom: 5px;
  font-size: 1.2rem;
  font-weight: 600;
}

.input .form-group input,
.input .form-group select
 {
  width: 100%;
  height: 40px;
  background: #ffffff; /*changement de couleur*/
  border: none;
  outline: none;
  margin: 0 0 0 0;
  padding: 2px 15px;
  font-size: 1.4rem;
  font-weight: 600;
  color: #3d3d3d; /*changement de couleur*/
  border: 2px solid rgb(179, 179, 179);
  /*inset: une forme entrée; */
}
.input .form-group:focus,
.input .form-group:hover{
  color:#ff5100
}
.input .form-group input:focus,
.input .form-group input:hover{
  border: 2px solid #ff5100;
  box-shadow: 0 0 0 0.15rem #ff51008c;
  cursor: pointer;
}
.input .form-group select:focus,
.input .form-group select:hover{
  border: 2px solid #ff5100;
  box-shadow: 0 0 0 0.15rem #ff51008c;
  color: #ff5100;
}
.input .form-group input[type="submit"] {
  margin-top: 20px;
  height: 50px;
  border-radius: 20px;
  background: rgb(255, 102, 0);
  border: 2px solid #ff5100;
  color: #fff;
  box-shadow: -2px -2px 8px rgba(255, 255, 255, 1),
    2px 2px 6px rgba(0, 0, 0, 0.28);
}

.input .form-group input[type="submit"]:active {
  background: rgb(255, 128, 44);
  color: #ffffff;
  margin-top: 20px;
  border: none;
  box-shadow: inset -2px -2px 6px rgba(255, 255, 255, 1),
    inset 2px 2px 6px rgba(0, 0, 0, 0.1); /*pour activer le clic*/
}

.input .form-group input::placeholder {
  color: #bdcada;
  font-size: 18px;
}
.checkBoxCondition{
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  padding: 20px 0px;
}
.checkBoxCondition:hover,
.checkBoxCondition:active,
.checkBoxCondition:focus{
  background: #ff510013;
}
.conditionUtilisation {
  color: #ff5100;
  margin: 0;
  padding: 0;
}
.modal-dialog {
  min-width: 95%; /*A grandir la boite modal*/
}
.modal-content {
  background: #efefef;
}


.imageStore
{
  position: relative;
  display: flex;
  justify-content: center;
  height: 900px;
  max-width: auto;
}
.imageStore img
{
  position: relative;
  min-height: 100%;
  width: 100%;

}

</style>
<template>
  <div>
    <b-form @submit.prevent="onSubmit" @reset="onReset" v-if="show">
      <b-form-group
        id="input-group-1"
        label="Wilaya:"
        label-for="input-1"
        description="Entrer votre Wilaya"
      >
      <b-form-select v-model="form.userAddress.wilaya" :options="wilayasUser" @change="clientChangeWilaya"></b-form-select>
      </b-form-group>

      <b-form-group id="input-group-2" label="Daira:" label-for="input-2" 
          description="Entrer votre Daira">
        <b-form-select v-model="form.userAddress.daira" :options="dairasUser" @change="clientChangeDaira"></b-form-select>
      </b-form-group>

      <b-form-group id="input-group-3" label="Commune:" label-for="input-2" 
          description="Entrer votre Commune">
        <b-form-select v-model="form.userAddress.commune" :options="communesUser"></b-form-select>
      </b-form-group>

      <b-form-group id="input-group-4" label="Rue:" label-for="input-2" 
          description="Saisir votre Rue">
        <b-form-input id="input-1" v-model="form.userAddress.rue"></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-5" label="ZIP:" label-for="input-2" 
          description="Code Postal ZIP">
        <b-form-input
          id="input-2"
          placeholder="enter zip code"
          required
          v-model="form.userAddress.zip"
        ></b-form-input>
      </b-form-group>
      <b-button type="submit" variant="primary">Soumettre</b-button>
    </b-form>
  </div>
</template>


<script>
// import WilayaCommune from '../components/WilayaCommune.vue';
import axios from 'axios';
import {mapGetters} from 'vuex';
export default {
  props: {
      userID:String,
  },
    name:'StoreAddAddress',
    data() {
        return {
            form: {
              userAddress:{
                wilaya:null,
                daira:null,
                commune:null,
                street:'',
                zip:'',
              },
            },
            show: true,
            wilayasUser:[],
            dairasUser:[],
            communesUser:[],
        }
    },
    methods: {
        async addAddress(){
            try{
                let res = await axios.post(`store/add_address`,{
                    storeID:this.user.storeID,
                    wilaya:this.form.userAddress.wilaya,
                    daira:this.form.userAddress.daira,
                    commune:this.form.userAddress.commune,
                    rue:this.form.userAddress.rue,
                    zip:this.form.userAddress.zip
                });
                window.console.log(res);
            }catch(e){
                window.console.error(e);
            }
        },      
        async onSubmit(event) {
            event.preventDefault();
            // alert(JSON.stringify(this.form));
            this.addAddress();
        },
        onReset(event) {
            event.preventDefault()
            this.wilaya = '';
            this.daira = '';
            this.commune = '';
            this.rue = '';
            this.zip = '';
            this.show = false
            this.$nextTick(() => {
            this.show = true
            })
        },
        clientChangeWilaya(){
          this.dairasUser = [];
          this.communesUser = [];
          let dairas = this.wilayasUser.filter(m =>m.value == this.form.userAddress.wilaya);
          
          this.dairasUser = dairas[0].list_daira;
          
        },
        clientChangeDaira(){
          this.communesUser = [];
          let communes = this.dairasUser.filter(m =>m.value == this.form.userAddress.daira);
          
          this.communesUser = communes[0].list_communes;
        },
    },
    async beforeMount(){
      try{
        let res = await axios.get('user/get_wilaya');
        let communes = [];
        let dairas = [];
        for(let wil of res.data){
          dairas = [];
          for(let dai of wil.dairas){
            communes = [];
            for(let com of dai.communes){
              communes.push({value:com.commune,text:com.commune});
            }
            dairas.push({
                value:dai.daira,
                text:dai.daira,
                list_communes:communes
            });
          }
          this.wilayasUser.push({
            value:wil.wilaya,
            text:wil.wilaya,
            list_daira:dairas
          })
        }
      }
      catch(err){
        window.console.error(err);
      }
    },    
    computed:{
      ...mapGetters(['user']),
    },
}
</script>
<style scoped>

</style>

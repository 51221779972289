<template>
    <b-container class="contactUser">
        <h2> Contacts utilisateur</h2>
        <b-form>
        <b-form-tags v-model="phones" no-outer-focus class="mb-2">
        <template v-slot="{ tags, inputAttrs, inputHandlers, addTag, removeTag }">
            <b-input-group aria-controls="my-custom-tags-list">
            <input
                v-bind="inputAttrs"
                v-on="inputHandlers"
                placeholder="Nouveau numéro"
                class="form-control"> 
            <b-input-group-append>
                <b-button @click="addTag(),addphone(inputAttrs)" class="BtnAdd">Ajouter</b-button>
            </b-input-group-append>
            </b-input-group>
            <ul
            id="my-custom-tags-list"
            class="list-unstyled d-inline-flex flex-wrap mb-0"
            aria-live="polite"
            aria-atomic="false"
            aria-relevant="additions removals"
            >
            <!-- Always use the tag value as the :key, not the index! -->
            <!-- Otherwise screen readers will not read the tag
                additions and removals correctly -->
            <b-card
                v-for="tag in tags"
                :key="tag"
                :id="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                tag="li"
                class="mt-1 mr-1"
                body-class="py-1 pr-2 text-nowrap"
            >
                <strong>{{ tag }}</strong>
                <b-button
                @click="removeTag(tag),removePhone(tag)"
                variant="link"
                size="sm"
                :aria-controls="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                >Supprimer</b-button>
            </b-card>
            </ul>
        </template>
        </b-form-tags>
            <b-button v-b-modal.modal-1 class="BtnAddAdress"><b-icon icon="plus-circle"></b-icon> Ajouter une Adresse</b-button>
                <b-card no-body class="mb-1" v-for="address in addresses" :key="address.id">
                    <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-button block v-b-toggle="address.id" variant="info">addresse {{address.num}}</b-button>
                    </b-card-header>
                    <b-collapse :id="address.id" variant="info" accordion="my-accordion" role="tabpanel">
                        <b-card-body>
                        <b-card-text>Wilaya: {{ address.wilaya }}</b-card-text>
                        <b-card-text>Daira: {{ address.daira }}</b-card-text>
                        <b-card-text>Commune: {{ address.commune }}</b-card-text>
                        <b-card-text>Rue:</b-card-text><b-card-text>{{ address.rue }}</b-card-text>
                        <b-card-text>Code Postal: {{ address.rue }}</b-card-text>
                        <b-button @click="removeAddress(address.id)">Supprimer</b-button>
                        </b-card-body>
                    </b-collapse>
                </b-card>
        </b-form>
        
        <b-modal hide-footer  id="modal-1" title="Ajouter une adresse">
            <p class="my-4" :userID="userID"><AddAddress></AddAddress></p>
        </b-modal>
    </b-container>
</template>


<script>
// import WilayaCommune from '../components/WilayaCommune.vue';
import axios from 'axios';
import AddAddress from '../components/addAddress.vue'
import {mapGetters} from 'vuex';
export default {
  props: {
      userID:String,
  },
        name:'UserContacts',
        data() {
            return {
                data:null,
                phones:[],
                addresses:[],
            }
        },
        components:{
            AddAddress
        },
        methods: {
            async removeAddress(addressID){
                try{
                    let res = await axios.delete(`info/delete_address/${this.user.id}/${addressID}`);
                    window.console.log(res);
                }catch(e){
                    window.console.error(e);
                }
            },
            async addAddress(phone){
                try{
                    let res = await axios.post(`info/add_address`,{
                        userID: this.user.id,
                        phoneNum: phone.value
                    });
                    window.console.log(res);
                }catch(e){
                    window.console.error(e);
                }
            },
            async addphone(phone){
                try{
                    let res = await axios.post(`info/add_phone`,{
                        userID: this.user.id,
                        phoneNum: phone.value
                    });
                    window.console.log(res);
                }catch(e){
                    window.console.error(e);
                }
            },
            async removePhone(phone){
                try{
                    let res = await axios.delete(`info/delete_phone/${phone}`);
                    window.console.log(res);
                }catch(e){
                    window.console.error(e);
                }                
            }
        },
        async mounted() {
            try{
                
                this.userID = '4ed3a39a-ea8b-4dfb-8ce8-a652c06de229';
                let res = await axios.get(`info/get_info/${this.user.id}`);
                this.data = res.data;
                for(let phone of res.data.contact.phones){
                    this.phones.push(phone.phone_num);
                }
                let i=0;
                for(let address of res.data.contact.addresses){
                    i++;
                    this.addresses.push({id:address.id, wilaya: address.wilaya, daira: address.daira, commune: address.commune,zip:address.zip ,rue: address.rue,num:i});
                }
            }catch(e){
                window.console.error(e);
            }
        },
        computed:{
            ...mapGetters(['user']),
        },
}
</script>
<style scoped>
.contactUser
{
    height: 100%;
    background: #fff;
    padding: 40px 40px;
}
.BtnAdd
{
    background: #F87127;
    color: #fff;
}
.BtnAddAdress
{
  color: #F87127;
  border-color: #F87127;
  border-radius: 10px;
  background: #fff;
  font-size: 1.2rem;
  font-weight: 500;
}
.BtnAddAdress:hover,
.BtnAddAdress:active
{
  background: #F87127;
  color: #fff;
  box-shadow: 0px 0px 0px 4px #f8702769;
}
</style>
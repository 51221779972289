<template>

    <b-container fluid no-gutters class="SousCategoriesPiece">       
        <!-- {{subcards}} -->
        <SubCardCategorie1 v-bind:subcards="subcards"/>


    </b-container>

</template>

<script>

import SubCardCategorie1 from "./lastCategoryLevelCard.vue";
import { bus } from '../main'
// import axios from 'axios';
export default {

    name:'CategoriesPieces',
    props: {
    },
    components: {
        SubCardCategorie1,
    },
    data() {
        return {
            subcards: [],
            subCategories:[],
        }
    },
    methods: {
        
    },
    created(){
        bus.$on('subCat', (data) => {
            this.subcards = data;


            window.console.log(this.subcards);
        });
    },
    
}
</script>

<style scoped>
.CategoriesPiece
{
    position: relative;
    margin: 60px 0;
}
</style>
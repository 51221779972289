<template>
  <b-container fluid class="loginAndSubsc" id="">
    <b-row>
      <b-col id="log" class="userID" cols="12" md="6" lg="6" xl="6">
        <b-form class="formLoginSubs" @submit.prevent="onSubmitLogin">
          <h2>Connexion</h2>
          <b-alert :show="dismissCountDown" variant="danger" @dismissed="dismissCountDown=0" @dismiss-count-down="countDownChanged">
            {{errormsg}} {{ dismissCountDown }} seconds...
          </b-alert>
          <hr class="divider divider-xs bg-bermuda offset-top-15" />
          <div class="input">
            <b-form-group id="identification-Input" label="Nom d'utilisateur" label-for="identificationInput">
              <b-form-input id="identificationInput" v-model="loginform.userName" required placeholder="Saisir votre Identifiant"></b-form-input>
            </b-form-group>

            <b-form-group id="idPassword-Input2" label="Mot de passe" label-for="idPasswordInputLogin">
              <b-form-input id="idPasswordInputLogin" v-model="loginform.password" type="password" required placeholder="**********" ></b-form-input>
            </b-form-group>
            <b-form-group>
              <input type="submit" value="SE CONNECTER" />
            </b-form-group>
          </div>
          <p class="forget">Mot de passe oublié? <b-link><h5>Click ici</h5></b-link></p>
          <p class="forget">Créez un nouveau compte Mikaniky<b-link :to="`/${$i18n.locale}/signup`"><h5>Inscription</h5></b-link></p>
        </b-form>
      </b-col>

      <b-col class="imageStore" cols="12" md="6" lg="6" xl="6">
        <img src="../assets/image_Login_right.jpg" alt="Store déttaché">
      </b-col> 

    </b-row>
  </b-container>
</template>

<script>
import axios from 'axios';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faFacebook, faGoogle } from '@fortawesome/free-brands-svg-icons';
import store from '../Vuex';

library.add(faFacebook, faGoogle);

export default {
  name: "Login",
  componnent:{
    "font-awesome-icon": FontAwesomeIcon,
  },
  data() {
    return {
      errormsg:'',
      dismissSecs: 5,
      dismissCountDown: 0,
      loginform: {
        userName:"",
        password:""
      }
    };
  },
    methods: {
      countDownChanged(dismissCountDown) {
        this.dismissCountDown = dismissCountDown
      },
      async onSubmitLogin(){
        try{
          let res = await axios.post(`auth/signin`,{
            username:this.loginform.userName,
            password:this.loginform.password
          });
          localStorage.setItem('x-auth-token',res.headers['x-auth-token']);
          store.dispatch('user',res.data);
          window.location.href = "https://www.mikaniky.com/";
          // this.$router.push({
          //   name:'Home'
          // });
        }catch(err){
          if(err.response.status == 400){
            this.errormsg = '';
            this.errormsg = 'Username Or password is wrong';
          }else{
            this.errormsg = '';
            this.errormsg = 'Something went wrong try again later';
          }
          this.dismissCountDown = this.dismissSecs;
        }
      }
  },
};
</script>

<style scoped>
.loginAndSubsc {
  position: relative;
  min-height: 100vh;
  background: #f5f5f5;
  padding: 20px 10px;
}
.row .userID {
  display: flex;
  justify-content: center;
  align-items: center;
}
.formLoginSubs {
  position: relative;
  background: #fff;
  margin-bottom: 20px;
  width: 500px;
  padding: 40px 40px 60px; /* les marge*/
  /*background: rgba(240, 101, 8, 0.096); changement de couleur*/
  border-radius: 10px; /*Arrondi les coins*/
  text-align: center;
  box-shadow: -3px -3px 3px rgba(0, 0, 0, 0.329),
    /*silohouette de Form*/ 3px 3px 3px rgba(0, 0, 0, 0.329);
}
h2 {
  color: #ff5100; /*changement de couleur*/
  font-weight: 500; /*text gras*/
  letter-spacing: 2px; /*l'espace entre les lettre*/
  display: flex;
  justify-content: center;
}
hr {
  width: 150px;
  height: 4px;
  margin: 7px auto;
  background-image: linear-gradient(to right, rgba(240, 124, 47, 0.746) 20%, rgb(255, 102, 0) 80%);
  background-repeat: repeat-x;
  border: none;
}
/*.lignevertical hr 
    {
        width: 3px;
        height: 100%;
        margin: 7px auto;
        background-image: linear-gradient(to bottom,#fed267 20%,#cf9b48 80%);
        background-repeat: repeat-x;
        border: none;
    }*/
.input {
  text-align: left; /*l'ecriture à gauche*/
  margin-top: 40px;
}

.input .form-group {
  margin-top: 20px;
}

.input .form-group {
  display: block; /*à la ligne*/
  color: #313131;
  margin-bottom: 5px;
  font-size: 1.2rem;
  font-weight: 600;
}

.input .form-group input {
  width: 100%;
  height: 40px;
  background: #ffffff; /*changement de couleur*/
  border: none;
  outline: none;
  padding: 5px 15px;
  font-size: 1.4rem;
  font-weight: 600;
  color: #3d3d3d; /*changement de couleur*/
  border: 2px solid rgb(179, 179, 179);
  /*inset: une forme entrée; */
}
.input .form-group:focus,
.input .form-group:hover{
  color:#ff5100
}
.input .form-group input:focus,
.input .form-group input:hover {
  border: 2px solid #ff5100;
  box-shadow: 0 0 0 0.15rem #ff51008c;
}
.input .form-group input[type="submit"] {
  margin-top: 20px;
  height: 50px;
  border-radius: 20px;
  background: rgb(255, 102, 0);
  border: 2px solid #ff5100;
  color: #fff;
  box-shadow: -2px -2px 8px rgba(255, 255, 255, 1),
    2px 2px 6px rgba(0, 0, 0, 0.28);
}

.input .form-group input[type="submit"]:active {
  background: rgb(255, 128, 44);
  color: #ffffff;
  margin-top: 20px;
  border: none;
  box-shadow: inset -2px -2px 6px rgba(255, 255, 255, 1),
    inset 2px 2px 6px rgba(0, 0, 0, 0.1); /*pour activer le clic*/
}

.input .form-group input::placeholder {
  color: #bdcada;
  font-size: 18px;
}
a.conditionUtilisation {
  color: #ff0047;
}
.modal-dialog {
  min-width: 95%; /*A grandir la boite modal*/
}
.modal-content {
  background: #efefef;
}
.custom-control-label::after {
  background-color: #ffffff;
  border: 2px solid #ee0505;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #ff0915;
}
.SeConecter
{
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 20px 0 0 0;
}
.SeConecter button
{
  margin: 5px;
  min-width: 100%;
}

.forget
{
	margin-top: 30px;
	color: #555;
}
.forget a
{
	color: #ff5100;
}

.imageStore
{
  position: relative;
  display: flex;
  justify-content: center;
  height: 900px;
  max-width: auto;
}
.imageStore img
{
  position: relative;
  min-height: 100%;
  width: 100%;

}
</style>
<template>
  <b-container class="publicationPage">
    <b-alert :show="dismissCountDown" :variant="alertcolor" @dismissed="dismissCountDown=0" @dismiss-count-down="countDownChanged">
      {{errormsg}} {{ dismissCountDown }} seconds...
    </b-alert>
        <h2 class="mb-5"> Liste des pièces</h2>
    <b-button-group>
      <b-button v-b-modal.modal-1 class="BtAddProduct" :to="`/${$i18n.locale}/UserProfile/addProduct`">
        <b-icon icon="plus-circle"></b-icon> Ajouter une pièce
      </b-button>
    </b-button-group>
    <b-table :items="products" :fields="fields" striped responsive="sm">
      <template #cell(photo)="row" >
        <b-button variant="light" size="sm" @click="row" class="mr-2" :to="`/${$i18n.locale}/UserProfile/productImages`">
          <b-icon  scale="2" icon="images"></b-icon>
        </b-button>
      </template>
      <template #cell(suprimer)="row">
        <b-button variant="light" size="sm" @click="row" class="mr-2">
          <b-icon scale="2" variant="danger" icon="trash"></b-icon>
        </b-button>
      </template>
      <template #cell(mise_à_jour)="row">
        <b-button variant="light" v-b-modal.modal-2 size="sm" @click.prevent="row" class="mr-2" :to="`/${$i18n.locale}/UserProfile/updateProduct`">
          <b-icon scale="2" icon="box-arrow-in-up"></b-icon>
        </b-button>
      </template>
      <template #cell(afficher_détaille)="row">
        <b-button size="sm" @click="row.toggleDetails" class="mr-2">
          {{ row.detailsShowing ? 'Hide' : 'Show'}} Détails
        </b-button>
      </template>
      <template #row-details="row">
        <b-card>
          <b-row class="mb-2">
            <b-col sm="3" class="text-sm-right"><strong>Nom de produit:</strong></b-col>
            <b-col>{{ row.item.nom_de_produit}}</b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col sm="3" class="text-sm-right"><strong>Prix:</strong></b-col>
            <b-col>{{ row.item.prix}}</b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col sm="3" class="text-sm-right"><strong>Description:</strong></b-col>
            <b-col>{{ row.item.product_description}}</b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col sm="3" class="text-sm-right"><strong>Autres Description:</strong></b-col>
            <b-col>{{ row.item.other_product_description}}</b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col sm="3" class="text-sm-right"><strong>Type de pièce :</strong></b-col>
            <b-col>{{ row.item.product_type_name}}</b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col sm="3" class="text-sm-right"><strong>Couleur de la pièce:</strong></b-col>
            <b-col><b-img v-for="color in colors" :key="color.id" v-bind="mainProps" :blank-color="color.color_value" ></b-img></b-col>
          </b-row>
          <b-button size="sm" @click="row.toggleDetails">Cacher Détails</b-button>
          <!-- <b-button size="sm" @click="row.toggleDetails">Cacher Détails</b-button> -->
        </b-card>
      </template>
    </b-table>
    <!-- <b-modal id="modal-deleted" title="Page pièce">
      <p class="my-4">la voiture a été supprimée avec succès</p>
    </b-modal>
    <b-modal id="modal-delete-error" title="Page pièce">
      <p class="my-4">une erreur est apparue !!</p>
    </b-modal>
    <b-modal scrollable id="modal-1" size="xl" title="Page pièce">
      <p class="my-4"><AddProduct></AddProduct></p>
    </b-modal>
    <b-modal id="modal-2" scrollable  size="xl" title="Mise à jours des produits">
      <p class="my-4"><UpdateProduct></UpdateProduct></p>
    </b-modal> -->
  </b-container>
</template>

<script>
// import UpdateProduct from '../components/updateProduct.vue'
// import AddProduct from '../components/AddProduct.vue';
import axios from 'axios';
import {mapGetters} from 'vuex';
  export default {
    props: {
      userID:String,
      storeID:String,
    },
    components: {
      // AddProduct,
      // UpdateProduct,
    },
    data() {
      return {
        fields: ['nom_de_produit', 'prix','photo', 'afficher_détaille','suprimer','mise_à_jour'],
        data:[],
        products:[],
        colors:[],
        product_pics:[],
        mainProps: { blank: true, width: 40, height: 40},
        showColor:false,
        errormsg:'',
        alertcolor:'',
        showview:false,
        index:null,
        dismissSecs: 5,
        dismissCountDown: 0,
      }
    },
    methods: {
      countDownChanged(dismissCountDown) {
        this.dismissCountDown = dismissCountDown
      },
      showAlert() {
        this.dismissCountDown = this.dismissSecs
      },
      async deleteProduct(){
        try{
          this.alertcolor = '';
          this.alertcolor = 'success';
          this.errormsg = '';
          this.errormsg = 'l\'image a été supprimée avec succès';
        }catch(err){
          this.alertcolor = '';
          this.alertcolor = 'danger';
          if(err.response.status == 400){
              this.errormsg = '';
              this.errormsg = 'l\'image n\'est pas supprimée';
          }else{
              this.errormsg = '';
              this.errormsg = 'quelque chose s\'est mal passé';
          }
          this.dismissCountDown = this.dismissSecs;
        }
      }
    },
    async created() {
      try{
        // this.userID = '4ed3a39a-ea8b-4dfb-8ce8-a652c06de229';
        // this.storeID = 'a15cfaca-6554-4029-b597-5cc9625e6a40';
        let res = await axios.get(`store/get_products/${this.user.storeID}`);
        this.data = res.data;
        
        for(let product of this.data){ 
          this.colors = [];
          this.product_pics = [ 'https://placekitten.com/801/800',
                                'https://placekitten.com/802/800',
                                'https://placekitten.com/803/800',
                                'https://placekitten.com/804/800',
                                'https://placekitten.com/805/800',
                                'https://placekitten.com/806/800',
                                'https://placekitten.com/807/800',
                                'https://placekitten.com/808/800',
                                'https://placekitten.com/809/800',
                                'https://placekitten.com/810/800'
                                ];

          for(let color of product.product.product_colors){
            this.colors.push(color);
          }

          for(let prod_pic of product.product.product_pics){
            this.product_pics.push(prod_pic);
          }
          
          this.products.push({
            product_storeID:product.id,
            productID:product.product.id,
            nom_de_produit:product.product.product_name,
            prix:product.product_price,
            product_description:product.product.product_description,
            other_product_description:product.product.other_product_description,
            product_type_name:product.product.product_type.product_type_name,
            product_colors:this.colors,
            product_pics:this.product_pics
          });
        }
      }catch(err) {
        window.console.error(err);
      }
    },
    computed:{
      ...mapGetters(['user']),
    },
  }
</script>
<style scoped>
.publicationPage
{
    height: 100%;
    background: #fff;
    padding: 40px 40px;
}

.BtAddProduct
{
  color: #F87127;
  border-color: #F87127;
  border-radius: 10px;
  background: #fff;
  font-size: 1.2rem;
  font-weight: 500;
}
.BtAddProduct:hover,
.BtAddProduct:active
{
  background: #F87127;
  color: #fff;
  box-shadow: 0px 0px 0px 4px #f8702769;
}
</style>
import Vue from 'vue';
import App from './App.vue';
import i18n from './i18n';
import './axios';
import store from './Vuex';
import {BootstrapVue,IconsPlugin } from 'bootstrap-vue';
import ProductZoomer from 'vue-product-zoomer';
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import router from './router'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(ProductZoomer)

Vue.config.productionTip = false;

router.beforeEach((to, from, next) => {
  let language = to.params.lang;

  if (!language) {
      language = 'en'
  }

  i18n.locale = language

  next()
})

export const bus = new Vue();

new Vue({
  store,
  i18n,
  router,
  render: h => h(App)
}).$mount('#app')

<template>
  <div>
      <p>un e-mail a été envoyé, veuillez vérifier votre e-mail et activer votre compte </p>
  </div>
</template>

<script>
export default {
    name:"SinginActivationMSG",
    components: {

    },
    data(){
        return{
            inforamtion:"Bonjour, veuillez patienter.... ",
        };
    },
    methods:{
        callFunction: function () {
            var v = this;
            setTimeout(function () {
                v.inforamtion = `vous serez redirigé vers la page d'accueil`;
                window.location = "http://127.0.0.1:8081/";
            }, 5000);
        }
    },
    mounted(){
        this.callFunction();
    },
}
</script>

<style>

</style>